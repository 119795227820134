<div class="product">
    <div class="title">{{product.Name}}</div>
    <div class="sub-title" *ngIf="product.PairingSubTitle!= ''">/ {{product.PairingSubTitle}}</div>
    <div class="main-product">
        <div class="image">
            <img [src]="product?.Thumbnail?.FileName" (click)="navigate(product)" />
        </div>
        <div class="text">
            {{product.PairingShortDescription}}
        </div>
    </div>
    <div *ngFor="let pairingProduct of product.Pairings">
        <div class="paired-product">
            <div class="image">
                <img [src]="pairingProduct?.Thumbnail?.FileName" (click)="navigate(pairingProduct)" />
            </div>
            <div class="text">
                {{pairingProduct.Name}}
            </div>
        </div>
    </div>
    <div class="sub-text">
        {{ product.PairingSubText }}
    </div>
</div>