<div class="page-image desktop">
    <img src="../../../assets/aboutus.svg" class="image">
    <div class="centered">Rólunk</div>
</div>

<div class="page-image mobile">
    <img src="../../../assets/aboutus_m.png" class="image">
    <div class="centered">Rólunk</div>
</div>


<div class="aboutus-card reverse">
    <div class="image mobile-image-wrapper-square">
        <img [src]="leftImageContent?.Thumbnail?.FileName" class="mobile-image-height"/>
    </div>
    <div class="content">
        <div [innerHTML]="leftImageContent?.Text | keepHtml" class="text"></div>
    </div>
</div>

<div class="paragraph">
    <div [innerHTML]="firstParagraph?.Text | keepHtml" class="text"></div>
</div>

<div class="aboutus-card">
    <div class="content">
        <div [innerHTML]="rightImageContent?.Text | keepHtml" class="text"></div>
    </div>
    <div class="image mobile-image-wrapper-square" >
        <img [src]="rightImageContent?.Thumbnail?.FileName" class="mobile-image-height" />
    </div>
</div>

<div class="paragraph">
    <div [innerHTML]="secondParagraph?.Text | keepHtml" class="text"></div>
</div>

<insta-post-list></insta-post-list>