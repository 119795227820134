import { Component, Input, OnInit } from '@angular/core';
import { MAT_PAGINATOR_INTL_PROVIDER_FACTORY } from '@angular/material';
import { ProductProperty } from 'src/app/contracts/product-property';
import { ProductPropertyValue } from 'src/app/contracts/product-property-value';

@Component({
  selector: 'property-picker',
  templateUrl: './property-picker.component.html',
  styleUrls: ['./property-picker.component.scss']
})
export class PropertyPickerComponent implements OnInit {
  @Input() property: ProductProperty;
  public selectedValue: ProductPropertyValue;

  constructor() { }

  ngOnInit() {
  }

  public onSelected(value: ProductPropertyValue){
    this.selectedValue = value;

    this.property.Values.forEach((v: ProductPropertyValue) => {
      if (value.Id == v.Id){
        v.Selected = true;
      }
      else{
        v.Selected = false;
      }
    });
    
  }

}
