import { Injectable } from "@angular/core";
import { HttpService } from "../common/httpService";
import { Contact } from "../contracts/contact";
import { Base } from "./base";

@Injectable()
export class ContactService extends Base {
    constructor(httpService: HttpService) {
        super(httpService, "ContactController");
    }

    public sendMail(contact: Contact){
        return this.httpService.post(this.controller, "SendMail", contact);
    }
}