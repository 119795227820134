<media-uploader #mediaUploader type="Blog" [id]="blog?.Id" (onImagesChangeEvent)="onImagesChanging()"
    (onImagesLoadedEvent)="imagesLoaded($event)" [disabled]="readOnly"> </media-uploader>

<mat-card class="card" *ngIf="blog">
    <div class="action-button-wrapper">
        <button mat-raised-button color="primary" class="btn-action" (click)="save()" [disabled]="readOnly">{{ 'Save' |
            translate }}</button>
        <button mat-raised-button color="secondary" class="btn-action" (click)="cancel()">{{ 'Cancel' | translate
            }}</button>
    </div>

    <mat-form-field class="input-full-width">
        <mat-label>{{ 'Title' | translate }}</mat-label>
        <input matInput placeholder="{{ 'Title' | translate }}" [(ngModel)]="blog.Title" [disabled]="readOnly">
    </mat-form-field>

    <image-picker #imagePicker *ngIf="blog" type="Blog" [id]="blog?.Id" placeholder="CoverImage"
        (onChangeEvent)="handleImage($event)" [selectedImage]="blog?.CoverImage" [disabled]="readOnly"></image-picker>

    <mat-form-field class="input-full-width">
        <mat-label>{{'CreationDate' | translate }}</mat-label>
        <input matInput placeholder="{{'CreationDate' | translate }}" [(ngModel)]="createdString" [disabled]="true">
    </mat-form-field>

    <mat-form-field class="input-full-width">
        <mat-label>{{'ModificationDate' | translate }}</mat-label>
        <input matInput placeholder="{{'ModificationDate' | translate }}" [(ngModel)]="modifiedString"
            [disabled]="true">
    </mat-form-field>

    <mat-checkbox class="input-full-width admin" [(ngModel)]="blog.Published" [disabled]="readOnly">
        {{ 'Published' | translate}}
    </mat-checkbox>

    <div class="input-full-width">
        <blog-category-picker [selectedCategory]="blog.CategoryId" (onChangeEvent)="changeCategory($event)" [disabled]="readOnly">
        </blog-category-picker>
    </div>

    <div class="editor">
        <span>Rövid leírás</span>
        <text-editor [imageList]="imageList" [(data)]="blog.ShortText" *ngIf="isEditorVisible" [disabled]="readOnly">
        </text-editor>
    </div>

    <div class="editor">
        <span>Blog</span>
        <text-editor [imageList]="imageList" [(data)]="blog.Text" *ngIf="isEditorVisible" [disabled]="readOnly">
        </text-editor>
    </div>
</mat-card>