import { Product } from "./product";
import { ProductProperty } from "./product-property";
import { ProductPropertyValue } from "./product-property-value";

export class ShopItem {
    public Product: Product;
    public Quantity: number;
    public Identifier: string;
    public SelectedProperties: ProductPropertyValue[];
    public Price: number;
    public OriginalPrice: number;  
    public IsActiveCoupon: boolean;   
}