import { Injectable } from "@angular/core";
import { HttpService } from "../common/httpService";
import { Filter } from "../contracts/infrastructure/filter";
import { Base } from "./base";

@Injectable()
export class ProductTypeService extends Base {

    constructor(httpService: HttpService) {
        super(httpService, "ProductTypeController");
    }
}