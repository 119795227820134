import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IBlog } from 'src/app/contracts/interfaces/iBlog';
import { BlogService } from 'src/app/dataServices/blogService';
import { Router } from '@angular/router';
import { config } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

var self;

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss'],
  providers: [BlogService]
})
export class BlogListComponent implements OnInit, OnDestroy {

  private selectedRowIndex: number = null;
  private selectedItem: any = null;
  private selection: SelectionModel<any>;
  private gridChangeSubscription: any;
  public isSelected: boolean = false;

  public displayedColumns = ['Id', 'Title', 'Published'];
  public dataSource: IBlog[];

  constructor(private blogService: BlogService, private router: Router, private translateService: TranslateService) {
    self = this;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(false);

    this.listAll();

    this.gridChangeSubscription = this.selection.changed.subscribe(this.change);
  }

  private change(e: any) {
    self.selectedRowIndex = e.added[0]['Id'];
    self.selectedItem = e.added[0];
    self.isSelected = true;
  }

  public onView() {
    this.router.navigateByUrl("adminsite/blogedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/bloglist', mode: 'ReadOnly' } } });
  }

  public onNew() {
    this.blogService.create().then(r => {
      this.router.navigateByUrl("adminsite/blogedit", { state: { data: { id: r, routeBack: 'adminsite/bloglistt', mode: 'New' } } });
    });
  }

  public onEdit() {
    this.router.navigateByUrl("adminsite/blogedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/bloglist', mode: 'Modify' } } });
  }

  public onDelete() {
    if (confirm(this.translateService.instant("Confirm"))) {
      this.blogService.delete(this.selectedItem.Id).then(r => {
        this.listAll();
        this.isSelected = false;
        this.selectedItem = null;
      });
    }
  }

  public listAll() {
    this.blogService.getAll().then((r: IBlog[]) => {
      this.dataSource = r;
    });
  }
  
  ngOnDestroy(): void {
    this.gridChangeSubscription.unsubscribe();
  }
}
