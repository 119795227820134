import { Injectable } from "@angular/core";
import { HttpService } from "../common/httpService";
import { Base } from "./base";

@Injectable()
export class DeliveryMethosService extends Base {
   
    constructor(httpService: HttpService){
        super( httpService, "DeliveryMethodController");
    }
}