<div class="page-image desktop">
    <img src="../../../assets/faq.svg" class="image">
    <div class="centered">Gyakori kérdések</div>
</div>

<div class="page-image mobile">
    <img src="../../../assets/faq_m.png" class="image">
    <div class="centered">Gyakori kérdések</div>
</div>

<div class="faq-list-wrapper">
    <div *ngFor="let faq of faqs" class="faq-item">
        <faq-item [faq]="faq"></faq-item>
    </div>
</div>