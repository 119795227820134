import {ProductPropertyValue} from './product-property-value';

export class ProductProperty{
    public Id: number;
    public Name: string;
    public Type: number;

    public IsNew: boolean;
    public IsDeleted: boolean;

    public Values: ProductPropertyValue[];

    constructor(){
        this.Values = [];
    }
}