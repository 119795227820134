import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/contracts/product';
import { ProductService } from 'src/app/dataServices/productService';

@Component({
  selector: 'service-picker',
  templateUrl: './service-picker.component.html',
  styleUrls: ['./service-picker.component.scss'],
  providers: [ProductService]
})
export class ServicePickerComponent implements OnInit {
  @Input() selectedService: Product;
  @Input() disabled: boolean;
  @Output() onChangeEvent: EventEmitter<Product> = new EventEmitter<Product>();
  
  public dataSource: Product[];
  
  constructor(private productService: ProductService) {
  }

  ngOnInit() {
    this.onFilter("");
  }

  public onFilter(filter: string) {
    //event.target.value
    this.productService.listServices(filter).then((r: any) => {
      this.dataSource = r.Result;
    });
  }

  public onChange(service: any){
    this.selectedService = service.option.value;
    this.onChangeEvent.emit(this.selectedService);
  }
}
