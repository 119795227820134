<div *ngFor="let service of productServices">
    <div class="product-wrapper">
        <div class="">
            <mat-checkbox [disableRipple]="true" [(ngModel)]="service.Selected"></mat-checkbox>
        </div>
        <div class="text">
            <div class="title">
                {{service.Name}} <span *ngIf="service.Price"> - {{ service.Price | number:'.0' | currency }} Forint</span><a *ngIf="service.IsVisible" [routerLink]="['/pages/product']" [queryParams]="{id: service.Id}">
                    (Link)
                  </a>
            </div>
            <div class="description" *ngIf="service.Description" [innerHTML]="service?.Description | keepHtml">
            </div>
        </div>
    </div>
</div>