import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Output, Query, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { QueryStringParameter } from "../contracts/queryStringParameter";
import { AuthService } from "../services/authService";
import { LoaderService } from "../services/loaderService";
import { ProcessingService } from "../services/processingService";
import { TokenService } from "../services/tokenService";
import { Deferred } from "./deferred";
import { AccessError } from "./enums/AccessError";

@Injectable()
export class HttpService {
    @Output() onAuthError: EventEmitter<AccessError> = new EventEmitter<AccessError>();
  
    constructor(private authService: AuthService, private http: HttpClient, private router: Router, private loaderService: LoaderService, private tokenHandler: TokenService, private processingService: ProcessingService) { }

  private deferred = new Deferred<any>();

  public post(controller: string, action: string, data: any, parameters: QueryStringParameter[] = [], appendContentType: boolean = true, id = "") {
    let token = this.loaderService.addToken();
    
    if (parameters == null){
      parameters = [];
    }

    let qs = this.buildQueryString(parameters);

    let promise = new Promise((resolve, reject) => {
      this.http.post<any>('/api/' + controller + '/' + action + qs, data, { headers: this.setHeaders(appendContentType), observe: "response" })
        .toPromise()
        .then(res => {
          if(id){
            this.processingService.removeGuid(id);
          }
          //this.processingService.Processing = false;
          //this.authService.setUserToken(res.headers.get('SessionGuid'), res.headers.)  --res.headers.get('AuthToken'));
          if (action.toLowerCase() == 'login'){
            if (res.headers.get("sessionid") != null && res.headers.get("authtoken") != null){
              this.authService.setUserToken(res.headers.get("sessionid"), res.headers.get("authToken"));
            }
          }

          if (res.body.ResultType && (res.body.ResultType == 1 || res.body.ResultType == 'Error'))
            reject(res.body);

          else if (res.body.ResultType && (res.body.ResultType == 2 || res.body.ResultType == 'Custom')) {

            if (res.body.CustomExceptionCode == 'MustChangePasswordExűception') {
              this.router.navigate(['sensenetwork/passwordchange']);
            }

            else if (res.body.CustomExceptionCode == 'TokenException') {
              this.onAuthError.next(AccessError.Token);
              this.tokenHandler.clearToken();
              //this.router.navigate(['login']);
            }
          }

          else {
            resolve(res.body);
          }

          this.loaderService.removeToken(token);
        },

        msg => {
          if(id){
            this.processingService.removeGuid(id);
          }
          //this.processingService.Processing = false;
          if (msg.status == 403) {
            this.onAuthError.next(AccessError.Forbidden);
            //this.router.navigate(['unauthorized']);
            reject(msg);
          }

          if (msg.status == 401) {
            this.onAuthError.next(AccessError.Unauthorized);
            this.tokenHandler.clearToken();
            //this.router.navigate(['unauthorized']);
            reject(msg);
          }

          else {
            reject(msg);
          }

          this.loaderService.removeToken(token);
        });
    });
      
    return promise;
  }

  public get(controller: string, action: string, parameters: QueryStringParameter[] = [], appendContentType: boolean = true) {
    let token = this.loaderService.addToken();

    let qs = this.buildQueryString(parameters);

    let promise = new Promise((resolve, reject) => {
      this.http.get<any>('/api/' + controller + '/' + action + qs, { headers: this.setHeaders(appendContentType), observe: "response" })
        .toPromise()
        .then(res => {
          //this.authService.setUserToken(res.headers.get('AuthToken'));

          if (res.body.ResultType && (res.body.ResultType == 1 || res.body.ResultType == 'Error'))
            reject(res.body);

          else if (res.body.ResultType && (res.body.ResultType == 2 || res.body.ResultType == 'Custom')) {

            if (res.body.CustomExceptionCode == 'MustChangePasswordException') {
              this.router.navigate(['sensenetwork/passwordchange']);
            }

            else if (res.body.CustomExceptionCode == 'TokenException') {
              this.onAuthError.next(AccessError.Token);
              this.tokenHandler.clearToken();
              //this.router.navigate(['login']);
            }
          }

          else {
            resolve(res.body);
          }

          this.loaderService.removeToken(token);
        },

        msg => {
          if (msg.status == 403) {
            this.onAuthError.next(AccessError.Forbidden);
            //this.router.navigate(['unauthorized']);
            reject(msg);
          }

          else if (msg.status == 401) {
            this.onAuthError.next(AccessError.Unauthorized);
            this.tokenHandler.clearToken();
            //this.router.navigate(['unauthorized']);
            reject(msg);
          }

          else {
            reject(msg);
          }

          this.loaderService.removeToken(token);
        });
    });

    return promise;
  }

  private setHeaders(appendContentType: boolean = true) {
    var headers = new HttpHeaders();

    headers = headers.append("language", "hu");

    if (appendContentType){
      headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    }
    else{
    //  headers = headers.append('Content-Type', 'application/octet-stream');
    }
    
    if (this.authService.getUserToken() != null && this.authService.getUserToken() != '') {
      headers = headers.append('SessionId', this.authService.getUserToken().sessionId);
      headers = headers.append('AuthToken', this.authService.getUserToken().token);
    }

    return headers;
  }

  private buildQueryString(parameters: QueryStringParameter[]): string {
    let qs: string = "";
    let isFirst = true;

    parameters.forEach(element => {
        if (isFirst) {
            qs += '?';
            isFirst = false;
        }
        else {
            qs += '&';
        }

        qs += element.key;
        qs += '=';
        qs += element.value;
    });

    return qs;
}

    /*constructor(private http: HttpClient) {
    }

    public get(controller: string, action: string, parameters: QueryStringParameter[] = [])  {
        let qs = this.buildQueryString(parameters);

        let promise = new Promise((resolve, reject) => {
            this.http.get<any>('/api/' + controller + '/' + action + qs, { headers: this.setHeaders(), observe: "response" })
                .toPromise()
                .then(res => {
                    resolve(res.body);
                })
        });

        return promise;
    }

    public post(controller: string, action: string, contract: any, parameters: QueryStringParameter[] = [], appendContentType: boolean = true) {
        let qs = this.buildQueryString(parameters);

        let promise = new Promise((resolve, reject) => {
            this.http.post<any>('/api/' + controller + '/' + action + qs, contract, { headers: this.setHeaders(appendContentType), observe: "response" })
                .toPromise()
                .then(res => {
                    resolve(res.body);
                })
        });

        return promise;
    }


    private buildQueryString(parameters: QueryStringParameter[]): string {
        let qs: string = "";
        let isFirst = true;

        parameters.forEach(element => {
            if (isFirst) {
                qs += '?';
                isFirst = false;
            }
            else {
                qs += '&';
            }

            qs += element.key;
            qs += '=';
            qs += element.value;
        });

        return qs;
    }

    private setHeaders(appendContentType: boolean = true) {
        var headers = new HttpHeaders();

        //headers = headers.append("language", this.config.language);

        if (appendContentType) {
            headers = headers.append('Content-Type', 'application/json; charset=utf-8');
        }

        /*if (this.authService.getUserToken() != null && this.authService.getUserToken() != '') {
          headers = headers.append('SessionId', this.authService.getUserToken().sessionId);
          headers = headers.append('AuthToken', this.authService.getUserToken().token);
        }*/

       // return headers;
   // }
}