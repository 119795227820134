import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/contracts/product';
import { ProductProperty } from 'src/app/contracts/product-property';
import { ProductPropertyValue } from 'src/app/contracts/product-property-value';
import { ShoppingCartService } from 'src/app/services/shopping-cart-service';

@Component({
  selector: 'place-in-cart',
  templateUrl: './place-in-cart.component.html',
  styleUrls: ['./place-in-cart.component.scss']
})
export class PlaceInCartComponent implements OnInit {
  @Input() product: Product;
  @Output() onAdded: EventEmitter<boolean> = new  EventEmitter<boolean>();

  public quantity: number;
  
  constructor(private shoppingCartService: ShoppingCartService) { }

  ngOnInit() {
    this.quantity = 1;
  }

  public add() {
    if (this.quantity) {
      let isValid = this.validatePropertySelections();

      if (isValid) {
        this.shoppingCartService.addProductToCart(this.product, this.quantity)

        this.product.Services.forEach((s: Product) => {
          if (s.Selected) {
            this.shoppingCartService.addProductToCart(s, this.quantity);
          }
        });

        //alert('A termék bekerült a kosárba');
        this.onAdded.next(true);
      }
      else {
        alert('A termék tulajdonságainak kitöltése kötelező');
      }
    }
  }

  public quantityChange(e) {
    if (e.key == '-') {
      this.quantity = 1;
    }
  }

  private validatePropertySelections() {
    let isValid = true;

    this.product.Properties.forEach((p: ProductProperty) => {
      let hasSelected = p.Values.find((pv: ProductPropertyValue) => {
        return pv.Selected;
      });

      if (!hasSelected) {
        isValid = false;
      }
    });

    return isValid;
  }
}
