<!--<action-header (onViewEvent)="onView()" (onNewEvent)="onNew()" (onEditEvent)="onEdit()" (onDeleteEvent)="onDelete()"
    [isSelected]="isSelected"></action-header>-->

<mat-card class="card admin filter">
    <div class="isnew-filter">
        <span class="title">Új?</span>
        <mat-radio-group class="group" [(ngModel)]="isNewFilter" aria-label="Select an option" (change)="changeIsNewFilter($event)">
            <mat-radio-button [disableRipple]="true" class="item" [value]="true">Igen</mat-radio-button>
            <mat-radio-button [disableRipple]="true" class="item" [value]="false" >Nem</mat-radio-button>
            <mat-radio-button [disableRipple]="true" class="item" [value]="null" >Mindkettő</mat-radio-button>
          </mat-radio-group>
    </div>
    <div class="status-filter">
        <span class="title">Rendelés státuszta</span>
        <div class="checkboxes">
            <mat-checkbox class="checkbox" [checked]="statusFilter[0]" [disableRipple]="true" (change)="changeStatusFilter(0,$event)">
                Feldolgozás alatt
            </mat-checkbox>
            <mat-checkbox class="checkbox" [checked]="statusFilter[1]" [disableRipple]="true" (change)="changeStatusFilter(1,$event)">
                Boltban
            </mat-checkbox>
            <mat-checkbox class="checkbox" [checked]="statusFilter[2]" [disableRipple]="true" (change)="changeStatusFilter(2,$event)">
                Kiszállítás alatt
            </mat-checkbox>
            <mat-checkbox class="checkbox" [checked]="statusFilter[3]" [disableRipple]="true" (change)="changeStatusFilter(3,$event)">
                Csomagponton
            </mat-checkbox>
            <mat-checkbox class="checkbox" [checked]="statusFilter[4]" [disableRipple]="true" (change)="changeStatusFilter(4,$event)">
                Lezárt
            </mat-checkbox>
        </div>
    </div>
</mat-card>

<mat-card class="card admin">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="DeleteButton">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <div class="table-buttons">
                    <button mat-raised-button color="primary" *ngIf="!element.InvoiceId"
                        (click)="createBill(element.Id)">Számla kiállítása</button>&nbsp;
                    <button mat-raised-button color="primary" *ngIf="element.InvoiceId"
                        (click)="getInvoice(element.Id)">Számla letöltése</button>
                    <button mat-raised-button color="primary" (click)="show(element)">Rendelés adatai</button> &nbsp;

                    <button mat-raised-button color="primary" (click)="deleteOrder(element)">Rendelés törlése</button>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Identifier">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="form-control-width">
                    <mat-placeholder class="th-mat-placeholder">Azonosító</mat-placeholder>
                    <input matInput class="mat-grid-header-search" [(ngModel)]="orderNumber" (keydown)="keydown()"
                        (keyup)="keyup()" />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" [ngStyle]="element.IsNew && {'font-weight': 'bold'}">
                {{element.Year}}/{{element.IdYear}}</td>
        </ng-container>

        <ng-container matColumnDef="OrderDate">
            <th mat-header-cell *matHeaderCellDef> Rendelési idő </th>
            <td mat-cell *matCellDef="let element" [ngStyle]="element.IsNew && {'font-weight': 'bold'}">
                {{element.OrderDate | date:'yyyy.MM.dd HH:mm:ss'}}</td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="form-control-width">
                    <mat-placeholder class="th-mat-placeholder">Név</mat-placeholder>
                    <input matInput class="mat-grid-header-search" [(ngModel)]="name" (keydown)="keydown()"
                        (keyup)="keyup()" />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" [ngStyle]="element.IsNew && {'font-weight': 'bold'}">
                {{element.Name}} </td>
        </ng-container>

        <ng-container matColumnDef="TaxNumber">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="form-control-width">
                    <mat-placeholder class="th-mat-placeholder">Adószám</mat-placeholder>
                    <input matInput class="mat-grid-header-search" [(ngModel)]="taxNumber" (keydown)="keydown()"
                        (keyup)="keyup()" />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" [ngStyle]="element.IsNew && {'font-weight': 'bold'}">
                {{element.TaxNumber}} </td>
        </ng-container>


        <ng-container matColumnDef="Phone">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="form-control-width">
                    <mat-placeholder class="th-mat-placeholder">Telefon</mat-placeholder>
                    <input matInput class="mat-grid-header-search" [(ngModel)]="phone" (keydown)="keydown()"
                        (keyup)="keyup()" />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" [ngStyle]="element.IsNew && {'font-weight': 'bold'}">
                {{element.Phone}} </td>
        </ng-container>

        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="form-control-width">
                    <mat-placeholder class="th-mat-placeholder">E-main</mat-placeholder>
                    <input matInput class="mat-grid-header-search" [(ngModel)]="email" (keydown)="keydown()"
                        (keyup)="keyup()" />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" [ngStyle]="element.IsNew && {'font-weight': 'bold'}">
                {{element.Email}} </td>
        </ng-container>

        <ng-container matColumnDef="PaymentMethod">
            <th mat-header-cell *matHeaderCellDef> Fizetés módja</th>
            <td mat-cell *matCellDef="let element" [ngStyle]="element.IsNew && {'font-weight': 'bold'}">
                {{getPaymentMethod(element.PaymentMethod)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'selected': selectedRowIndex === row.Id}" (click)="selection.select(row)">
        </tr>
    </table>
</mat-card>