import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Category } from 'src/app/contracts/category';
import { CategoryService } from 'src/app/dataServices/categoryService';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss'],
  providers: [CategoryService]
})
export class CategoryEditComponent implements OnInit {
  public pageData: any;
  public readOnly: boolean;
  public category: Category;

  constructor(private router: Router, private categoryService: CategoryService) {
    this.category = new Category();
    this.pageData = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.data : null;
    this.readOnly = this.pageData.mode == "ReadOnly";
  }

  ngOnInit() {
    if (this.pageData.mode != "New") {
      this.getById();
    }
  }

  private getById() {
    this.categoryService.getById(this.pageData.id).then((r: Category) => {
      this.category = r;
    });
  }

  public cancel() {
    this.router.navigateByUrl(this.pageData.routeBack);
  }

  public handleCategory(category: Category) {
    this.category.Parent = category;
  }

  public save() {
    this.categoryService.save(this.category).then((r:any) => {
      if (r.ResultType == 0) {
        this.router.navigateByUrl(this.pageData.routeBack);
      }
    });
  }
}
