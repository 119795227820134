import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IFaq } from 'src/app/contracts/interfaces/iFaq';
import { FaqService } from 'src/app/dataServices/faqService';

var self;

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss'],
  providers: [FaqService]
})
export class FaqListComponent implements OnInit {
  public dataSource: IFaq[];
  public displayedColumns = ['Id', 'Question'];

  private selectedRowIndex: number = null;
  private selectedItem: any = null;
  private selection: SelectionModel<any>;
  private gridChangeSubscription: any;
  public isSelected: boolean = false;

  constructor(private faqService: FaqService, private router: Router, private translateService: TranslateService) {
    self = this;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(false);

    this.filter();

    this.gridChangeSubscription = this.selection.changed.subscribe(this.change);
  }

  private change(e: any) {
    self.selectedRowIndex = e.added[0]['Id'];
    self.selectedItem = e.added[0];
    self.isSelected = true;
  }

  private filter() {
    this.faqService.getAll().then((r: IFaq[]) => {
      this.dataSource = r;
    })
  }

  public onView() {
    this.router.navigateByUrl("adminsite/faqedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/faqlist', mode: 'ReadOnly' } } });
  }

  public onNew() {
    this.faqService.create().then((r:any) => {
      this.router.navigateByUrl("adminsite/faqedit", { state: { data: { id: r.Result, routeBack: 'adminsite/faqlist', mode: 'New' } } });
    });
  }

  public onEdit() {
    this.router.navigateByUrl("adminsite/faqedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/faqlist', mode: 'Modify' } } });
  }

  public onDelete() {
    if (confirm(this.translateService.instant("Confirm"))) {
      this.faqService.delete(this.selectedItem.Id).then(r => {
        this.filter();
        this.isSelected = false;
        this.selectedItem = null;
      });
    }
  }

  ngOnDestroy() : void {
    this.gridChangeSubscription.unsubscribe();
  }
}
