import { EventEmitter } from '@angular/core';
import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, Output } from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { Image } from 'src/app/contracts/image';
import { UploadImage } from 'src/app/contracts/uploadImage';
import { ImageService } from 'src/app/dataServices/imageService';

@Component({
  selector: 'media-uploader',
  templateUrl: './media-uploader.component.html',
  styleUrls: ['./media-uploader.component.scss'],
  providers: [ImageService]
})
export class MediaUploaderComponent implements OnInit {
  public fileComponent: ElementRef;
  public fileTabComponent: MatTabGroup;
  public uploadableFiles: any[];
  public uploadedFiles: Image[];
  public isFilesVisible: boolean = true;

  @Input() type: string;
  @Input() id: number;
  @Input() disabled: boolean;
  @Input() filter: string;

  @Output() onImagesLoadedEvent: EventEmitter<Image[]> = new EventEmitter<Image[]>();
  @Output() onImagesChangeEvent: EventEmitter<Object> = new EventEmitter<Object>();
  //  @ViewChild("fileTab", { static: false }) fileTab: MatTabGroup;

  @ViewChild('fileTab', { static: false }) set fileTab(component: MatTabGroup) {
    if (component) {
      this.fileTabComponent = component;
    }
  }


  @ViewChild('file', { static: false }) set file(component: ElementRef) {
    if (component) {
      this.fileComponent = component;
    }
  }

  constructor(private imageService: ImageService) {
    this.uploadedFiles = [];
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    //this.fileTab.selectedIndex = 1;
    this.isFilesVisible = false;
  }

  public onFileChange(evt) {
    this.uploadableFiles = evt.target.files;
    // this.fileTabComponent.selectedIndex = 0;
  }

  public removeFileFromQueue(name) {
    this.uploadableFiles = Array.from(this.uploadableFiles).filter(
      item => { return item['name'] != name });

    if (this.uploadableFiles.length == 0) {
      this.uploadableFiles = null;
      // this.fileTabComponent.selectedIndex = 0;
    }
  }

  public deleteFile(id: number) {
    this.onImagesChangeEvent.emit();

    this.imageService.deleteFile(this.type, id).then(r => {
      if (this.filter == undefined || this.filter == "" ) {
        this.load(this.type, this.id);
      }
      else {
        this.loadByFilter(this.filter);
      }
    });

  }

  public upload() {
    this.onImagesChangeEvent.emit();
    let data = new UploadImage(this.id, this.type, this.filter);
    let formData: FormData = new FormData();
    let idx = 0;
    formData.append("json", JSON.stringify(data));

    Array.from(this.uploadableFiles).forEach(f => {
      formData.append('file' + idx, f, f.name);
      idx++;
    });

    this.imageService.upload(formData).then(r => {
      this.uploadableFiles = null;
      this.fileTabComponent.selectedIndex = 0;
      if (this.filter == undefined || this.filter == "" ) {
        this.load(this.type, this.id);
      }
      else {
        this.loadByFilter(this.filter);
      }
    });
  }

  public load(entityType: string, id: number) {
    this.imageService.getByEntiTypeAndId(entityType, id).then((r: any) => {
      this.uploadedFiles = r;
      this.onImagesLoadedEvent.emit(this.uploadedFiles);
    });
  }

  public loadByFilter(filter: string) {
    this.imageService.getByFilter(filter).then((r: any) => {
      this.uploadedFiles = r;
      this.onImagesLoadedEvent.emit(this.uploadedFiles);
    });
  }

  public showHide() {
    this.isFilesVisible = !this.isFilesVisible;

    if (this.isFilesVisible) {
      if (this.uploadableFiles != null) {
        //        this.fileTabComponent.selectedIndex = 0;
      }
      else {
        //      this.fileTabComponent.selectedIndex = 1;
      }
    }
  }
}
