import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getMatIconFailedToSanitizeUrlError } from '@angular/material';
import { Favourite } from 'src/app/contracts/favourite';
import { ProductService } from 'src/app/dataServices/productService';

@Component({
  selector: 'favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss'],
  providers: [ProductService]
})
export class FavouritesComponent implements OnInit, OnDestroy {
  private scrollerTimer: any;
  private favourites: Favourite[];
  public favouritesToShow: Favourite[];
  private imageDistance: number = 400;
  @Input() showLines: boolean = false;

  constructor(private productService: ProductService) {
  }

  ngOnInit() {
    this.favouritesToShow = [];

    this.productService.getFavourites().then((f: Favourite[]) => {
    this.favouritesToShow = f;
      //let position = 0;

      /*this.favourites.forEach((favourite: Favourite) => {
        let newFavourite = Object.assign({}, favourite);
        newFavourite.IsVisible = true;
        newFavourite.LeftPosition = position;

        this.favouritesToShow.push(newFavourite);

        position += this.imageDistance;
      });

      this.startScrolling();
    });*/
  });
  }

 /* private startScrolling() {
    this.scrollerTimer = setInterval(() => {
      this.favouritesToShow.forEach((item) => {
        item.LeftPosition--;

        if (item.LeftPosition <= (-1 * this.imageDistance) && item.IsVisible) {
          item.IsVisible = false;

          let newItem = Object.assign({}, this.favourites.filter(i => i.Id == item.Id)[0]);
          let lastShowedItem = this.favouritesToShow[this.favouritesToShow.length - 1];

          newItem.LeftPosition = lastShowedItem.LeftPosition + this.imageDistance;
          this.favouritesToShow.push(newItem);
        }
      })

      this.favouritesToShow = this.favouritesToShow.filter(i => i.IsVisible);
    }, 20);
  }

  private stopScrolling() {
    clearInterval(this.scrollerTimer);
  }

  public onEnter(event) {
    if (!event) {
      this.startScrolling();
    }
    else {
      this.stopScrolling();
    }
  }*/

  ngOnDestroy(){
   // this.stopScrolling();
  }
}
