<div class="admin">
    <mat-toolbar color="primary">
        <span class="logo">
            <img src="/assets/logo.png" height="40px" alt="Vivian" />
        </span>
        <div class="current-user">
            <current-user></current-user>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="page">
        <mat-sidenav mode="side" class="menu" opened>
            <ul>
                <li>
                    <a [routerLink]="['/adminsite/orderlist']"> Leadott rendelések</a>
                </li>
                <li>
                    <a [routerLink]="['/adminsite/newslettersend']"> Hírlevél</a>
                </li>
                <li>
                    <a [routerLink]="['/adminsite/couponlist']"> Kuponok</a>
                </li>
            </ul>
            <hr>
            <ul>
                <li>
                    <a [routerLink]="['/adminsite/content']"> {{'ContentMenu' | translate}}</a>
                </li>
                <li><a [routerLink]="['/adminsite/bloglist']"> {{'BlogMenu' | translate}}</a></li>
                <li><a [routerLink]="['/adminsite/faqlist']">GYIK</a></li>
                <li><a [routerLink]="['/adminsite/instapostlist']">Instagram post-ok</a></li>
            </ul>
            <hr>
            <ul>
                <li>
                    <a [routerLink]="['/adminsite/categorylist']"> {{'CategoryMenu' | translate}}</a>
                </li>
                <li>
                    <a [routerLink]="['/adminsite/productlist']"> {{'ProductMenu' | translate}}</a>
                </li>
            </ul>
            <hr>
            <ul>
                <li>
                    <a [routerLink]="['/adminsite/userlist']"> Felhasználó lista</a>
                </li>
            </ul>
        </mat-sidenav>

        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>