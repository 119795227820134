import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'cta-collection',
  templateUrl: './cta-collection.component.html',
  styleUrls: ['./cta-collection.component.scss']
})
export class CtaCollectionComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit() {
  }

  public navigate() {
    this.router.navigate(['/pages/shop'], { queryParams: { code: 1 } });
  }
}
