import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/contracts/product';
import { ProductService } from 'src/app/dataServices/productService';

@Component({
  selector: 'product-picker',
  templateUrl: './product-picker.component.html',
  styleUrls: ['./product-picker.component.scss'],
  providers: [ProductService]
})
export class ProductPickerComponent implements OnInit {
  @Input() selectedProduct: Product;
  @Input() disabled: boolean;
  @Output() onChangeEvent: EventEmitter<Product> = new EventEmitter<Product>();
  
  public dataSource: Product[];
  
  constructor(private productService: ProductService) {
  }

  ngOnInit() {
    this.onFilter("");
  }

  public onFilter(filter: string) {
    //event.target.value
    this.productService.listProducts(filter).then((r: any) => {
      this.dataSource = r.Result;
    });
  }

  public onChange(product: any){
    this.selectedProduct = product.option.value;
    this.onChangeEvent.emit(this.selectedProduct);
  }

}
