import { Component, OnInit } from '@angular/core';
import { Faq } from 'src/app/contracts/faq';
import { FaqService } from 'src/app/dataServices/faqService';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  providers: [FaqService]
})
export class FaqComponent implements OnInit {

  public faqs: Faq[];

  constructor(private faqService: FaqService) {
    this.faqs = [];
  }

  ngOnInit() {
    this.faqService.getAll().then((faqs: Faq[]) => {
      this.faqs = faqs;
    })
  }
}
