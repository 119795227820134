import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Constants } from 'src/app/common/constants';
import { Subscription } from 'src/app/contracts/subscription';
import { NewsLetterService } from 'src/app/dataServices/newsletterService';
import { CaptchaComponent } from '../captcha/captcha.component';

var self;

@Component({
  selector: 'newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  providers: [NewsLetterService]
})
export class NewsletterComponent implements OnInit {
  public subscription: Subscription;
  public acceptGdpr;
//  public isResolved: boolean = false;

  private onResolvedSubscription: any;
  private onErrorSubscription:any;
  private captchaDialog: any;

  constructor(private newsletterService: NewsLetterService, public constants: Constants, public dialog: MatDialog) {
    this.subscription = new Subscription();
    self = this;
  }

  ngOnInit() {
  }

  public subscribeToNewsletter() {
    this.newsletterService.subscribe(this.subscription).then((r: any) => {
      if (r.ResultType == 0) {
        this.subscription = new Subscription();
        alert("Feliratkozás sikeres, ellenőrizd az e-mail címét.");
      }
      else if (r.ResultType == 3) {
        let errors = "";
        r.ValidationResult.forEach(element => {
          errors += element.Key + ': ' + element.Value + '\r\n';
        });

        alert(errors);
      }
      else {
        alert("Sikertelen feliratkozás");
      }
    }).catch(r => {
      alert("Sikertelen feliratkozás");
    })
  }

  public openCaptcha(){
    this.captchaDialog   = this.dialog.open(CaptchaComponent, {
      width: '310px',
      height: '126px',
    });

    this.onResolvedSubscription = this.captchaDialog.componentInstance.onResolved.subscribe(() =>{
      this.subscribeToNewsletter();
      this.unSubscripbeCapthca();
    });

    this.onErrorSubscription = this.captchaDialog.componentInstance.onError.subscribe(()=>{
      this.unSubscripbeCapthca();
    });
  }

  private unSubscripbeCapthca(){
    this.onResolvedSubscription.unsubscribe();
    this.onErrorSubscription.unsubscribe();

    this.captchaDialog.close();
    this.captchaDialog = null;
  }
}
