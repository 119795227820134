import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextStore } from 'src/app/common/contextStore';
import { HttpService } from 'src/app/common/httpService';
import { UserService } from 'src/app/dataServices/userService';

@Component({
  selector: 'app-masterpage',
  templateUrl: './masterpage.component.html',
  styleUrls: ['./masterpage.component.scss']
})
export class MasterpageComponent implements OnInit {

  constructor(private userService: UserService, private httpServce: HttpService, private contextStore: ContextStore, private router:Router ) { }

  ngOnInit() {
    this.userService.getContext().then(res => {
      this.contextStore.Context = res;
    },
      msg => {

      })

    this.httpServce.onAuthError.subscribe(r=>{
      this.contextStore.Context = null;
      this.router.navigate(['admin']);
    })
  }

}
