<!--<mat-form-field class="input-full-width" >
    <mat-label>Kupon</mat-label>
    <input matInput placeholder="Kupon" [(ngModel)]="shoppingCartService.couponCode" [disabled]="shoppingCartService.isCouponActive" />
</mat-form-field>

<button mat-raised-button color="primary" class="btn-action" (click)="activate()" [disabled]="shoppingCartService.isCouponActive">Aktivál</button>-->
<div class="gift">
    <img src="/assets/gift_card.svg" /> 
    <input class="gift-code" type="text" [disabled]="shoppingCartService.isCouponActive" placeholder="Ajándékkártya kód" [(ngModel)]="shoppingCartService.couponCode"/>
    <span *ngIf="!shoppingCartService.isCouponActive" class="btn" (click)="activate()">Beváltás</span>
    <span *ngIf="shoppingCartService.isCouponActive" class="btn" (click)="deActivate()">Törlés</span>
</div>