import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/contracts/product';
import { ProductService } from 'src/app/dataServices/productService';

@Component({
  selector: 'app-pairings',
  templateUrl: './pairings.component.html',
  styleUrls: ['./pairings.component.scss'],
  providers: [ProductService]
})
export class PairingsComponent implements OnInit {
  public products: Product[];

  constructor(private productService: ProductService) {
    this.products = [];
  }

  ngOnInit() {
    this.productService.listPairings().then((p: any) => {
      this.products = p.Result;
      let mode = 0;

      this.products.forEach((element: Product) => {
        mode++;
        element.Mode = mode;

        if (mode == 3) {
          mode = 0;
        }
      });
    });
  }

}
