import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {

  @ViewChild('gcaptcha', { static: false }) captchaElem: RecaptchaComponent

  @Output() onResolved: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();

  private intervalId;

  constructor() { 

  }

  ngOnInit() {
    this.intervalId = setInterval((a) => {
      let element = document.getElementsByClassName('g-recaptcha-bubble-arrow')[0]

      if (element) {
        element.parentElement.style.position = 'fixed';
        clearInterval(a.intervalId);
      }

    }, 100, this);
  }

  public reset() {
    this.captchaElem.reset();
  }
  
  ngOnDestroy(){
    clearInterval(this.intervalId);
  }
}
