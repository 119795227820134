import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/contracts/product';
import { ProductService } from 'src/app/dataServices/productService';
import { ShopServices } from 'src/app/services/shop-service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  providers: [ProductService]
})
export class ShopComponent implements OnInit {
  public products: Product[];

  constructor(private productService: ProductService, private router: Router, private route: ActivatedRoute, public shopServices: ShopServices) {
    this.shopServices.queryParams = this.router.routerState.snapshot.root.queryParams;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.shopServices.queryParams = queryParams;
      this.getProducts();
    });
  }

  changeOrder(order:any){
    this.shopServices.orderByKey = order.value.Code;
    this.getProducts();
  }

  private getProducts(){
    this.productService.getAllActiveByCategory(this.shopServices.queryParams != null ? this.shopServices.queryParams.code : null, this.shopServices.orderByKey).then((products: Product[]) => {
      this.products = products;
    });
  }


}
