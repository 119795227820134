import { Component, OnInit } from '@angular/core';
import { ContextStore } from 'src/app/common/contextStore';
import { UserService } from 'src/app/dataServices/userService';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [UserService]
})
export class DashboardComponent implements OnInit {

  constructor(private userService: UserService, private contextStore: ContextStore) { }

  ngOnInit() {
  }

}
