<div class="size-table-wrapper">
    <span class="title">
        A modell a képen 173 cm magas és S méretű ruhát visel.
    </span>

    <table class="size-table">
        <thead>
            <tr>
                <th>Méret</th>
                <th>Mellbőség</th>
                <th>Derékbőség</th>
                <th>Csipőbőség</th>
                <th>Mellmélység</th>
                <th>Vállszélesség</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>S</td>
                <td>84 cm</td>
                <td>66 cm</td>
                <td>90 cm</td>
                <td>26 cm</td>
                <td>12 cm</td>
            </tr>

            <tr>
                <td>M</td>
                <td>88 cm</td>
                <td>70 cm</td>
                <td>94 cm</td>
                <td>27 cm</td>
                <td>12 cm</td>
            </tr>

            <tr>
                <td>L</td>
                <td>92 cm</td>
                <td>74 cm</td>
                <td>98 cm</td>
                <td>28 cm</td>
                <td>13 cm</td>
            </tr>
        </tbody>
    </table>

    <div class="size-table-mobile">
        <div>
            Méret: S
            <ul>
                <li>Mellbőség: 84 cm</li>
                <li>Derékbőség: 66 cm</li>
                <li>Csipőbőség: 90 cm</li>
                <li>Mellmélység: 26 cm</li>
                <li>Vállszélesség: 12 cm</li>
            </ul>
        </div>
        <div>
            Méret: M
            <ul>
                <li>Mellbőség: 88 cm</li>
                <li>Derékbőség: 70 cm</li>
                <li>Csipőbőség: 94 cm</li>
                <li>Mellmélység: 27 cm</li>
                <li>Vállszélesség: 12 cm</li>
            </ul>
        </div>
        <div>
            Méret: L
            <ul>
                <li>Mellbőség: 92 cm</li>
                <li>Derékbőség: 74 cm</li>
                <li>Csipőbőség: 98 cm</li>
                <li>Mellmélység: 28 cm</li>
                <li>Vállszélesség: 13 cm</li>
            </ul>
        </div>
    </div>
</div>