import { Category } from "./category";
import { Image } from "./image";
import { IBlog } from "./interfaces/iBlog"

export class Blog implements IBlog {
    public Id: number;
    public Title: string;
    public Created: Date;
    public Modified: Date
    public Text: string;
    public ShortText: string;
    public Published: boolean;
    public CoverImage: Image;
    public CategoryId: number;
}