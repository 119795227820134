import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICoupon } from 'src/app/contracts/interfaces/iCoupon';
import { CouponService } from 'src/app/dataServices/couponService';

var self;

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss'],
  providers: [CouponService]
})
export class CouponListComponent implements OnInit {

  public dataSource: ICoupon[];
  public displayedColumns = ['Id', 'Code', 'From', 'To', 'IsActive'];
    
  private selectedRowIndex: number = null;
  private selectedItem: any = null;
  private selection: SelectionModel<any>;
  private gridChangeSubscription: any;
  public isSelected: boolean = false;
 

  constructor(private couponService: CouponService, private router: Router, private translateService: TranslateService) {
    self = this;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(false);

    this.getAll();

    this.gridChangeSubscription = this.selection.changed.subscribe(this.change);
  }

  private getAll() {
    this.couponService.getAll().then((r: any) => {
      this.dataSource = r.Result;
    });
  }
  
  private change(e: any) {
    self.selectedRowIndex = e.added[0]['Id'];
    self.selectedItem = e.added[0];
    self.isSelected = true;
  }

  public onView() {
    this.router.navigateByUrl("adminsite/couponedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/couponlist', mode: 'ReadOnly' } } });
  }

  public onNew() {
    this.router.navigateByUrl("adminsite/couponedit", { state: { data: { routeBack: 'adminsite/categorylist', mode: 'New' } } });
  }

  public onEdit() {
    this.router.navigateByUrl("adminsite/couponedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/couponlist', mode: 'Modify' } } });
  }

  public onDelete() {
    if (confirm(this.translateService.instant("Confirm"))) {
      this.couponService.delete(this.selectedItem.Id).then(r => {
        this.getAll();
        this.isSelected = false;
        this.selectedItem = null;
      });
    }
  }

  ngOnDestroy(): void {
    this.gridChangeSubscription.unsubscribe();
  }

}
