<div *ngIf="successActivation">Sikeres aktiválás</div>
<div *ngIf="successUnSubscribe">Sikeres leiratkozás</div>

<div *ngIf="unsuccessActivation">Sikertelen aktiválás</div>
<div *ngIf="unsuccessUnSubscribe">Sikertelen leiratkozás</div>

<div *ngIf="unSubscribeRequest" class="unsubscribe-request"> 
    <h1>
        Leiratkozás a hírlevélről
    </h1>

    <div>
        <span class="text">E-mail cím: </span>
        <span>
            <input type="text" [(ngModel)]="subscription.Email" class="email" />
        </span>

        <div>
            <button mat-flat-button (click)="openCaptcha()"> Leiratkozás</button>
        </div>
    </div>
</div>

<div *ngIf="unSubscribeRequestMessage" class="text spacing">
    A leiratkozás megerősítéséhez ellenőrizd az e-mail címét.
</div>
