import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'size-table',
  templateUrl: './size-table.component.html',
  styleUrls: ['./size-table.component.scss']
})
export class SizeTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
