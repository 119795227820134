<div class="wrapper">
    <div class="question">
        <div class="question-text">
            <h1>{{faq.Question}}</h1>
        </div>
        <div class="buttons">
            <img *ngIf="isClosed" (click)="change()" src="/assets/open.svg" />
            <img *ngIf="!isClosed" (click)="change()" src="/assets/close.svg" />
        </div>
    </div>
    <div *ngIf="!isClosed" class="answer">
        <p [innerHTML]="faq?.Answer | keepHtml"></p>
    </div>
</div>