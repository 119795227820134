<div class="wrapper admin">
    <div>
        <span>Esedékesség: &nbsp;</span>
        <input  color="primary" matInput [(ngModel)]="model.DueDate" [matDatepicker]="dueDatePicker">
        <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dueDatePicker></mat-datepicker>
    </div>

    <div>
        <span>Teljesítés: &nbsp;</span>
        <input  color="primary" matInput [(ngModel)]="model.FullFillmentDate" [matDatepicker]="fullfillmentDatePicker">
        <mat-datepicker-toggle matSuffix [for]="fullfillmentDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fullfillmentDatePicker></mat-datepicker>
    </div>

    <button mat-raised-button color="primary" [disabled]="!model.DueDate || !model.FullFillmentDate" (click)="createBill()">Számla kiállítása</button>
</div>