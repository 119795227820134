import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Content } from 'src/app/contracts/content';
import { ContentService } from 'src/app/dataServices/contentService';

@Component({
  selector: 'card-half',
  templateUrl: './card-half.component.html',
  styleUrls: ['./card-half.component.scss'],
  providers: [ContentService]
})
export class CardHalfComponent implements OnInit {

  @Input() code: string;
  @Input() url: string;
  @Input() btnText: string;
  @Input() urlParam: string;

  public content: Content;
  
  constructor(private contentService: ContentService, private router: Router) {
  }

  ngOnInit() {
    this.contentService.getByCode(this.code).then((r: Content) => {
      this.content = r;
    });
  }

  navigate(){
    this.router.navigate([this.url], { queryParams: { code: this.urlParam } });
  }
}
