import { Address } from './address';
import { Coupon } from './coupon';
import { DeliveryMethod } from './deliveryMethod';
import { OrderDataProduct } from './orderDataProduct';

export class OrderData {
    public Name: string;
    public Phone: string;
    public Email: string;
    public BillingAddress: Address;
    public DeliveryAddress: Address;
    public Comment: string;
    public OtherAddress: boolean;
    public DeliveryMethod: DeliveryMethod;
    public PaymentMethod: string;
    public OrderDataProducts: OrderDataProduct[];
    public Price: number;
    public Status: number;
    public IsNew: boolean;
    public IdYear: number;
    public Year: number;
    public OrderDate: Date;
    public OrderId: string;
    public DeliveryMethodPrice: number;
    public TaxNumber: string;
    public Coupon: Coupon;

    public PostPointId:number;
    public PostPointAddress: string;
    public PostPointName: string;

    constructor() {
        this.BillingAddress = new Address();
        this.OrderDataProducts = [];
    }
}