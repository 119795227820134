import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InstaPost } from 'src/app/contracts/instaPost';
import { InstaPostService } from 'src/app/dataServices/instaPostService';
import { ImagePickerComponent } from 'src/app/directives/image-picker/image-picker.component';
import { MediaUploaderComponent } from 'src/app/directives/media-uploader/media-uploader.component';

@Component({
  selector: 'app-instapost-edit',
  templateUrl: './instapost-edit.component.html',
  styleUrls: ['./instapost-edit.component.scss'],
  providers: [InstaPostService]
})
export class InstapostEditComponent implements OnInit {
  public instaPost: InstaPost;
  private mediaUploader: MediaUploaderComponent;
  private imagePickerComponent: ImagePickerComponent;
  private pageData: any;
  public readOnly: boolean;

  @ViewChild('mediaUploader', { static: false }) set file(component: MediaUploaderComponent) {
    if (component) {
      this.mediaUploader = component;
      this.mediaUploader.loadByFilter("InstaPost");
    }
  }

  @ViewChild('imagePicker', { static: false }) set imagePicker(component: ImagePickerComponent) {
    if (component) {
      this.imagePickerComponent = component;
    }
  }

  constructor(private instaPostService: InstaPostService, private router: Router) { 
    this.instaPost = new InstaPost();

    this.pageData = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.data : null;
    this.readOnly = this.pageData.mode == "ReadOnly";
  }

  ngOnInit() {
    this.getById();
  }

  private getById() {
    this.instaPostService.getById(this.pageData.id).then((r: any) => {      
      this.instaPost = r.Result;
    });
  }


  public handleImage(image: any){
    this.instaPost.Image = image;
  }

  public onImagesChanging(){

  }

  public imagesLoaded(images: any){
    this.imagePickerComponent.getImages();
  }

  public save(){
    this.instaPostService.save(this.instaPost).then(r=>{
      this.cancel();
    });
  }

  public cancel(){
    this.router.navigateByUrl(this.pageData.routeBack);
  }
}
