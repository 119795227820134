import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-check',
  templateUrl: './order-check.component.html',
  styleUrls: ['./order-check.component.scss'],
  providers:[{ provide: MAT_DIALOG_DATA, useValue: {} }]
})
export class OrderCheckComponent implements OnInit {
  public orderId: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.orderId =this.route.snapshot.params.orderId;
    console.log(this.orderId);
  }

}
