import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductType } from 'src/app/contracts/productType';
import { ProductTypeService } from 'src/app/dataServices/productTypeService';

@Component({
  selector: 'app-producttype-edit',
  templateUrl: './producttype-edit.component.html',
  styleUrls: ['./producttype-edit.component.scss'],
  providers: [ProductTypeService]
})
export class ProducttypeEditComponent implements OnInit {

  public productType: ProductType;
  private pageData: any;
  public readOnly: boolean;

  constructor(private productTypeService: ProductTypeService, private router: Router) {
    this.productType = new ProductType();
    this.pageData = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.data : null;
    this.readOnly = this.pageData.mode == "ReadOnly";
  }

  ngOnInit() {
    if (this.pageData.mode != "New"){
      this.getById();
    }
  }

  private getById() {
    this.productTypeService.getById(this.pageData.id).then((r: ProductType) => {
      this.productType = r;
    });
  }

  public cancel(){
    this.router.navigateByUrl(this.pageData.routeBack);
  }

  public save() {
    this.productTypeService.save(this.productType).then(r => {
      this.router.navigateByUrl(this.pageData.routeBack);
    });
  }

}
