<div *ngIf="content" class="wrapper">
    <div class="left">
        <div class="content">
            <span>
                {{content.Title}}
            </span>

            <div class="description" [innerHTML]="content.Description | keepHtml">
            </div>

            <div class="action-btn">
                <button mat-flat-button class="read-more" (click)="navigate()">Tovább</button>
            </div>
        </div>
    </div>

    <div class="right">
        <img [src]="content.Thumbnail.FileName" class="image" />
    </div>
</div>