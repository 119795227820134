import { HttpService } from "../common/httpService";
import { QueryStringParameter } from "../contracts/queryStringParameter";
import { Base } from "./base";

export class BlogService extends Base {

    constructor(httpService: HttpService) {
        super(httpService, "BlogController");
    }

    public getAllActiveById(categoryId: number) {
        return this.httpService.get(this.controller, 'GetAllActive', [new QueryStringParameter("categoryId", categoryId)]);
    }

    public getActiveById(id: number) {
        return this.httpService.get(this.controller, 'GetActiveById', [new QueryStringParameter("Id", id)]);
    }
}