import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/contracts/product';

@Component({
  selector: 'info-switcher',
  templateUrl: './info-switcher.component.html',
  styleUrls: ['./info-switcher.component.scss']
})
export class InfoSwitcherComponent implements OnInit {
  @Input() product: Product;
  public id: number;
  private has:boolean;

  constructor() {    
  }

  ngOnInit() {
    if (this.product.IsHandle) {
      this.id = 3;
    }
    else if (this.product.IsMeasure) {
      this.id = 2;
    }
    else if (this.product.IsSizeTable) {
      this.id = 1;
    }
    else if (this.product.IsTransport) {
      this.id = 4;
    }
  }

  public show(id: number) {
    this.id = id;
    console.log(id);
  }
}
