
<div class="wrapper">
    <div class="cart-message" *ngIf="showCart">
        <div>
            <img class="close" (click)="closeCartMessage()" src="../../../assets/close2.svg" />
            {{ product?.Name }} bekerült a kosárba
        </div>
        <button  mat-flat-button (click)='navigateToCart()'  class="btn cart" >Kosár</button>
    </div>

    <div class="page">
        <div class="media-content">
            <image-viewer *ngIf="product" [item]="product"></image-viewer>
        </div>

        <div class="text-content">
            <span class="breadcrumb">SHOP » {{product?.Category?.Name}} » {{product?.Name}}</span>


            <h1>{{ product?.Name }}</h1>
            <h2>{{ product?.Price | number:'.0' | currency }} Forint</h2>

            
            <div class="line"></div>

            <div class="description" [innerHTML]="product?.Description | keepHtml"></div>

            <div  *ngFor="let property of product?.Properties">
                <property-picker [property]="property"></property-picker>
            </div>

            <div class="service">
                <product-services [productServices]="product?.Services"></product-services>
            </div>

            <place-in-cart (onAdded)="onProductAdded()" [product]="product"></place-in-cart>
        </div>
    </div>

    <mobile-slideshow *ngIf="product" [mediaContent]="product.MediaContent" class="mobile-slideshow"></mobile-slideshow>
    <info-switcher *ngIf="product" [product]="product"></info-switcher>
    <pairings *ngIf="product" [pairings]="product.Pairings"></pairings>
    <favourites [showLines]="true"></favourites>
</div>