import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../services/tokenService'

@Injectable()
export class AuthService {
  constructor(private router: Router, private tokenService: TokenService) { }

  private userToken: string;

  public setUserToken(sessionId: string, key: string) {
    //this.userToken = token;
    this.tokenService.saveToken(sessionId, key);
  }

  public getUserToken() {
    return this.tokenService.loadToken();
  }
}
