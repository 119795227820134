import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'favourite-item',
  templateUrl: './favourite-item.component.html',
  styleUrls: ['./favourite-item.component.scss']
})
export class FavouriteItemComponent implements OnInit {
  @Input() src: string;
  @Input() name: string;
  @Input() id: number;
  @Input() isPairing = false;
  @Output() onEnter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isVisible: boolean;

  constructor(private router: Router) {
    this.isVisible = false;
  }

  ngOnInit() {
  }

  public enter() {
    this.isVisible = true;
    this.onEnter.emit(true);
  }

  public leave() {
    this.isVisible = false;
    this.onEnter.emit(false);
  }

  public navigate(){
    this.router.navigate(['/pages/product'], { queryParams: { id: this.id } });
  }
}
