import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from 'src/app/contracts/login';
import { UserService } from 'src/app/dataServices/userService';
import { TokenService } from 'src/app/services/tokenService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserService]
})
export class LoginComponent implements OnInit {
  public email: string;
  public password: string;

  constructor(private router: Router, private userService: UserService, private tokenService:TokenService) { }

  ngOnInit() {
    if (this.tokenService.hasValidToken()) {
      this.userService.authenticate().then(res => {
        //this.getContext();
        //this.config.LogedIn = true;
        this.router.navigate(["adminsite/dashboard"]);
      },
        msg => {
        });
    }
  }

  public login(){
    this.userService.login(new Login(this.email, this.password)).then(res=>{
      this.email = null;
      this.password = null;
      //this.getContext();
      //this.config.LogedIn = true;
      this.router.navigate(["adminsite/dashboard"]);
    },
      msg => {
        
        alert('Sikertelen bejelentkezés')
      });

  }
}
