<!--<media-uploader #mediaUploader type="Blog" [id]="blog?.Id" (onImagesChangeEvent)="onImagesChanging()" (onImagesLoadedEvent)="imagesLoaded($event)" [disabled]="readOnly"> </media-uploader>-->

<mat-card class="card" *ngIf="faq">
    <div class="action-button-wrapper">
        <button mat-raised-button color="primary" class="btn-action" (click)="save()" [disabled]="readOnly" >{{ 'Save' | translate }}</button>
        <button mat-raised-button color="secondary" class="btn-action" (click)="cancel()" >{{ 'Cancel' | translate }}</button>
    </div>

    <mat-form-field class="input-full-width">
        <mat-label>Kérdés</mat-label>
        <input matInput placeholder="Kérdés" [(ngModel)]="faq.Question" [disabled]="readOnly">
    </mat-form-field>

    <div class="editor">
        <span>Válasz</span>
        <text-editor [(data)]="faq.Answer" *ngIf="isEditorVisible" [disabled]="readOnly"></text-editor>
    </div>
</mat-card>