import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Content } from 'src/app/contracts/content';
import { ContentService } from 'src/app/dataServices/contentService';

@Component({
  selector: 'card-full',
  templateUrl: './card-full.component.html',
  styleUrls: ['./card-full.component.scss'],
  providers: [ContentService]
})
export class CardFullComponent implements OnInit {
  @Input() code: string;
  @Input() url: string;

  public content: Content;

  constructor(private contentService: ContentService, private router: Router) {
  }

  ngOnInit() {
    this.contentService.getByCode(this.code).then((r: Content) => {
      this.content = r;
    });
  }

  navigate() {
    this.router.navigate([this.url], { queryParams: { code: this.code } });
  }

}
