import { Content } from '../../../contracts/content'
import { Image } from '../../../contracts/image'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/dataServices/contentService';
import { MediaUploaderComponent } from 'src/app/directives/media-uploader/media-uploader.component';
import { ImagePickerComponent } from 'src/app/directives/image-picker/image-picker.component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  providers: [ContentService]
})
export class ContentComponent implements OnInit {
  public isMenuSelected: boolean;
  public content: Content;
  public imageList = [];
  public isEditorVisible = true;

  private mediaUploader: MediaUploaderComponent;
  private imagePickerComponent: ImagePickerComponent;

  @ViewChild('mediaUploader', { static: false }) set file(component: MediaUploaderComponent) {
    if (component) {
      this.mediaUploader = component;
    }
  }

  @ViewChild('imagePicker', { static: false }) set imagePicker(component: ImagePickerComponent) {
    if (component) {
      this.imagePickerComponent = component;
    }
  }

  constructor(private contentService: ContentService) {
    this.content = new Content();
    this.isMenuSelected = false;
  }

  ngOnInit() {
  }

  public changeMenu(menu: any) {
    this.isEditorVisible = false;
    this.contentService.getByCode(menu.value).then((r: Content) => {
      this.isMenuSelected = true;
      this.content = r;
      this.mediaUploader.load("Content", r.Id);
    });
  }

  public save() {
    this.contentService.update(this.content).then(r => {
      alert('Sikeres mentés');
    }).catch(r => {
      alert("Sikertelen mentés");
    });
  }

  public onImagesChanging() {
    this.isEditorVisible = false;
  }

  public imagesLoaded(images: Image[]) {
    let list = [];
    images.forEach(e => {
      list.push({ title: e.Name, value: e.FileName });
    });

    if (this.imagePickerComponent) {
      this.imagePickerComponent.getImages();
    }

    this.imageList = list;
    this.isEditorVisible = true;
  }

  public handleImage(image: Image) {
    this.content.Thumbnail = image;
  }

  public changeCta(cta: any) {
    this.content.Cta = cta.value;
  }
}
