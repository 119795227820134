<mat-card class="card" >
    <div>
        <button mat-raised-button color="primary" class="btn-send" (click)="send()">Küldés</button>
    </div>

    <mat-form-field class="input-full-width">
        <mat-label>Tárgy</mat-label>
        <input matInput placeholder="Tárgy" [(ngModel)]="newsletter.Subject">
    </mat-form-field>

    <div class="editor">
        <span>Üzenet</span>
        <text-editor [(data)]="newsletter.Body"></text-editor>
    </div>
</mat-card>