import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog } from 'src/app/contracts/blog';
import { BlogService } from 'src/app/dataServices/blogService';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  providers: [
    BlogService
  ]
})
export class BlogComponent implements OnInit {
  public blog: Blog;

  private queryParams: any;
  private categories = [
    { code: 1, viewValue: 'Vivian tippek' },
    { code: 2, viewValue: 'Minden, ami esküvő' },
    { code: 3, viewValue: 'Esküvői történetek' },
  ];
  

  constructor(private blogService: BlogService,  private route: ActivatedRoute, private router: Router) { 
   this.queryParams = this.router.routerState.snapshot.root.queryParams; 
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.blogService.getActiveById(queryParams.id).then((b: Blog) => {
        this.blog = b;
      });
    });
  }

  getCategoryById(id: number){
    let item  = this.categories.find(f=>{
      return f.code == id;
    })

    return item.viewValue;
  }

}
