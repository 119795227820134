<div class="wrapper">
    <h1>
        HÍRLEVÉL
    </h1>
    <h2>
        Iratkozz fel hírlevelünkre, hogy az elsők között értesülhess legújabb termékeink érkezéséről, akcióinkról! <br /> <br/>Bízz bennünk, nem telítjük meg a postaládádat!
    </h2>
    <div class="input-fields">
        <input placeholder="Név" type="text" [(ngModel)]="subscription.Name" />
        <input placeholder="E-mail cím" type="text" [(ngModel)]="subscription.Email" />
    </div>
    <div class="privacy-statement newsletter">
        <mat-checkbox [disableRipple]="true" [(ngModel)]="acceptGdpr"></mat-checkbox>
        <span>Elfogadom az <u><b>adatvédelmi nyilatkozatot</b></u></span>
    </div>
    <div class="action-button">
        <button [disabled]="!acceptGdpr" mat-flat-button (click)="openCaptcha()">Küldés</button>
    </div>
</div>