import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cta-switcher',
  templateUrl: './cta-switcher.component.html',
  styleUrls: ['./cta-switcher.component.scss']
})
export class CtaSwitcherComponent implements OnInit {
  @Input() cta: string;

  constructor() { }

  ngOnInit() {
  }

}
