<div class="wrapper">
    <div class="image-show">
        <img class="pager" src="/assets/prev.png" (click)="prev()" />
        
        <img [src]="current.FileName" *ngIf="!current.IsMovie" width="460" height="694" class="image">

        <video width="460" height="694" *ngIf="current.IsMovie" controls>
            <source [src]="current.FileName" type="video/mp4">
        </video>

        <img class="pager" src="/assets/next.png" (click)="next()" />
    </div>
    <span class="image-counter">
        {{mediaContentDisplayIndex}}/{{mediaContentCount}}
    </span>
</div>