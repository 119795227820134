<action-header (onViewEvent)="onView()" (onNewEvent)="onNew()" (onEditEvent)="onEdit()" (onDeleteEvent)="onDelete()"
    [isSelected]="isSelected"></action-header>

<mat-card class="card admin">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef> {{ 'Id'| translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
        </ng-container>

        <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef> {{ 'Title'| translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.Title}} </td>
        </ng-container>

        <ng-container matColumnDef="Published">
            <th mat-header-cell *matHeaderCellDef> {{ 'Published'| translate }} </th>
            <td mat-cell *matCellDef="let element"> <input type="checkbox" checked *ngIf="element.Published" disabled /> <input type="checkbox" *ngIf="!element.Published" disabled /></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'selected': selectedRowIndex === row.Id}" (click)="selection.select(row)">
        </tr>
    </table>
</mat-card>