<div class="site">
    <div class="top">
        <div class="header">
            <img class="mobile-menu-btn" src="../../../assets/mobile_menu.svg" (click)="isShowMenu=true"
                style="position: absolute; cursor: pointer;" />

            <div class="logo">
                <img src="../../../assets/logo_main.png" height="63px" (click)="navigate('/pages/home')" />
            </div>

            <div class="action-btns">
               <!-- <icon-btn src="../../../assets/search.svg"></icon-btn>-->
                <!--<icon-btn src="../../../assets/bag.png" (click)="navitgateToCart()"></icon-btn>-->


                <div class="container" (click)="navitgateToCart()">
                    <img src="../../../assets/bag.svg" />
                    <div class="notification">
                        <span>{{cartServices.shoppingCart.length}}</span>
                    </div>
                </div>

            </div>
        </div>
        <div class="menu">
            <button mat-button [disableRipple]="true" class="menu-btn uppercase" [matMenuTriggerFor]="shopMenu">{{
                'ShopMenu' | translate}} <span class="arrow-down"></span></button>
            <mat-menu #shopMenu="matMenu" xPosition="after" class="sub-menu-container">
                <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                    (click)="navigate('/pages/shop')">Összes</button>

                <div *ngFor="let category of categories">
                    <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                        (click)="navigateShop('/pages/shop', category)">{{category.Name}}</button>
                </div>
                <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                    (click)="navigate('/pages/pairings')">Kedvenc párosítások</button>
            </mat-menu>

            <button mat-button [disableRipple]="true" class="menu-btn uppercase" (click)="navigate('/pages/aboutus')">{{'AboutUsMenu' | translate}}</button>

                <button mat-button [disableRipple]="true" class="menu-btn uppercase" [matMenuTriggerFor]="blogMenu">Blog <span class="arrow-down"></span></button>
                <mat-menu #blogMenu="matMenu" xPosition="after" class="sub-menu-container">
                    
                    <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                        (click)="navigate('/pages/bloglist')">Összes</button>

                    <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                        (click)="navigateWithParam('/pages/bloglist',1)">Vivian tippek</button>
                        
                    <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                        (click)="navigateWithParam('/pages/bloglist',2)">Minden, ami esküvő</button>
                        
                    <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                        (click)="navigateWithParam('/pages/bloglist',3)">Esküvői történetek</button>
                </mat-menu>

            <button mat-button [disableRipple]="true" class="menu-btn uppercase" (click)="navigate('/pages/faq')">{{
                'FaqMenu' | translate }}</button>
            <button mat-button [disableRipple]="true" class="menu-btn uppercase" (click)="navigate('/pages/contact')">{{
                'ContactMenu' | translate }}</button>

        </div>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <div class="footer">
        <div class="logo">
            <img src="/assets/vivian.svg" height="56px">
        </div>
        <div class="text">
            Kövess minket social médián is, hogy semmiről ne maradj le!
        </div>
        <social-media></social-media>
        <div class="info">
            <link-content code="84498032-f5f0-4d0b-82ab-827c1f84b0a4" url="/pages/content"></link-content>
            <link-content code="00943e21-e818-4109-98b4-b886a7b68f3f" url="/pages/content"></link-content>
            <link-content code="197eb8a2-0ffe-410b-b759-4ca1529a1b85" url="/pages/content"></link-content>
        </div>
    </div>

    <div class="cards">
        <img src="../../../assets/cards.png" />
    </div>

    <span class="info-text">Vivian.hu 2020 - Minden jog fenntartva! A vállalkozás létrehozását és fejlesztését a
        “Vállalkozó Start” program keretében az OFA Nonprofit Kft. támogatta. A támogatás forrását a Nemzeti
        Foglalkoztatási Alap biztosította.</span>
</div>

<ng-container *ngIf="isShowMenu">
    <div class="mobile-backdrop" (click)="isShowMenu=false"></div>
    <div class="mobile-menu">
        <div class="action-btns">
            <icon-btn src="../../../assets/bag.svg" (click)="navitgateToCart()"></icon-btn>
        </div>

        <button mat-button [disableRipple]="true" (click)="isShowCategoryMenu = !isShowCategoryMenu"
            class="menu-btn uppercase">{{'ShopMenu' | translate}}</button>

        <ng-container *ngIf="isShowCategoryMenu">
            <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                (click)="navigate('/pages/shop')">Összes</button>
            <div *ngFor="let category of categories">
                <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                    (click)="navigateShop('/pages/shop', category)">{{category.Name}}</button>

            </div>
            <button [disableRipple]="true" mat-menu-item class="menu-btn sub-menu uppercase"
                (click)="navigate('/pages/pairings')">Kedvenc párosítások</button>
        </ng-container>

        <!--<button mat-button [disableRipple]="true" class="menu-btn uppercase"
            (click)="isShowAboutUsMenu = !isShowAboutUsMenu">{{'AboutUsMenu'
            | translate}}</button>
        <div *ngIf="isShowAboutUsMenu">
            <button mat-menu-item [disableRipple]="true" class="menu-btn uppercase sub-menu"
                (click)="navigateWithParam('/pages/content', 'acc85bc2-910e-4d80-a216-a31accf5975b')">{{ 'BrandMenu'
                | translate }}</button>
            <button mat-menu-item [disableRipple]="true" class="menu-btn uppercase sub-menu"
                (click)="navigateWithParam('/pages/content', 'c640f10c-146f-4a84-a564-82fb33ac8cbd')">{{
                'DesignerMenu' | translate }}</button>
        </div>-->

        <button mat-button [disableRipple]="true" class="menu-btn uppercase" (click)="navigate('/pages/aboutus')">{{
            'AboutUsMenu' | translate}}</button>

        <button mat-button [disableRipple]="true" class="menu-btn uppercase" (click)="navigate('/pages/bloglist')">{{
            'BlogMenu' | translate }}</button>
        <button mat-button [disableRipple]="true" class="menu-btn uppercase" (click)="navigate('/pages/faq')">{{
            'FaqMenu' | translate }}</button>
        <button mat-button [disableRipple]="true" class="menu-btn uppercase" (click)="navigate('/pages/contact')">{{
            'ContactMenu' | translate }}</button>

    </div>
</ng-container>