import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EditorModule } from "@tinymce/tinymce-angular";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpService } from './common/httpService';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from "ng-recaptcha";
import { NgxCookiebotConfig, NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from './cookiebot.config';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule, MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
//import { MatAccordionModule } from '@angular/material/';

import { HomeComponent } from './pages/home/home.component';
import { ContentComponent } from './pages/content/content.component';
import { LoginComponent } from './pages/admin/login/login.component';
import { MasterpageComponent as AdminMasterPage } from './pages/admin/masterpage/masterpage.component';
import { MasterpageComponent as PageMasterPage } from './pages/masterpage/masterpage.component';

import { CurrentUserComponent } from './directives/current-user/current-user.component';
import { TextEditorComponent } from './directives/text-editor/text-editor.component';
import { ContentComponent as AdminContentComponent } from './pages/admin/content/content.component';
import { MenuPickerComponent } from './directives/menu-picker/menu-picker.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { BlogListComponent as AdminBlogListComponent } from './pages/admin/blog-list/blog-list.component';
import { BlogEditComponent } from './pages/admin/blog-edit/blog-edit.component';
import { ActionHeaderComponent } from './directives/action-header/action-header.component';

import { MediaUploaderComponent } from './directives/media-uploader/media-uploader.component';
import { ImagePickerComponent } from './directives/image-picker/image-picker.component';
import { DateTime } from './common/dateTime';
import { CategoryListComponent } from './pages/admin/category-list/category-list.component';
import { CategoryEditComponent } from './pages/admin/category-edit/category-edit.component';
import { CategoryPickerComponent } from './directives/category-picker/category-picker.component';
import { ProducttypeListComponent } from './pages/admin/producttype-list/producttype-list.component';
import { ProducttypeEditComponent } from './pages/admin/producttype-edit/producttype-edit.component';
import { ProductListComponent } from './pages/admin/product-list/product-list.component';
import { ProductEditComponent } from './pages/admin/product-edit/product-edit.component';
import { IconBtnComponent } from './directives/icon-btn/icon-btn.component';
import { CtaMainComponent } from './directives/cta-main/cta-main.component';
import { CtaCollectionComponent } from './directives/cta-collection/cta-collection.component';
import { CardFullComponent } from './directives/card-full/card-full.component';
import { CardHalfComponent } from './directives/card-half/card-half.component';
import { CtaPairingsComponent } from './directives/cta-pairings/cta-pairings.component';
import { FavouritesComponent } from './directives/favourites/favourites.component';
import { FavouriteItemComponent } from './directives/favourite-item/favourite-item.component';
import { NewsletterComponent } from './directives/newsletter/newsletter.component';
import { SocialMediaComponent } from './directives/social-media/social-media.component';
import { LinkContentComponent } from './directives/link-content/link-content.component';
import { IgnoreSanitize } from './pipes/ignoreSanitize';
import { Currency } from './pipes/currency';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogListComponent } from './pages/blog-list/blog-list.component';
import { BlogListItemComponent } from './directives/blog-list-item/blog-list-item.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqItemComponent } from './directives/faq-item/faq-item.component';
import { CtaPickerComponent } from './directives/cta-picker/cta-picker.component';
import { CtaSwitcherComponent } from './directives/cta-switcher/cta-switcher.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ShopComponent } from './pages/shop/shop.component';
import { ShopItemComponent } from './directives/shop-item/shop-item.component';
import { AlignPickerComponent } from './directives/align-picker/align-picker.component';
import { ShopServices } from './services/shop-service';
import { ProductComponent } from './pages/product/product.component';
import { ImageViewerComponent } from './directives/image-viewer/image-viewer.component';
import { CardFullLeftImageComponent } from './directives/card-full-left-image/card-full-left-image.component';
import { ProductPickerComponent } from './directives/product-picker/product-picker.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { AuthService } from './services/authService';
import { TokenService } from './services/tokenService';
import { LocalStorageService, NgxLocalStorageModule } from 'ngx-localstorage';
import { LoaderService } from './services/loaderService';
import { ProcessingService } from './services/processingService';
import { ContextStore } from './common/contextStore';
import { LoginRestrict } from './common/loginRestrict';
import { UserService } from './dataServices/userService';
import { SlideshowComponent } from './directives/slideshow/slideshow.component';
import { ProductServicesComponent } from './directives/product-services/product-services.component';
import { SizeTableComponent } from './directives/size-table/size-table.component';
import { InfoSwitcherComponent } from './directives/info-switcher/info-switcher.component';
import { HowToCareComponent } from './directives/how-to-care/how-to-care.component';
import { PropertyPickerComponent } from './directives/property-picker/property-picker.component';
import { PropertyPickerItemComponent } from './directives/property-picker-item/property-picker-item.component';
import { PlaceInCartComponent } from './directives/place-in-cart/place-in-cart.component';
import { ShoppingCartService } from './services/shopping-cart-service';
import { CartComponent } from './pages/cart/cart.component';
import { OrderWizardComponent } from './directives/order-wizard/order-wizard.component';
import { TextInputComponent } from './directives/text-input/text-input.component';
import { Constants } from './common/constants';
import { CaptchaComponent } from './directives/captcha/captcha.component';
import { OrderListComponent } from './pages/admin/order-list/order-list.component';
import { CartMessageComponent } from './pages/cart-message/cart-message.component';
import { OrderDataDisplayComponent } from './directives/order-data-display/order-data-display.component';
import { OrderCheckComponent } from './pages/order-check/order-check.component';
import { BillingFormComponent } from './directives/billing-form/billing-form.component';
import { NewslettersendComponent } from './pages/admin/newslettersend/newslettersend.component';
import { ChangepasswordComponent } from './pages/admin/changepassword/changepassword.component';
import { UserListComponent } from './pages/admin/user-list/user-list.component';
import { UserEditComponent } from './pages/admin/user-edit/user-edit.component';
import { MobileSlideshowComponent } from './directives/mobile-slideshow/mobile-slideshow.component';
import { PairingsComponent } from './directives/pairings/pairings.component';
import { PairingsComponent as PairingsPage } from './pages/pairings/pairings.component';
import { ProductService } from './dataServices/productService';
import { FaqListComponent } from './pages/admin/faq-list/faq-list.component';
import { FaqEditComponent } from './pages/admin/faq-edit/faq-edit.component';
import { ServicePickerComponent } from './directives/service-picker/service-picker.component';
import { PairingLeftItemComponent } from './directives/pairing-left-item/pairing-left-item.component';
import { InstapostListComponent } from './pages/admin/instapost-list/instapost-list.component';
import { InstapostEditComponent } from './pages/admin/instapost-edit/instapost-edit.component';
import { InstaPostListComponent } from './directives/insta-post-list/insta-post-list.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PairingRightItemComponent } from './directives/pairing-right-item/pairing-right-item.component';
import { PairingLeft2ItemComponent } from './directives/pairing-left2-item/pairing-left2-item.component';
import { BlogCategoryPickerComponent } from './directives/blog-category-picker/blog-category-picker.component';
import { CouponListComponent } from './pages/admin/coupon-list/coupon-list.component';
import { CouponEditComponent } from './pages/admin/coupon-edit/coupon-edit.component';
import { CouponComponent } from './directives/coupon/coupon.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { PairingMobileComponent } from './directives/pairing-mobile/pairing-mobile.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AdminMasterPage,
    PageMasterPage,
    CurrentUserComponent,
    TextEditorComponent,
    ContentComponent,
    AdminContentComponent,
    MenuPickerComponent,
    DashboardComponent,
    BlogListComponent,
    AdminBlogListComponent,
    BlogEditComponent,
    ActionHeaderComponent,
    MediaUploaderComponent,
    ImagePickerComponent,
    CategoryListComponent,
    CategoryEditComponent,
    CategoryPickerComponent,
    ProducttypeListComponent,
    ProducttypeEditComponent,
    ProductListComponent,
    ProductEditComponent,
    IconBtnComponent,
    CtaMainComponent,
    CtaCollectionComponent,
    CardFullComponent,
    CardHalfComponent,
    CtaPairingsComponent,
    FavouritesComponent,
    FavouriteItemComponent,
    NewsletterComponent,
    SocialMediaComponent,
    LinkContentComponent,
    IgnoreSanitize,
    Currency,
    BlogComponent,
    BlogListItemComponent,
    FaqComponent,
    FaqItemComponent,
    CtaPickerComponent,
    CtaSwitcherComponent,
    ContactComponent,
    ShopComponent,
    ShopItemComponent,
    AlignPickerComponent,
    ProductComponent,
    ImageViewerComponent,
    CardFullLeftImageComponent,
    ProductPickerComponent,
    SubscriptionComponent,
    SlideshowComponent,
    ProductServicesComponent,
    SizeTableComponent,
    InfoSwitcherComponent,
    HowToCareComponent,
    PropertyPickerComponent,
    PropertyPickerItemComponent,
    PlaceInCartComponent,
    CartComponent,
    OrderWizardComponent,
    TextInputComponent,
    CaptchaComponent,
    OrderListComponent,
    CartMessageComponent,
    OrderDataDisplayComponent,
    OrderCheckComponent,
    BillingFormComponent,
    NewslettersendComponent,
    ChangepasswordComponent,
    UserListComponent,
    UserEditComponent,
    MobileSlideshowComponent,
    PairingsComponent,
    PairingsPage,
    FaqListComponent,
    FaqEditComponent,
    ServicePickerComponent,
    PairingLeftItemComponent,
    InstapostListComponent,
    InstapostEditComponent,
    InstaPostListComponent,
    AboutUsComponent,
    PairingRightItemComponent,
    PairingLeft2ItemComponent,
    BlogCategoryPickerComponent,
    CouponListComponent,
    CouponEditComponent,
    CouponComponent,
    PairingMobileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    HttpClientModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    EditorModule,
    FormsModule,
    MatTabsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatAutocompleteModule,
    RecaptchaModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    NgxLocalStorageModule.forRoot(),
    NgxCookiebotModule.forRoot(CookiebotConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    HttpService,
    DateTime,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ShopServices,
    AuthService,
    TokenService,
    LocalStorageService,
    LoaderService,
    ProcessingService,
    ContextStore,
    LoginRestrict,
    UserService,
    ShoppingCartService,
    Constants,
    ProductService,
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}},
    {provide: 'googleTagManagerId',  useValue: 'GTM-NMPGLW8'}
  ],
  entryComponents: [
    SlideshowComponent,
    OrderDataDisplayComponent,
    CaptchaComponent,
    BillingFormComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'hu']);
    translate.setDefaultLang('hu');
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/translations/");
}