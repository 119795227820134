import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'action-header',
  templateUrl: './action-header.component.html',
  styleUrls: ['./action-header.component.scss']
})
export class ActionHeaderComponent implements OnInit {

  @Input() custom1ButtonVisible: boolean = false;
  @Input() custom2ButtonVisible: boolean = false;
  @Input() custom3ButtonVisible: boolean = false;

  @Input() custom1Icon: string = "settings_suggest";
  @Input() custom2Icon: string = "settings_suggest";
  @Input() custom3Icon: string = "settings_suggest";

  @Input() isSelected: boolean;
  
  @Output() onViewEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNewEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCustom1Event: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCustom2Event: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCustom3Event: EventEmitter<any> = new EventEmitter<any>();
  

  constructor() { }

  ngOnInit() {
  }

}
