import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/contracts/product';

@Component({
  selector: 'product-services',
  templateUrl: './product-services.component.html',
  styleUrls: ['./product-services.component.scss']
})
export class ProductServicesComponent implements OnInit {
  @Input() productServices: Product[];
  
  constructor() { }

  ngOnInit() {
  }

}
