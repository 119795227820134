<action-header (onViewEvent)="onView()" (onNewEvent)="onNew()" (onEditEvent)="onEdit()" (onDeleteEvent)="onDelete()"
    [isSelected]="isSelected"></action-header>

<mat-card class="card admin">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef> {{ 'Id'| translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> {{ 'Name'| translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
        </ng-container>

        <ng-container matColumnDef="ProductType">
            <th mat-header-cell *matHeaderCellDef> {{ 'ProductType'| translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.ProductType?.Name}} </td>
        </ng-container>

        
        <ng-container matColumnDef="Price">
            <th mat-header-cell *matHeaderCellDef> {{ 'Price'| translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.Price}} </td>
        </ng-container>

        <ng-container matColumnDef="IsActive">
            <th mat-header-cell *matHeaderCellDef> {{ 'IsActive'| translate }} </th>
            <td mat-cell *matCellDef="let element">  <input type="checkbox" checked *ngIf="element.IsActive" disabled /> <input type="checkbox" *ngIf="!element.IsActive" disabled /> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'selected': selectedRowIndex === row.Id}" (click)="selection.select(row)">
        </tr>
    </table>
</mat-card>