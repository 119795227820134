import { Component, Input, OnInit } from '@angular/core';
import { Faq } from 'src/app/contracts/faq';

@Component({
  selector: 'faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss']
})
export class FaqItemComponent implements OnInit {
  @Input() faq: Faq;

  public isClosed: boolean;
  constructor() { 
    this.isClosed = true;
  }

  ngOnInit() {
  }

  public change(){
    this.isClosed = !this.isClosed;
  }

}
