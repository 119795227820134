import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICategory } from 'src/app/contracts/interfaces/iCategory';
import { IProduct } from 'src/app/contracts/interfaces/iProduct';
import { ProductService } from 'src/app/dataServices/productService';

var self;

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  providers: [ProductService]
})
export class ProductListComponent implements OnInit, OnDestroy {
  public dataSource: IProduct[];
  public displayedColumns = ['Id', 'Name', 'ProductType', 'Price', 'IsActive'];

  private selectedRowIndex: number = null;
  private selectedItem: any = null;
  private selection: SelectionModel<any>;
  private gridChangeSubscription: any;
  public isSelected: boolean = false;

  constructor(private productService: ProductService, private router: Router, private translateService: TranslateService) {
    self = this;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(false);

    this.filter();

    this.gridChangeSubscription = this.selection.changed.subscribe(this.change);
  }

  private change(e: any) {
    self.selectedRowIndex = e.added[0]['Id'];
    self.selectedItem = e.added[0];
    self.isSelected = true;
  }

  private filter() {
    this.productService.filter([]).then((r: IProduct[]) => {
      this.dataSource = r;
    })
  }

  public onView() {
    this.router.navigateByUrl("adminsite/productedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/productlist', mode: 'ReadOnly' } } });
  }

  public onNew() {
    this.productService.create().then(r => {
      this.router.navigateByUrl("adminsite/productedit", { state: { data: { id: r, routeBack: 'adminsite/productlist', mode: 'New' } } });
    });
  }

  public onEdit() {
    this.router.navigateByUrl("adminsite/productedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/productlist', mode: 'Modify' } } });
  }

  public onDelete() {
    if (confirm(this.translateService.instant("Confirm"))) {
      this.productService.delete(this.selectedItem.Id).then(r => {
        this.filter();
        this.isSelected = false;
        this.selectedItem = null;
      });
    }
  }

  ngOnDestroy() : void {
    this.gridChangeSubscription.unsubscribe();
  }
}
