import { Image } from "./image";

export class Content{
    public Id: number;
    public Code: string;
    public Title: string;
    public Description: string;
    public Text: string;
    public Cta: string;
    public Thumbnail: Image;
    public IsTitle: boolean;
    public IsDescription: boolean;
    public IsText: boolean;
    public IsThumbnail: boolean;
    public IsCta: boolean;
}