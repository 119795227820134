import { Coupon } from "./coupon";
import { Product } from "./product";

export class CouponProduct {
    public Id: number;
    public Coupon: Coupon;
    public Product: Product;
    public Value: number;
    public IsNew: boolean;
    public IsDeleted: boolean;
    public IsModified: boolean;
    
    constructor() {
    }
}