import { Component, Input, OnInit } from '@angular/core';
import { Image } from '../../contracts/image';

@Component({
  selector: 'mobile-slideshow',
  templateUrl: './mobile-slideshow.component.html',
  styleUrls: ['./mobile-slideshow.component.scss']
})
export class MobileSlideshowComponent implements OnInit {
  @Input() mediaContent: Image[];
  
  constructor() { }

  ngOnInit() {
  }

}
