<div class="main admin">
    <mat-card class="card">
        <mat-card-header class="header">
            <img src="/assets/logo.png" height="30px" alt="Vivian" />
            <span class="header-text">
                <h1>{{ 'ContentManagement' | translate }}</h1>
            </span>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field class="input-full-width">
                <mat-label>{{ 'UserName' | translate }}</mat-label>
                <input matInput placeholder="{{ 'UserName' | translate }}" [(ngModel)]="email">
            </mat-form-field>
            <mat-form-field class="input-full-width">
                <mat-label>{{ 'Password' | translate }}</mat-label>
                <input matInput type="password" placeholder="{{ 'Password' | translate }}"  [(ngModel)]="password">
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="login()">{{ 'Login' | translate }}</button>
        </mat-card-actions>
    </mat-card>
</div>