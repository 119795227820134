import { HttpService } from "../common/httpService";
import { QueryStringParameter } from "../contracts/queryStringParameter";
import { Base } from "./base";

export class ImageService extends Base {
    constructor(public httpService: HttpService) {
        super(httpService, "ImageController");
    }

    public upload(formData: FormData) {
        return this.httpService.post(this.controller, "Upload", formData, [], false);
    }

    public getByEntiTypeAndId(entityType: string, id: number) {
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter("entityType", entityType));
        parameters.push(new QueryStringParameter("id", id));

        return this.httpService.get(this.controller, "GetByEntityTypeAndId", parameters);
    }

    public getByFilter(filter:string) {
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter("filter", filter));
        
        return this.httpService.get(this.controller, "GetByFilter", parameters);
    }

    public deleteFile(entityType: string, id: number) {
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter("entityType", entityType));
        parameters.push(new QueryStringParameter("id", id));

        return this.httpService.post(this.controller, "DeleteFile", null, parameters);
    }
}