<div class="pairing-wrapper">
    <div class="separator">
        <div class="line"></div>
        <h1>Mivel párosítsam</h1>
        <div class="line"></div>
    </div>
    <div class="item-wrapper">
        <div *ngFor="let product of pairings">
            <favourite-item [isPairing]="true" *ngIf="product && product.Thumbnail" [name]="product.Name"
                [id]="product.Id" [src]="product.Thumbnail.FileName"></favourite-item>
        </div>
    </div>
</div>