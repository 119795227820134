//import { Md5 } from '../common/md5';
import {Md5} from 'ts-md5/dist/md5';

export class Login {
    Email: string;
    Password: string;

    constructor(email: string, password: string/*, private md5: Md5*/){
        let md5 = new Md5();
        md5.appendStr(password);

        this.Email = email;
        this.Password = md5.end().toString();
    }
}