import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IInstaPost } from 'src/app/contracts/interfaces/iInstaPost';
import { InstaPostService } from 'src/app/dataServices/instaPostService';

var self;

@Component({
  selector: 'app-instapost-list',
  templateUrl: './instapost-list.component.html',
  styleUrls: ['./instapost-list.component.scss'],
  providers: [InstaPostService]
})
export class InstapostListComponent implements OnInit, OnDestroy {
  public dataSource: IInstaPost[];
  public displayedColumns = ['Id', 'Title', 'Url'];

  private selectedRowIndex: number = null;
  private selectedItem: any = null;
  private selection: SelectionModel<any>;
  private gridChangeSubscription: any;
  public isSelected: boolean = false;

  constructor(private instaPostService: InstaPostService, private router: Router) {
    self = this;
   }

  ngOnInit() {
    this.selection = new SelectionModel<any>(false);

    this.gridChangeSubscription = this.selection.changed.subscribe(this.change);

    this.listAll();
  }

  private change(e: any) {
    self.selectedRowIndex = e.added[0]['Id'];
    self.selectedItem = e.added[0];
    self.isSelected = true;
  }

  private listAll(){
    this.instaPostService.getAll().then(
      (r: any)=>{
        this.dataSource = r.Result;
      }
    )
  }

  public onView() {
    this.router.navigateByUrl("adminsite/instapostedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/instapostlist', mode: 'ReadOnly' } } });
  }

  public onNew() {
    this.router.navigateByUrl("adminsite/instapostedit", { state: { data: { routeBack: 'adminsite/instapostlist', mode: 'New' } } });
  }

  public onEdit() {
    this.router.navigateByUrl("adminsite/instapostedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/instapostlist', mode: 'Modify' } } });
  }

  public onDelete() {
    if (confirm('Biztos benne?')) {
      this.instaPostService.delete(this.selectedItem.Id).then(r => {
        this.listAll();
        this.isSelected = false;
        this.selectedItem = null;
      });
    }
  }

  ngOnDestroy() : void {
    this.gridChangeSubscription.unsubscribe();
  }
}
