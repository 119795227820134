<div class="page-image desktop">
    <img src="../../../assets/blog.svg" class="image">
    <div class="centered">Blog</div>
</div>
<div class="page-image mobile">
    <img src="../../../assets/blog_m.png" class="image">
    <div class="centered">Blog</div>
</div>

<div *ngIf="blog" class="category">
    {{ getCategoryById(blog.CategoryId) }}
</div>

<p [innerHTML]="blog?.Text | keepHtml" class="content"></p>