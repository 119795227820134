import { Component, ContentChild, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contact } from 'src/app/contracts/contact';
import { ContactService } from 'src/app/dataServices/contactService';
import { CaptchaComponent } from 'src/app/directives/captcha/captcha.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ContactService]
})
export class ContactComponent implements OnInit {
  public isResolved: boolean = false;
  public acceptGdpr: boolean = false;
  public acceptTermsAndConditions: boolean = false;
  public contact: Contact;

  private onResolvedSubscription: any;
  private onErrorSubscription: any;
  private captchaDialog: any;

  @ViewChild('captcha', { static: false }) captchaElem: CaptchaComponent;

  constructor(private contactService: ContactService, private dialog: MatDialog) {
    this.contact = new Contact();
  }


  ngOnInit() {
  }


  public resolved(e: any) {
    this.isResolved = true;
  }

  public onError(e: any) {
    this.isResolved = false;
  }

  public send() {
    let validationResult = this.validate();

    if (validationResult.isValid) {
      this.contactService.sendMail(this.contact).then((r: any) => {

        if (r.ResultType == 0) {
          this.contact = new Contact();
          alert("Üzenet sikeresen elküldve");
          this.acceptGdpr = false;
          this.acceptTermsAndConditions = false;
          this.isResolved = false;
          this.captchaElem.reset();

        }
        else if (r.ResultType == 3) {
          let errors = "";
          r.ValidationResult.forEach(element => {
            errors += element.Key + ': ' + element.Value + '\r\n';
          });

          alert(errors);
        }
        else {
          alert('Sikertelen küldés');
        }
      })
        .catch((r: any) => {

        });

    }
    else {
      alert(validationResult.message);
    }

  }

  private validate(): any {
    let result = { isValid: true, message: '' };
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.contact || !this.contact.Name || this.contact.Name == '') {
      result.message += "Név kitöltése kötelező \r\n";
      result.isValid = false;
    }

    if (!this.contact || !this.contact.Email || this.contact.Email == '') {
      result.message += "E-mail kitöltése kötelező \r\n";
      result.isValid = false;
    }

    if (!this.contact || !this.contact.Message || this.contact.Message == '') {
      result.message += "Üzenet kitöltése kötelező \r\n";
      result.isValid = false;
    }

    let isValidMail = emailRegExp.test(this.contact.Email);

    if (!isValidMail) {
      result.message += "Nem megfelelő e-mail cím formátum";
      result.isValid = false;
    }

    return result;
  }

  public openCaptcha() {
    if (this.acceptGdpr && this.acceptTermsAndConditions) {

      this.captchaDialog = this.dialog.open(CaptchaComponent, {
        width: '310px',
        height: '126px',
      });

      this.onResolvedSubscription = this.captchaDialog.componentInstance.onResolved.subscribe(() => {
        this.send();
        this.unSubscripbeCapthca();
      });

      this.onErrorSubscription = this.captchaDialog.componentInstance.onError.subscribe(() => {
        this.unSubscripbeCapthca();
      });
    }
    else{
      alert('Fogadja el az Adatvédelmi tájékoztatót és az Általános szerződési feltételeket!')
    }
  }

  private unSubscripbeCapthca() {
    this.onResolvedSubscription.unsubscribe();
    this.onErrorSubscription.unsubscribe();

    this.captchaDialog.close();
    this.captchaDialog = null;
  }
}
