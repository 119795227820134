import { Injectable } from "@angular/core";
import { HttpService } from "../common/httpService";
import { Filter } from "../contracts/infrastructure/filter";
import { QueryStringParameter } from "../contracts/queryStringParameter";
import { Base } from "./base";

@Injectable()
export class CategoryService extends Base {
    constructor(httpService: HttpService) {
        super(httpService, "CategoryController");
    }

    public filterByName(name: string) {        
        let filters = new Array<Filter>();
        filters.push(new Filter("FilterByNameForAutoComplete", name));
        
        return super.filter(filters);
    }
}