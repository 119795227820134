import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(private meta: Meta, private title: Title) { 
    this.title.setTitle("Vivian");
    this.meta.addTags([
      {name: 'description', content: "Vivian esküvői ruhák" },
      {name: 'keywords', content: "vivian, esküvő, wedding, ruha, esküvői ruha, kiegészítők, esküvői kiegészítők"}
    ]);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
     
  }

}
