<div class="order-data" [ngStyle]="{'display': data && data.orderId ? 'contents' : 'block' }">
    <div class="page-title">Rendelésem (Azonosító: {{orderData?.Year}}/{{orderData?.IdYear}})</div>

    <div class="header">
        <div [ngClass]="{selected: id===1}" class="title" (click)="show(1)">Rendelési adatok</div>
        <div [ngClass]="{selected: id===2}" class="title" (click)="show(2)">Számlázási cím</div>
        <div *ngIf="orderData && orderData.DeliveryMethod && orderData.DeliveryMethod.IsAdditionalAddress" [ngClass]="{selected: id===3}" class="title" (click)="show(3)">Szállítási cím</div>
        <div *ngIf="orderData && orderData.DeliveryMethod && orderData.DeliveryMethod.Code == 'PostPoint'" [ngClass]="{selected: id===5}" class="title" (click)="show(5)">Postapont adatok</div>
        <div [ngClass]="{selected: id===4}" class="title" (click)="show(4)">Termékek</div>
    </div>

    <div class="data">
        <div *ngIf="id==1">
           
            <div class="row" *ngIf="orderData.Coupon">
                <div class="wrapper">
                    <span class="title">Kupon kód</span>
                    <span class="value">{{orderData?.Coupon?.Code}}</span>
                </div>

                <div class="wrapper">
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Azonosító</span>
                    <span class="value">{{orderData?.Year}}/{{orderData?.IdYear}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">Rendelési ideje</span>
                    <span class="value">{{orderData?.OrderDate | date:'yyyy.MM.dd HH:mm:ss'}}</span>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Név/Cég</span>
                    <span class="value">{{orderData?.Name}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">Adószám</span>
                    <span class="value">{{orderData?.TaxNumber}}</span>
                </div>
            </div>
            <div class="row">
                <div class="wrapper">
                    <span class="title">Telefon</span>
                    <span class="value">{{orderData?.Phone}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">E-mail</span>
                    <span class="value">{{orderData?.Email}}</span>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Megjegyzés</span>
                    <span class="value">{{orderData?.Comment}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">Szállítási mód</span>
                    <span class="value">{{orderData?.DeliveryMethod?.Name}} ({{orderData.DeliveryMethodPrice | number:'.0' | currency}} Forint)</span>
                </div>
            </div>

            <div class="row">                
                <div class="wrapper">
                    <span class="title">Fizetési mód</span>
                    <span class="value">{{ getPaymentMethod(orderData?.PaymentMethod) }} <span class="value" *ngIf="orderData?.PaymentMethod == 'Barion'">({{ getPaymentState(orderData?.PaymentStatus) }})</span></span> 
                    
                </div>

                <div class="wrapper">
                    <span class="title">Ár</span>
                    <span class="value">{{orderData?.Price | number:'.0' | currency}} Forint</span>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Állapot</span>
                    <span class="value">{{getState(orderData?.Status)}}</span>
                </div>
                
                <div class="wrapper"></div>
            </div>


            <div class="wrapper admin buttons"
                *ngIf="contextStore && contextStore.Context && contextStore.Context.Name">
                <div *ngIf="orderData != null && orderData.Status == 0" class="action-buttons">
                    <button *ngIf="orderData?.DeliveryMethod?.Code=='Personal'" class="spacing" mat-raised-button color="primary" (click)="setState(1)">Átvehető a
                        boltban</button>&nbsp;
                    <button *ngIf="orderData?.DeliveryMethod?.Code=='HomeDelivery' || orderData?.DeliveryMethod?.Code=='GlsHomeDelivery'" class="spacing" mat-raised-button color="primary" (click)="setState(2)">Kiszállítás
                        alatt</button>&nbsp;
                    <button  *ngIf="orderData?.DeliveryMethod?.Code=='PostPoint'" class="spacing" mat-raised-button color="primary" (click)="setState(3)">Átvehető az
                        általad
                        megadott ponton</button>&nbsp;
                </div>
                <div *ngIf="orderData != null && (orderData.Status == 1 || orderData.Status == 2 || orderData.Status == 3)"
                    class="action-buttons">
                    <button class="spacing" mat-raised-button color="primary" (click)="setState(4)">Lezárva</button>
                </div>
            </div>
        </div>

        <div *ngIf="id==2">
            <div class="row">
                <div class="wrapper">
                    <span class="title">Ország</span>
                    <span class="value">{{orderData?.BillingAddress?.Country}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">Megye</span>
                    <span class="value">{{orderData?.BillingAddress?.County}}</span>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Irányítószám</span>
                    <span class="value">{{orderData?.BillingAddress?.PostalCode}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">Város</span>
                    <span class="value">{{orderData?.BillingAddress?.City}}</span>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Lakcím</span>
                    <span class="value">{{orderData?.BillingAddress?.AddressLine1}}</span>
                    <span class="value">{{orderData?.BillingAddress?.AddressLine2}}</span>
                </div>

                <div class="wrapper">

                </div>

            </div>
        </div>

        <div *ngIf="id==3">
            <div class="row">
                <div class="wrapper">
                    <span class="title">Ország</span>
                    <span class="value">{{deliveryAddress?.Country}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">Megye</span>
                    <span class="value">{{deliveryAddress?.County}}</span>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Irányítószám</span>
                    <span class="value">{{deliveryAddress?.PostalCode}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">Város</span>
                    <span class="value">{{deliveryAddress?.City}}</span>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Lakcím</span>
                    <span class="value">{{deliveryAddress?.AddressLine1}}</span>
                    <span class="value">{{deliveryAddress?.AddressLine2}}</span>
                </div>

                <div class="wrapper">
                  
                </div>
            </div>
        </div>

        <div *ngIf="id==5">
            <div class="row">
                <div class="wrapper">
                    <span class="title">Postapont neve</span>
                    <span class="value">{{orderData.PostPointName}}</span>
                </div>

                <div class="wrapper">
                    <span class="title">Postapont címe</span>
                    <span class="value">{{orderData.PostPointAddress}}</span>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <span class="title">Postapont azonosítója</span>
                    <span class="value">{{orderData.PostPointId}}</span>
                </div>

                <div class="wrapper"></div>
            </div>
        </div>


        <div *ngIf="id==4">
            <table class="item-table">
                <thead>
                    <tr>
                        <th>Termék</th>
                        <th>Mennyiség</th>
                        <th>Egységár</th>
                        <th>Ár</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of orderData.OrderDataProducts" class="row">
                        <td>
                            <div class="product-wrapper">
                                <span class="product-name">{{item.Product.Name}}</span>
                                <span class="product-property">{{getProperties(item)}}</span>
                                <span class="product-service">{{getServices(item)}}</span>
                            </div>
                        </td>

                        <td class="center">
                            {{item.Quantity}}
                        </td>

                        <td class="center">
                            <span style="text-decoration: line-through;" *ngIf="item.IsDiscounted">{{item.OriginalProductPrice}}</span><br/>
                            <span>{{item.ProductPrice}}</span> Forint
                        </td>

                        <td class="center">
                            {{item.CalculatedPrice}} Forint
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>