<div class="wrapper">
    <div class="content-wrapper">
        <div class="alignment">
            <span> A tökéletes ruha az, amiben önmagad lehetsz. <br/> Érezd magad jól minden porcikádban a Nagy Napon egy
                VIVIAN ruhában! 
            </span>

            <button [disableRipple]="true" class="action-button" mat-flat-button>Tovább</button>
        </div>
    </div>
</div>