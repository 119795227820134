import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'blog-category-picker',
  templateUrl: './blog-category-picker.component.html',
  styleUrls: ['./blog-category-picker.component.scss']
})
export class BlogCategoryPickerComponent implements OnInit {
  @Input() selectedCategory: string;
  @Input() disabled: boolean;
  @Output() onChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  public categories: any[];
  
  constructor() { }

  ngOnInit() {

    this.categories = [
      { code: 1, viewValue: 'Vivian tippek' },
      { code: 2, viewValue: 'Minden, ami esküvő' },
      { code: 3, viewValue: 'Esküvői történetek' },
    ];
  }
}
