<div class="wrapper">
    <div class="text font-size">
        <div class="alignment">
            <span style="margin-bottom: 19px;display: block;" class="font-size">Könnyed elegancia modern Nőknek!</span>
            Add önmagad esküvőd napján egyedi menyasszonyi ruhában! <br/>
            A VIVIAN modellei egytől egyig kézzel és teljes
            szívvel készülnek, tőlünk Neked.
        
            <span style="margin-top: 50px;display: block;">#vivianbridalpower</span>
        </div>
    </div>
</div>