import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/contracts/product';

@Component({
  selector: 'pairings',
  templateUrl: './pairings.component.html',
  styleUrls: ['./pairings.component.scss']
})
export class PairingsComponent implements OnInit {
  @Input() pairings: Product[];

  constructor() { }

  ngOnInit() {
  }

}
