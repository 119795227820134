<action-header (onViewEvent)="onView()" (onNewEvent)="onNew()" (onEditEvent)="onEdit()" (onDeleteEvent)="onDelete()"
    [isSelected]="isSelected" [custom1ButtonVisible]="true" (onCustom1Event)="generatePassword()" custom1Icon="password"></action-header>

<mat-card class="card admin">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef> {{ 'Id'| translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Név </th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
        </ng-container>

        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef> E-mail </th>
            <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
        </ng-container>

        <ng-container matColumnDef="IsLocked">
            <th mat-header-cell *matHeaderCellDef> Zárolt </th>
            <td mat-cell *matCellDef="let element"> 
                <input *ngIf="element.IsLocked" type="checkbox" disabled="disabled" checked="checked">    
                <input *ngIf="!element.IsLocked" type="checkbox" disabled="disabled">    
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'selected': selectedRowIndex === row.Id}" (click)="selection.select(row)">
        </tr>
    </table>
</mat-card>