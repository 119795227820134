import { Injectable, Component, NgModule, Injector } from '@angular/core';
import { Routes, RouterModule, CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
//import { HttpService } from '.httpService';
//import { PermissionTypes } from '../enums/permissionTypes';
//import { Permission } from '../contracts/permission';
import { UserService } from '../dataServices/userService';

@Injectable()
export class LoginRestrict implements CanActivate {

    constructor(private router: Router, private userService: UserService) { }

    public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.userService.authenticate().then(res => {
                if (res) {
                    resolve(true);
                }
                else {
                    reject(this.router.navigate(['admin']));
                }
            })

                .catch((err: any) => {
                    reject(this.router.navigate(['admin']));
                });
        });
    }
}
