<div class="shop">
    <div class="shop-header">
        <div class="breadcrumb">
            <span>SHOP » {{shopServices.activeShopMenu}}</span>
        </div>
        <div class="order-picker">
            <align-picker (onOrderChange)="changeOrder($event)"></align-picker>
        </div>
    </div>

    <div class="container">
        <div *ngFor="let product of products">
            <shop-item [product]="product"></shop-item>
        </div>
    </div>
</div>