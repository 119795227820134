<div *ngIf="content" class="wrapper">
    <div class="image-wrapper">
        <img [src]="content.Thumbnail.FileName" />
    </div>
    <div class="content-wrapper">
        <h1>
            {{content.Title}}
        </h1>

        <div class="content" [innerHTML]="content.Description | keepHtml">
        </div>

        <div class="action-btn">
            <button [disableRipple]="true" mat-flat-button class="read-more" (click)="navigate()">{{btnText}}</button>
        </div>
    </div>
</div>