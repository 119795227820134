import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductPropertyValue } from 'src/app/contracts/product-property-value';

@Component({
  selector: 'property-picker-item',
  templateUrl: './property-picker-item.component.html',
  styleUrls: ['./property-picker-item.component.scss']
})
export class PropertyPickerItemComponent implements OnInit {
  @Input() value: ProductPropertyValue;
  @Input() selected: boolean = false;
  @Output() onSelected : EventEmitter<ProductPropertyValue> = new EventEmitter<ProductPropertyValue>();

  constructor() { }

  ngOnInit() {
  }

  public select(){
    this.onSelected.emit(this.value);
  }
}
