<div class="page-image desktop">
    <img src="../../../assets/contact.svg" class="image">
    <div class="centered">Kapcsolat</div>
</div>


<div class="page-image mobile">
    <img src="../../../assets/contact_m.png" class="image">
    <div class="centered">Kapcsolat</div>
</div>

<div class="wrapper">
    <div class="contact-form">
        <h1>
            Kérdésed van? Írj nekünk!
        </h1>

        <div class="input-wrapper">
            <input class="input" type="text" [(ngModel)]="contact.Name" placeholder="Név" />
            <input class="input" type="text" [(ngModel)]="contact.Email" placeholder="E-mail cím" />
            <textarea rows="10" class="input area" [(ngModel)]="contact.Message" placeholder="Üzenet"></textarea>

            <div class="privacy-statement">
                <mat-checkbox [disableRipple]="true" [(ngModel)]="acceptGdpr"></mat-checkbox>
                <span>Elfogadom az adatkezelési tájékoztatót</span>
            </div>

            <div class="privacy-statement">
                <mat-checkbox [disableRipple]="true" [(ngModel)]="acceptTermsAndConditions"></mat-checkbox>
                <span>Elfogadom az általános szerződési feltételeket</span>
            </div>

            <div class="action-buttons">
                <button mat-flat-button (click)="openCaptcha()">Küldés</button>
            </div>
        </div>

    </div>

    <div class="contact-info">
        <h1>
            Elérhetőség
        </h1>
     
            <h2>
                <span>  
                    Ügyfélszolgálat
                </span>
            </h2>

            <span>
                Hétfő-péntek 9:00-18:00<br />
                hello@vivian.hu<br />
                +36 70 423 2406<br />
            </span>

            <h2 class="title">
                <span>
                    Tervező, tulajdonos
                </span>
            </h2>

            <span>
                dr. Buday-Kele Boglárka
            </span>

            <h2 class="title">
                <span>
                    Social media
                </span>
            </h2>

            <div class="social-mdeia">
                <a href="https://www.facebook.com/vivian.weddingshop"><img class="img"
                        src="/assets/contact_facebook.svg" /></a>
                <a href="https://instagram.com/vivian.weddingshop?igshid=mu7da7v753rf"><img class="img"
                        src="/assets/contact_instagram.svg" /></a>
                <a href="https://vm.tiktok.com/ZMeCD22sG/"><img class="img" src="/assets/contact_tiktok.svg" /></a>
            </div>
        
    </div>
</div>