import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextStore } from 'src/app/common/contextStore';
import { UserService } from 'src/app/dataServices/userService';
import { TokenService } from 'src/app/services/tokenService';

@Component({
  selector: 'current-user',
  templateUrl: './current-user.component.html',
  styleUrls: ['./current-user.component.scss'],
  providers: [UserService]
})
export class CurrentUserComponent implements OnInit {

  constructor(public contextStore: ContextStore, private userService: UserService, private tokenService: TokenService,private router: Router) { }

  ngOnInit() {

  }

  public logout(){
    this.userService.logout().then(res => {
      this.tokenService.clearToken();
      this.contextStore.Context = null;
      this.router.navigate(['admin']);
    },
      msg => {
        //this.processing = false;
      });
  }

  public changePassword(){
    this.router.navigate(['adminsite/changepassword']); 
  }

}
