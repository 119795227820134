import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from 'src/app/contracts/category';
import { CategoryService } from 'src/app/dataServices/categoryService';

@Component({
  selector: 'category-picker',
  templateUrl: './category-picker.component.html',
  styleUrls: ['./category-picker.component.scss'],
  providers: [CategoryService]
})
export class CategoryPickerComponent implements OnInit {
  @Input() selectedCategory: Category;
  @Input() disabled: boolean;
  @Output() onChangeEvent: EventEmitter<Category> = new EventEmitter<Category>();
  
  public dataSource: Category[];
  
  constructor(private categoryService: CategoryService) {
  }

  ngOnInit() {
    this.onFilter("");
  }

  public onFilter(filter: string) {
    //event.target.value
    this.categoryService.filterByName(filter).then((r: Category[]) => {
      this.dataSource = r;
    });
  }

  public onChange(category: any){
    this.selectedCategory = category.option.value;
    this.onChangeEvent.emit(this.selectedCategory);
  }
}
