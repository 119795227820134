import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-pairings',
  templateUrl: './cta-pairings.component.html',
  styleUrls: ['./cta-pairings.component.scss']
})
export class CtaPairingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
