<div class="image-viewer">
    <div class="image">
        <img [src]="item?.Thumbnail?.FileName" width="420" (click)="showSlideShow(item.Thumbnail)" style="cursor:pointer" />
    </div>
    <div class="thumbnail-container">
        <div *ngFor="let media of mediaContent" class="thumbnail" (click)='showSlideShow(media)'>
            <img [src]="media.FileName" width="140" *ngIf="!media.IsMovie" />

            <video width="140" *ngIf="media.IsMovie" controls>
                <source [src]="media.FileName" type="video/mp4">
            </video>
        </div>
    </div>
</div>