<div class="wrapper">
    <div class="content-wrapper">
        <div class="alignment">

            <span>Talán még nem is tudod pontosan, milyen ruhára vágysz, de egy biztos: ahogy meglátod, megismered. <br/>
                 Nézd
                meg kollekciónk darabjait, hisszük, hogy éppen közöttük lapul a Te ruhád is!
            </span>

            <button [disableRipple]="true" (click)="navigate()" class="action-button" mat-flat-button>Tovább</button>
        </div>
    </div>
</div>