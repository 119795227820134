import { Component, OnInit } from '@angular/core';
import { Content } from 'src/app/contracts/content';
import { ContentService } from 'src/app/dataServices/contentService';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  providers: [ContentService]
})
export class AboutUsComponent implements OnInit {
  public leftImageContent: Content;
  public rightImageContent: Content;
  public firstParagraph: Content;
  public secondParagraph: Content;

  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.getByGuid("AB616D6E-833E-42E6-A0F2-EE148E1886CE", "LeftImage");
    this.getByGuid("1287A65A-EF3C-45A3-A859-9C9277FAC645", "RightImage");
    this.getByGuid("73999598-1567-45EE-9C4D-F15352ECC202", "FirstParagraph");
    this.getByGuid("06F66F0A-6B58-4BE7-B085-9A0BDCC0FA55", "SecondParagraph");
  }

  private getByGuid(code:string, property: string){
    this.contentService.getByCode(code).then((r:any)=>{
      
      switch(property){
        case "LeftImage":
          this.leftImageContent = r;
          break;

        case "RightImage":
          this.rightImageContent = r;
          break;

        case "FirstParagraph":
          this.firstParagraph = r;
          break;

        case "SecondParagraph":
          this.secondParagraph = r;
          break;
      }
    });
  }

}
