import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Coupon } from 'src/app/contracts/coupon';
import { CouponProduct } from 'src/app/contracts/couponProduct';
import { Product } from 'src/app/contracts/product';
import { CouponService } from 'src/app/dataServices/couponService';

@Component({
  selector: 'app-coupon-edit',
  templateUrl: './coupon-edit.component.html',
  styleUrls: ['./coupon-edit.component.scss'],
  providers: [CouponService]
})
export class CouponEditComponent implements OnInit {
  public coupon: Coupon;
  private pageData: any;
  public readOnly: boolean;

  public CouponTypes = [
    { Id: 1, Name: 'Kedvezmény a végösszegből' },
    { Id: 2, Name: 'Kedvezmény a kiválasztott termékekből' },
    { Id: 3, Name: 'Kedvezmény bizonyos termékszám után (legolcsóbb)' },
    { Id: 4, Name: 'Kedvezmény bizonyos termékszám után (legdrágább)' }
  ];

  constructor(private couponService: CouponService, private router: Router) {
    this.coupon = new Coupon();
    this.coupon.Products = [];
    this.coupon.IsNew = true;
    this.coupon.Type = 1;

    this.pageData = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.data : null;
    this.readOnly = this.pageData.mode == "ReadOnly";
  }

  ngOnInit() {
    this.getById();
  }

  private getById() {
    this.couponService.getById(this.pageData.id).then((r: any) => {
      this.coupon = r.Result;
      if (!this.coupon.IsActive){
        this.readOnly = true;
      }
    });
  }

  public save() {
    this.couponService.save(this.coupon).then(r=>{
      this.cancel();
    });
  }

  public cancel() {
    this.router.navigateByUrl(this.pageData.routeBack);
  }

  public handleProduct(product: Product, idx: number) {
    let isNew = this.coupon.Products[idx].IsNew;

    this.coupon.Products[idx].Product = product;
    this.coupon.Products[idx].IsNew = isNew;

    console.log( this.coupon);
  }

  public addProduct() {
    let p = new CouponProduct()
    p.IsNew = true;
    this.coupon.Products.push(p);
  }

  public deleteProduct(product: CouponProduct) {
    if (product.IsNew) {
      this.coupon.Products = this.coupon.Products.filter(obj => obj !== product);
    }
    else {
      product.IsDeleted = true;
    }
  }
}
