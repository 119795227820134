import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ContextStore } from 'src/app/common/contextStore';
import { Address } from 'src/app/contracts/address';
import { OrderData } from 'src/app/contracts/orderData';
import { OrderDataProduct } from 'src/app/contracts/orderDataProduct';
import { OrderDataProductPropertyValue } from 'src/app/contracts/orderDataProductPropertyValue';
import { OrderDataProductService } from 'src/app/contracts/orderDataProductService';
import { OrderService } from 'src/app/dataServices/orderService';

@Component({
  selector: 'order-data-display',
  templateUrl: './order-data-display.component.html',
  styleUrls: ['./order-data-display.component.scss'],
  providers: [OrderService]

})
export class OrderDataDisplayComponent implements OnInit {
  @Input() orderId: string;

  public orderData: OrderData;
  public deliveryAddress: Address;
  private orderGuid;
  public id: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService, public contextStore: ContextStore) {
    this.orderData = new OrderData();
    this.id = 1;
  }

  ngOnInit() {
    if (this.orderId) {
      this.orderGuid = this.orderId;
    }
    else {
      this.orderGuid = this.data.orderId;
    }

    this.getData();
  }

  private getData() {
    this.orderService.getByOrderId(this.orderGuid).then((r: any) => {
      this.orderData = r.Result;

      if (this.orderData.OtherAddress) {
        this.deliveryAddress = this.orderData.DeliveryAddress;
      }
      else {
        this.deliveryAddress = this.orderData.BillingAddress;
      }
    });
  }

  public getProperties(orderDataProduct: OrderDataProduct) {
    let properties = '';
    orderDataProduct.OrderDataProductPropertyValues.forEach((ppv: OrderDataProductPropertyValue) => {
      properties += ppv.ProductPropertyValue.ProductProperty.Name + ': ' + ppv.ProductPropertyValue.Value + ", "
    });

    return properties;
  }

  public getServices(orderDataProduct: OrderDataProduct) {
    let services = '';
    orderDataProduct.OrderDataProductServices.forEach((srv: OrderDataProductService) => {
      services += srv.Product.Name + ", "
    });

    return services;
  }

  public getState(state: number) {
    let text;

    switch (state) {
      case 0:
        text = 'Feldolgozás alatt';
        break;

      case 1:
        text = 'Átvehető a boltban';
        break;

      case 2:
        text = 'Kiszállítás alatt';
        break;

      case 3:
        text = 'Átvehető a kiválasztott ponton';
        break;

      case 4:
        text = 'Lezárva';
    }

    return text;
  }

  public setState(state: number) {
    this.orderService.setState(this.data.orderId, state).then((r: any) => {
      alert('Státusz beállítása sikeres');
      this.orderData.Status = state;
    }).catch((r: any) => {
      alert('Státusz beállítása sikertelen');
    });
  }

  public getPaymentMethod(code: string) {
    let result = "";

    if (code) {
      switch (code.toLowerCase()) {
        case "transfer":
          result = "Fizetés előre utalással";
          break;

        case "cashondelivery":
          result = "Fizetés utánvéttel";
          break;

        case "barion":
          result = "Fizetés Barionnal";
          break;
      }
    }

    return result;
  }

  public show(id: number){
    this.id = id;
  }

  public getPaymentState(state:string){
    let val;

    switch (state){
      case "Prepared":
        val = "Előkészítve";
        break;
      case "Succeeded":
        val = "Sikeres";
        break;
      default:
        val = "Sikertelen";
    }

    return val;
  }
}
