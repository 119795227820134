import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog } from 'src/app/contracts/blog';
import { BlogService } from 'src/app/dataServices/blogService';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss'],
  providers: [BlogService]
})
export class BlogListComponent implements OnInit {
  public blogs: Blog[];
  private queryParams: any;

  constructor(private blogService: BlogService, private router: Router, private route: ActivatedRoute) {
    this.queryParams = this.router.routerState.snapshot.root.queryParams
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.blogService.getAllActiveById(queryParams.code).then((r: Blog[]) => {
        this.blogs = r;
      });
    });

  }

}
