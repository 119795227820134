import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

import { BillModel } from 'src/app/contracts/billl-model';
import { OrderService } from 'src/app/dataServices/orderService';

@Component({
  selector: 'app-billing-form',
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.scss'],
  providers: [OrderService]
})
export class BillingFormComponent implements OnInit {
  @Output() onBillCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  public model: BillModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService) { 
    this.model = new BillModel();
  }

  ngOnInit() {
  }

  public createBill(){
    this.model.Id = this.data.orderId;
    
    this.orderService.createBill(this.model).then(r=>{
      alert("Számla kiállítása sikeres");
      this.onBillCreated.next(true);
    }).catch(r=>{
      alert("Számla kiállítása sikertelen");
      this.onBillCreated.next(true);
    })
  }
}
