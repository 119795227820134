import { Injectable } from '@angular/core';
import { User } from '../contracts/user';

import { Login } from '../contracts/login';
import { Password } from '../contracts/password';
import { HttpService } from '../common/httpService';
import { Base } from './base';
import { QueryStringParameter } from '../contracts/queryStringParameter';

export class UserService extends Base {
    constructor(httpService: HttpService) {
        super(httpService, "UserController");
    }

    public register(user: User) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post("UserController", "Register", user).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public activate(token: string) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post("UserController", "Activate?token=" + token, null).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public login(loginModel: Login) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post("UserController", "Login", loginModel).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public logout() {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post("UserController", "Logout", null).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public authenticate() {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post('UserController', 'Authenticate', null).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public getContext() {
        let promise = new Promise((resolve, reject) => {
            this.httpService.get('UserController', 'GetContext').then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public getUsers() {
        let promise = new Promise((resolve, reject) => {
            this.httpService.get(this.controller, 'GetAll').then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public generatePassword(id){
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter("id", id));

        return this.httpService.post(this.controller, "GeneratePassword", null, parameters);
    }
    
    public changePassword(model: Password, id) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post("UserController", "ChangePassword", model, null, true, id).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public changePasswordByToken(model: Password) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post("UserController", "ChangePasswordByToken", model).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public getCurrentUser() {
        let promise = new Promise((resolve, reject) => {
            this.httpService.get("UserController", "GetCurrent").then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public updateProfile(user: User, id) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post("UserController", "UpdateProfile", user, null, true, id).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public sendPasswordChangeLink(email: string) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post("UserController", "SendForgottenPasswordEmail", JSON.stringify(email)).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }

    public deleteProfile(id) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.post('UserController', 'DeleteProfile', null, null, true, id).then(res => {
                resolve(res);
            },
                msg => {
                    reject(msg);
                });
        });

        return promise;
    }
}