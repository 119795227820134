import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Filter } from 'src/app/contracts/infrastructure/filter';
import { IOrderData } from 'src/app/contracts/interfaces/iOrderData';
import { OrderData } from 'src/app/contracts/orderData';
import { OrderService } from 'src/app/dataServices/orderService';
import { BillingFormComponent } from 'src/app/directives/billing-form/billing-form.component';
import { OrderDataDisplayComponent } from 'src/app/directives/order-data-display/order-data-display.component';

var self;

@Component({
  selector: 'order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  providers: [OrderService]
})

export class OrderListComponent implements OnInit {

  public dataSource: IOrderData[];
  public displayedColumns = ['DeleteButton', 'Identifier', 'OrderDate', 'Name', 'TaxNumber', 'Phone', 'Email', 'PaymentMethod'];

  private selectedRowIndex: number = null;
  private selectedItem: any = null;
  private selection: SelectionModel<any>;
  private gridChangeSubscription: any;
  private rowClickSubscription: any;
  private typingTimer: any;

  public isSelected: boolean = false;

  public orderNumber: string;
  public name: string;
  public taxNumber: string;
  public phone: string;
  public email: string;
  public statusFilter: boolean[];
  public isNewFilter: boolean;

  constructor(private orderDataService: OrderService, private router: Router, private translateService: TranslateService, public dialog: MatDialog) {
    self = this;
    this.statusFilter = [true, true, true, true, false];
    this.isNewFilter = true;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(false);

    this.getAll();

    this.gridChangeSubscription = this.selection.changed.subscribe(this.change);
  }

  public getAll() {
    let filters = new Array<Filter>();

    if (this.orderNumber) {
      filters.push(new Filter("OrderNumber", this.orderNumber));
    }

    if (this.name) {
      filters.push(new Filter("Name", this.name));
    }

    if (this.taxNumber) {
      filters.push(new Filter("TaxNumber", this.taxNumber));
    }

    if (this.phone) {
      filters.push(new Filter("Phone", this.phone));
    }

    if (this.email) {
      filters.push(new Filter("Email", this.email));
    }

    filters.push(new Filter("IsNew", this.isNewFilter));

    filters.push(new Filter("State", this.statusFilter[0] + ',' + this.statusFilter[1] + ',' + this.statusFilter[2] + ',' + this.statusFilter[3] + ',' + this.statusFilter[4]));

    this.orderDataService.filter(filters).then((r: IOrderData[]) => {
      this.dataSource = r;
    });
  }

  private filterByName(name: string) {
    /*this.categoryService.filterByName(name).then((r:IOrderData[]) => {
      this.dataSource = r;
    });*/
  }

  public changeStatusFilter(idx: number, evt: any) {
    this.statusFilter[idx] = evt.checked;
    this.getAll();
  }

  public changeIsNewFilter(evt:any){
    this.getAll();
  }

  private change(e: any) {
    self.selectedRowIndex = e.added[0]['Id'];
    self.selectedItem = e.added[0];
    self.isSelected = true;
  }

  public onView() {
    ///this.router.navigateByUrl("adminsite/categoryedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/categorylist', mode: 'ReadOnly' } } });
  }

  public onNew() {
    //this.router.navigateByUrl("adminsite/categoryedit", { state: { data: { routeBack: 'adminsite/categorylist', mode: 'New' } } });
  }

  public onEdit() {
    //this.router.navigateByUrl("adminsite/categoryedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/categorylist', mode: 'Modify' } } });
  }

  public onDelete() {
    /*if (confirm(this.translateService.instant("Confirm"))) {
      this.categoryService.delete(this.selectedItem.Id).then(r => {
        this.getAll();
        this.isSelected = false;
        this.selectedItem = null;
      });
    }*/
  }

  public deleteOrder(orderData: OrderData) {
    if (confirm("Biztos benne?")) {
      this.orderDataService.deleteByOrderId(orderData.OrderId).then(r => {
        this.getAll();
      }).catch(r => {
        alert('Sikertelen törlés');
      });
    };
  }

  public show(oData: OrderData) {
    this.orderDataService.setSeen(oData.OrderId).then((r: any) => {
      self.selectedItem.IsNew = false;

      let orderDataDisplay = self.dialog.open(OrderDataDisplayComponent, {
        height: '600px',
        width: '800px',
        data: { orderId: oData.OrderId }
      });

    }).catch(err => {

    })
  }

  public getPaymentMethod(code: string) {
    let result = "";

    if (code) {
      switch (code.toLowerCase()) {
        case "transfer":
          result = "Fizetés előre utalással";
          break;

        case "cashondelivery":
          result = "Fizetés utánvéttel";
          break;

        case "barion":
          result = "Fizetés Barionnal";
          break;
      }
    }

    return result;
  }

  public createBill(id) {
    let billingForm = this.dialog.open(BillingFormComponent, {
      data: { orderId: id },
      height: '200px',
      width: '800px',
    });

    billingForm.componentInstance.onBillCreated.subscribe(r => {
      billingForm.close();
      this.getAll();
    });
  }

  public getInvoice(id) {
    this.orderDataService.getPdf(id).then((r: any) => {

      const source = 'data:application/pdf;base64,' + r.Result;
      const link = document.createElement("a");
      link.href = source;
      link.download = 'invoice.pdf';
      link.click();


    }).catch(r => {
      alert("Nem sikerült a számlát megnyitni.")
    });
  }

  keydown() {
    clearTimeout(this.typingTimer);
  }

  keyup() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.getAll();
    }, 1000, this);
  }

  ngOnDestroy(): void {
    this.gridChangeSubscription.unsubscribe();
  }

}
