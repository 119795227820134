import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/contracts/product';
import { ProductService } from 'src/app/dataServices/productService';
import { ShopServices } from 'src/app/services/shop-service';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [ProductService]
})
export class ProductComponent implements OnInit, OnDestroy {
  public product: Product;
  public showCart: boolean;

  constructor(private productService: ProductService, private router: Router, private route: ActivatedRoute, public shopServices: ShopServices, private meta: Meta, private title: Title) {
    this.showCart = false;

    this.route.queryParams.subscribe(queryParams => {
      this.productService.getById(queryParams.id).then((p: Product) => {
        this.product = p;
        this.title.setTitle("Vivian - " + p.Name)

        let keywords = p.Name + ', ' + p.Name.split(' ').join(', ');

        this.meta.addTags([
          {name: 'description', content: p.Description},
          {name: 'keywords', content: keywords}
        ]);

        //this.meta.updateTag( { name:'description', content: p.Description},"name='description'");
        //this.meta.updateTag( { name:'keywords', content: keywords},"name='keywords'");

      });
    });
  }

  ngOnInit() {
    
  }

  public closeCartMessage() {
    this.showCart = false;
  }

  public navigateToCart() {
    this.router.navigate(['pages/cart']);
  }

  public onProductAdded() {
    this.showCart = true;
    scrollTo(0,0);
  }

  ngOnDestroy(): void {
}

}
