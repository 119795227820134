import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
  @Input() title: string;
  @Input() multiline: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() ngModel;
  @Input() isReadOnly: boolean = false;
  @Output() ngModelChange:any = new EventEmitter<any>();
  
  constructor() { 
    
  }

  ngOnInit() {
    this.ngModelChange.emit(this.ngModel);
  }

}
