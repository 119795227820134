import { Route } from '@angular/compiler/src/core';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'src/app/common/dateTime';
import { Blog } from 'src/app/contracts/blog';
import { Image } from 'src/app/contracts/image';
import { BlogService } from 'src/app/dataServices/blogService';
import { ImagePickerComponent } from 'src/app/directives/image-picker/image-picker.component';
import { MediaUploaderComponent } from 'src/app/directives/media-uploader/media-uploader.component';

@Component({
  selector: 'app-blog-edit',
  templateUrl: './blog-edit.component.html',
  styleUrls: ['./blog-edit.component.scss'],
  providers: [BlogService]
})
export class BlogEditComponent implements OnInit, AfterViewInit {
  private pageData: any;
  public blog: Blog;
  public readOnly: boolean;
  public imageList = [];
  public isEditorVisible = true;

  public createdString:string;
  public modifiedString:string;

  private mediaUploader: MediaUploaderComponent;
  private imagePickerComponent: ImagePickerComponent;

  @ViewChild('imagePicker', { static: false }) set imagePicker(component: ImagePickerComponent) {
    if (component) {
      this.imagePickerComponent = component;
    }
  }

  @ViewChild('mediaUploader', { static: false }) set file(component: MediaUploaderComponent) {
    if (component) {
      this.mediaUploader = component;
    }
  }

  constructor(private router: Router, private blogService: BlogService, public dateTimeService: DateTime) {
    this.pageData = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.data : null;
    this.readOnly = this.pageData.mode == "ReadOnly";
  }

  ngOnInit() {
    this.getById();
  }

  ngAfterViewInit() {
    this.isEditorVisible = false;
    this.mediaUploader.load("Blog", this.pageData.id);
  }

  private getById() {
    this.blogService.getById(this.pageData.id).then((r: Blog) => {      
      this.blog = r;
     this.createdString = this.dateTimeService.convertFromUtc( r.Created);
      this.modifiedString = this.dateTimeService.convertFromUtc( r.Modified);
    });
  }

  public onImagesChanging(){
    this.isEditorVisible = false;
  }

  public imagesLoaded(images: Image[]) {
    let list = [];
    images.forEach(e => {
      list.push({ title: e.Name, value: e.FileName });
    });

    this.imageList = list;
    this.isEditorVisible = true;
    this.imagePickerComponent.getImages();
  }

  public save() {
    this.blogService.update(this.blog).then(r => {
      this.router.navigateByUrl(this.pageData.routeBack);
    });
  }

  public cancel(){
    this.router.navigateByUrl(this.pageData.routeBack);
  }

  public handleImage(image: Image){
    this.blog.CoverImage = image;
  }

  public changeCategory(category){
    this.blog.CategoryId = category.value;
  }
}
