import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Content } from 'src/app/contracts/content';
import { ContentService } from 'src/app/dataServices/contentService';

@Component({
  selector: 'menu-picker',
  templateUrl: './menu-picker.component.html',
  styleUrls: ['./menu-picker.component.scss'],
  providers: [ContentService]
})
export class MenuPickerComponent implements OnInit {
  @Output() onChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  public menus: Content[];

  constructor(private contentService:ContentService, private translateService: TranslateService) { }

  ngOnInit() {
    this.contentService.getAll().then((c:Content[]) =>{
      this.menus = c;
    });
  }

}
