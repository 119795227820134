import { Injectable } from "@angular/core";
import { HttpService } from "../common/httpService";
import { Filter } from "../contracts/infrastructure/filter";
import { QueryStringParameter } from "../contracts/queryStringParameter";
import { Base } from "./base";

@Injectable()
export class ProductService extends Base {
    constructor(public httpService: HttpService) {
        super(httpService, "ProductController");
    }

    public getAllActiveByCategory(categoryId: number, orderCode: string){
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter("categoryId", categoryId));
        parameters.push(new QueryStringParameter("orderByCode", orderCode));

        return this.httpService.get(this.controller, "GetAllActive", parameters);
    }

    public getFavourites(){
        return this.httpService.get(this.controller, "GetFavourites");
    }

    public listPairings(){
        return this.httpService.get(this.controller, "ListPairingProducts");
    }

    public GetByIds(ids: number[]){
        return this.httpService.post(this.controller, "GetByIds", ids );
    }

    public listServices(name: string){
        return this.httpService.post(this.controller, "ListServices", new Filter("Name", name));
    }

    public listProducts(name: string){
        return this.httpService.post(this.controller, "ListProducts", new Filter("Name", name));
    }
}