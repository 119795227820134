import { HttpService } from "../common/httpService";
import { Base } from "./base";

export class InstaPostService extends Base {
    constructor(public httpService: HttpService) {
        super(httpService, "InstaPostController");
    }

    public GetAll(){
        return this.httpService.get(this.controller, "GetAll");
    }
}