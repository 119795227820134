import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/contracts/product';

@Component({
  selector: 'pairing-right-item',
  templateUrl: './pairing-right-item.component.html',
  styleUrls: ['./pairing-right-item.component.scss']
})
export class PairingRightItemComponent implements OnInit {
  @Input() product: Product;
  
  constructor(private router: Router) { }

  ngOnInit() {
  }

  public navigate(p: Product){
    this.router.navigate(['/pages/product'], { queryParams: { id: p.Id } });
  }
}
