export class UploadImage {
    public Id: number;
    public Type: string;
    public Filter: string;

    constructor(id: number, type: string, filter: string){
        this.Id = id;
        this.Type = type;
        this.Filter = filter;
    }
}