<mat-card class="card">
    <menu-picker (onChangeEvent)="changeMenu($event)"></menu-picker>
</mat-card>

<media-uploader #mediaUploader (onImagesChangeEvent)="onImagesChanging()" (onImagesLoadedEvent)="imagesLoaded($event)"
    [disabled]="!isMenuSelected" type="Content" [id]="content?.Id"></media-uploader>

<mat-card class="card grid">
    <div>
        <button mat-raised-button color="primary" class="btn-save" [disabled]="!isMenuSelected" (click)="save()">{{
            'Save' | translate }}</button>
    </div>

    <div class="editor" *ngIf="content.IsTitle">
        <mat-form-field class="input-full-width">
            <mat-label>Cím</mat-label>
            <input matInput placeholder="{{'Cím' | translate }}" [(ngModel)]="content.Title">
        </mat-form-field>
    </div>

    <div class="editor" *ngIf="content.IsDescription">
        <span>Rövid leírás</span>
        <text-editor *ngIf="isEditorVisible" [imageList]="imageList" [disabled]="!isMenuSelected"
            [(data)]="content.Description"></text-editor>
    </div>

    <div class="editor" *ngIf="content.IsText">
        <span>Leírás</span>
        <text-editor *ngIf="isEditorVisible" [imageList]="imageList" [disabled]="!isMenuSelected"
            [(data)]="content.Text"></text-editor>
    </div>

    <div *ngIf="content.IsThumbnail">
        <image-picker #imagePicker *ngIf="content" type="Content" [id]="content?.Id" placeholder="CoverImage"
            (onChangeEvent)="handleImage($event)" [selectedImage]="content?.Thumbnail">
        </image-picker>
    </div>

    <div *ngIf="content.IsCta">
        <cta-picker [selectedCta]="content?.Cta" (onChangeEvent)="changeCta($event)"></cta-picker>
    </div>

</mat-card>