<div class="product">
    <div class="title">{{product.Name}}</div>
    <div class="sub-title" *ngIf="product.PairingSubTitle!= ''">/ {{product.PairingSubTitle}}</div>
    <div class="main-product">
        <div class="image">
            <img [src]="product?.Thumbnail?.FileName" />
        </div>
        <div class="text">
            {{product.PairingShortDescription}}
        </div>
    </div>
    <div class="paired-items">
        <div class="row1">
            <div class="image1" *ngIf="product.Pairings[0] && product.Pairings[0].Thumbnail"
                (click)="navigate(product.Pairings[0])">
                <img [src]="product.Pairings[0].Thumbnail.FileName" />
            </div>

        </div>
        <div class="row2">
            <div class="wrapper">
                <div class="image2" *ngIf="product.Pairings[1] && product.Pairings[1].Thumbnail"
                    (click)="navigate(product.Pairings[1])">
                    <img [src]="product.Pairings[1].Thumbnail.FileName" />
                </div>

                <div class="text">
                    {{product.PairingSubText}} asd
                </div>
            </div>

            <div class="image3" *ngIf="product.Pairings[2] && product.Pairings[2].Thumbnail"
                (click)="navigate(product.Pairings[2])">
                <img [src]="product.Pairings[2].Thumbnail.FileName" />
            </div>
        </div>
    </div>
</div>