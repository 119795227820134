<div class="cart-wrapper">
    <div class="products center empty-cart" *ngIf="step==0">
        <div class="title">A kosarad jelenleg üres</div>
        <div class="description"> Nézz szét a shopban és találd meg álmaid ruháját</div>

        <button class="action-button" style="width:200px" mat-flat-button (click)="openCollection()">Megnézem a
            kollekciót</button>
    </div>
    <div class="products" *ngIf="step==1">
        <h1>Kosár tartalma</h1>
        <table class="product-list">
            <thead>
                <tr>
                    <th class="action-button"></th>
                    <th class="product-title">Termék</th>
                    <th class="size-title">Méret</th>
                    <th class="quantity-title">Mennyiség</th>
                    <th class="price-title">Ár</th>
                </tr>
            </thead>
            <tbody class="teszt">
                <tr *ngFor="let product of shoppingCartService.shoppingCart">
                    <td>
                        <img src="/assets/delete.svg" class="delete" *ngIf="product.Product.IsVisible"
                            (click)="shoppingCartService.removeProductFromCart(product)" />
                    </td>
                    <td>
                        <div class="product-name">
                            <img class="product-image" [src]="product.Product.Thumbnail?.FileName"
                                *ngIf="product.Product.Thumbnail" />
                            <div class="product-image" *ngIf="!product.Product.Thumbnail"></div>
                            <div class="text-container">
                                <span>{{product.Product.Name}}</span><br />
                                <span class="price">
                                    <span *ngIf="product.IsActiveCoupon" class="price strikeout">
                                        {{product?.OriginalPrice | number:'.0' | currency }}</span>
                                    <span class="price"> {{ product?.Product?.Price | number:'.0' | currency }}
                                    </span>Forint
                                </span>

                            </div>
                        </div>
                    </td>
                    <td style="text-align: center;">
                        {{ getSize(product) }}
                    </td>
                    <td style="text-align: center;">
                        {{product.Quantity}}
                    </td>
                    <td style="text-align:center">
                        <span>{{ product?.Price | number:'.0' | currency }}</span>
                    </td>
                </tr>
            </tbody>
            <tbody class="last-row">
                <tr>
                    <td></td>
                </tr>
            </tbody>
        </table>

        <div class="product-list-mobile">
            <hr color="black" />
            <div *ngFor="let product of shoppingCartService.shoppingCart" class="item">
                <table style="width:100%">
                    <tr>
                        <td style="width:5%">
                            <img src="/assets/delete.svg" class="delete" *ngIf="product.Product.IsVisible"
                                (click)="shoppingCartService.removeProductFromCart(product)" />

                        </td>
                        <td style="width: 45%">
                            <img class="product-image" [src]="product.Product.Thumbnail?.FileName"
                                *ngIf="product.Product.Thumbnail" />
                        </td>
                        <td style="width: 50%">
                            <div class="text">
                                Név: <b>{{product.Product.Name}}</b><br />
                                Méret: {{ getSize(product) }}<br />
                                Mennyiség: {{product.Quantity}}<br />
                                Egységár: <span class="strikeout" *ngIf="product.IsActiveCoupon">{{
                                    product?.OriginalPrice |
                                    number:'.0' | currency }}</span> <span>{{ product?.Product?.Price | number:'.0' |
                                    currency
                                    }}</span> Forint<br />
                                Ár:{{ product?.Price | number:'.0' | currency }}<br />
                            </div>
                        </td>
                    </tr>
                </table>

                <hr color="black" />
            </div>
        </div>

        <div class="cart-button-wrapper">
            <span class="cart-button" (click)="deleteCart()">
                <div class="refresh">
                    <img class="trash-img" src="/assets/trash.svg" />
                    <span class="text">Kosár törlése</span>
                </div>
            </span>


            <span class="cart-button" (click)="refreshCart()">
                <div class="refresh">
                    <img class="refr-img" src="/assets/refresh.svg" />
                    <span class="text">Kosár frissítése</span>
                </div>
            </span>
        </div>

        <coupon></coupon>
    </div>
    <div class="products" *ngIf="step==2">
        <h1>Számlázási adatok</h1>
        <div class="row">
            <text-input class="full-width" title="Név/Cégnév" [isRequired]="true" [(ngModel)]="orderData.Name"
                ngDefaultControl>
            </text-input>
        </div>

        <div class="row">
            <text-input class="full-width" title="Adószám" [(ngModel)]="orderData.TaxNumber" ngDefaultControl>
            </text-input>
        </div>

        <div class="row">
            <text-input class="full-width" title="Ország" [isRequired]="true"
                [(ngModel)]="orderData.BillingAddress.Country" ngDefaultControl></text-input>
        </div>

        <div class="row">
            <text-input class="full-width" title="Megye" [isRequired]="true"
                [(ngModel)]="orderData.BillingAddress.County" ngDefaultControl>
            </text-input>
        </div>

        <div class="row">
            <text-input class="full-width" title="Irányítószám" [isRequired]="true"
                [(ngModel)]="orderData.BillingAddress.PostalCode" ngDefaultControl></text-input>
            <text-input class="full-width" title="Város" [isRequired]="true" [(ngModel)]="orderData.BillingAddress.City"
                ngDefaultControl>
            </text-input>
        </div>

        <div class="row">
            <text-input class="full-width" title="Lakcím" [isRequired]="true"
                [(ngModel)]="orderData.BillingAddress.AddressLine1" ngDefaultControl></text-input>
        </div>

        <div class="row spacing">
            <text-input class="full-width" [(ngModel)]="orderData.BillingAddress.AddressLine2" ngDefaultControl>
            </text-input>
        </div>

        <div class="row">
            <text-input class="full-width" title="Telefonszám" [isRequired]="true" [(ngModel)]="orderData.Phone"
                ngDefaultControl>
            </text-input>
            <text-input class="full-width" title="E-mail cím" [isRequired]="true" [(ngModel)]="orderData.Email"
                ngDefaultControl>
            </text-input>
        </div>

        <div class="row other-address" (click)="otherAddress()" *ngIf="orderData.DeliveryMethod.IsAdditionalAddress">
            <mat-checkbox [disableRipple]="true" [(ngModel)]="this.orderData.OtherAddress"></mat-checkbox> <span
                style="margin-left: 20px;">Szállítás másik címre?</span>
        </div>

        <div *ngIf="orderData.OtherAddress">
            <div class="row">
                <text-input class="full-width" title="Ország" [isRequired]="true"
                    [(ngModel)]="orderData.DeliveryAddress.Country" ngDefaultControl></text-input>
            </div>

            <div class="row">
                <text-input class="full-width" title="Megye" [isRequired]="true"
                    [(ngModel)]="orderData.DeliveryAddress.County" ngDefaultControl></text-input>
            </div>

            <div class="row">
                <text-input class="full-width" title="Irányítószám" [isRequired]="true"
                    [(ngModel)]="orderData.DeliveryAddress.PostalCode" ngDefaultControl></text-input>
                <text-input class="full-width" title="Város" [isRequired]="true"
                    [(ngModel)]="orderData.DeliveryAddress.City" ngDefaultControl></text-input>
            </div>

            <div class="row">
                <text-input class="full-width" title="Lakcím" [isRequired]="true"
                    [(ngModel)]="orderData.DeliveryAddress.AddressLine1" ngDefaultControl></text-input>
            </div>

            <div class="row spacing">
                <text-input class="full-width" [(ngModel)]="orderData.DeliveryAddress.AddressLine2" ngDefaultControl>
                </text-input>
            </div>
        </div>

        <div class="row" *ngIf="orderData.DeliveryMethod.Code == 'PostPoint'">
            <text-input id="postPointName" class="full-width" title="PostaPont neve" [isReadOnly]="true"
                [isRequired]="true" [(ngModel)]="orderData.PostPointName" ngDefaultControl></text-input>
            <text-input id="postPointName" class="full-width" title="PostaPont címe" [isReadOnly]="true"
                [isRequired]="true" [(ngModel)]="orderData.PostPointAddress" ngDefaultControl></text-input>
        </div>

        <div class="row spacing">
            <text-input class="full-width" multiline="true" title="Egyéb" [(ngModel)]="orderData.Comment"
                ngDefaultControl></text-input>
        </div>

        <coupon></coupon>
        
        <div *ngIf="orderData.DeliveryMethod.Code == 'PostPoint'" class="postpointwrapper">
            <div id="postPointChooser"></div>
        </div>

        
    </div>


    <div class="shopping" *ngIf="step==1 || step==2">
        <div class="cart">
            <h1>
                <span *ngIf="step==1">Kosár értéke</span>
                <span *ngIf="step==2">Rendelés</span>
            </h1>

            <div class="block">
                <div class="title">Részösszeg</div>
                <div class="content">
                    <span class="normal-price">{{shoppingCartService.cartSumPrice | number:'.0' | currency }}</span>
                    Forint
                </div>
            </div>

            <hr />

            <div class="block delivery-method-block">
                <div class="delivery-method-title">Szállítás</div>
                <div class="content">
                    <mat-radio-group name="dMethod">
                        <div *ngFor="let deliveryMethod of deliveryMethods" class="delivery-methods">
                            <div class="delivery-title">
                                <mat-radio-button [disableRipple]="true" [value]="deliveryMethod.Id" checked="false"
                                    (change)="changeDeliveryMethod($event)"></mat-radio-button>
                                <div class="delivery-method-wrapper">
                                    <span class="method-name">{{deliveryMethod.Name}}</span>
                                    <span *ngIf="deliveryMethod.Price"> - {{deliveryMethod.Price | number:'.0' |
                                        currency}}
                                        Forint</span>
                                </div>
                            </div>
                            <div class="delivery-description"
                                *ngIf="deliveryMethod.Description && orderData.DeliveryMethod && orderData.DeliveryMethod.Id == deliveryMethod.Id">
                                {{deliveryMethod.Description}}
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>

            <hr />

            <div class="block before-buttons">
                <div class="title">Összesen</div>
                <div class="content sum">{{shoppingCartService.sumWithDeliveryPrice | number:'.0' | currency}} Forint
                </div>
            </div>

            <div>
                <button mat-flat-button class="btn" *ngIf="step==1" (click)="goToPayment()"
                    [disabled]="isPaymentDisabled()"> Tovább a fizetéshez</button>
            </div>
            <div *ngIf="step==2">

                <div class="text">
                    Személyes adataidat a Vivian Wedding Shop <u><span class="link"
                            (click)="navigate('/pages/content', '84498032-f5f0-4d0b-82ab-827c1f84b0a4')">adatvédelmi
                            nyilatkozatának</span></u> megfelelően fogjuk feldolgozni.
                </div>

                <div class="text">
                    A folytatással elfogadod a Vivian Wedding Shop <u><span class="link"
                            (click)="navigate('/pages/content', '00943e21-e818-4109-98b4-b886a7b68f3f')">általános
                            szerződési feltételeit</span></u>.
                </div>

                <button mat-flat-button class="btn" [disabled]="clicked" *ngIf="isBankTransferEnabled()"
                    (click)="payCart('Transfer')">Fizetés előre utalással</button>
                <button mat-flat-button class="btn" [disabled]="clicked" *ngIf="isCashOnDeliveryEnabled()"
                    (click)="payCart('CashOnDelivery')">Fizetés utánvéttel</button>
                <button mat-flat-button class="btn" [disabled]="clicked" *ngIf="isOnlinePaymentEnabled()"
                    (click)="payCart('Barion')">Bankkártyás fizetés Barionnal</button>

                <div class="cards">
                    <img src="../../../assets/cards.png">
                </div>
            </div>


        </div>

        <!--<div class="cart">
            <h1>
                Kosár értéke
            </h1>

            <div class="price">
                Részösszeg: {{shoppingCartService.cartSumPrice | number:'.0' | currency }} Forint
            </div>

            <hr />

            <div *ngFor="let deliveryMethod of deliveryMethods" class="delivery-methods">
                <div class="delivery-title">
                    <input type="radio" name="dMethod" [value]="deliveryMethod.Id"
                        (change)="changeDeliveryMethod($event)">
                    <span>{{deliveryMethod.Name}}</span>
                    <span *ngIf="deliveryMethod.Price"> - {{deliveryMethod.Price | number:'.0' | currency}}
                        Forint</span>
                </div>
                <div class="delivery-description"
                    *ngIf="deliveryMethod.Description && orderData.DeliveryMethod && orderData.DeliveryMethod.Id == deliveryMethod.Id">
                    {{deliveryMethod.Description}}
                </div>

            </div>

            <hr />

            <div class="price">Összesen: {{shoppingCartService.sumWithDeliveryPrice | number:'.0' | currency}} Forint
            </div>

            <div>
                <button mat-flat-button class="btn" *ngIf="step==1" (click)="goToPayment()"
                    [disabled]="isPaymentDisabled()"> Tovább a fizetéshez</button>
            </div>
            <div *ngIf="step==2">
                <button mat-flat-button class="btn" [disabled]="clicked" *ngIf="isBankTransferEnabled()"
                    (click)="payCart('Transfer')">Fizetés előre utalással</button>
                <button mat-flat-button class="btn" [disabled]="clicked" *ngIf="isCashOnDeliveryEnabled()"
                    (click)="payCart('CashOnDelivery')">Fizetés utánvéttel</button>
                <button mat-flat-button class="btn" [disabled]="clicked" *ngIf="isOnlinePaymentEnabled()"
                    (click)="payCart('Barion')">Fizetés
                    Barionnal</button>
            </div>
        </div>-->
    </div>

    <div class="messages" *ngIf="step==3">
        <div class="finished-title">Köszönjük a rendelésed, a csomagod hamarosan feladásra kerül.</div>
        <div class="finished-description">A rendeléshez szükséges információkat elküldtük az e-mail címedre.</div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <insta-post-list></insta-post-list>
    </div>

    <div class="messages" *ngIf="step==4">
        <div *ngIf="!paymentState || paymentState.State == 'Prepared'" class="">
            <div class="finished-title">Várakozás a Barion-ra</div>
        </div>

        <div *ngIf="paymentState.State == 'Succeeded'" class="">
            <div class="finished-title">Köszönjük a rendelésed, a csomagod hamarosan feladásra kerül.</div>
            <div class="finished-description">A rendelés részleteit és a számlát elküldtük az e-mail címedre.</div>
        </div>

        <div *ngIf="paymentState.State != 'Succeeded' && paymentState.State != 'Prepared' " class="">
            <div class="finished-title">A fizetés sikertelen.</div>
            <div class="finished-description">
                <button mat-flat-button class="btn re-pay" (click)="rePayCart()">Fizetés újra</button>
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <insta-post-list></insta-post-list>
    </div>
</div>