import { Component, OnInit } from '@angular/core';
import { Newsletter } from 'src/app/contracts/newsletter';
import { NewsLetterService } from 'src/app/dataServices/newsletterService';

@Component({
  selector: 'app-newslettersend',
  templateUrl: './newslettersend.component.html',
  styleUrls: ['./newslettersend.component.scss'],
  providers: [NewsLetterService]
})
export class NewslettersendComponent implements OnInit {
  public newsletter: Newsletter;

  constructor(private newsLetterService: NewsLetterService) {
    this.newsletter = new Newsletter();
  }

  ngOnInit() {
  }

  public send() {
    this.newsLetterService.send(this.newsletter).then((r: any) => {
      if (r.ResultType == 0) {
        alert("Hírlevél sikeresen elküldve!");
      }
      else if (r.ResultType == 3) {
        let errors = "";
        r.ValidationResult.forEach(element => {
          errors += element.Key + ': ' + element.Value + '\r\n';
        });

        alert(errors);
      }
      else {
        alert("A hírlevelet nem sikerült elküldeni!")
      }
    }).catch(r => {
      alert("A hírlevelet nem sikerült elküldeni!")
    });
  }

}
