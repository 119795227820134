import { OrderData } from "./orderData";
import { OrderDataProduct } from "./orderDataProduct";
import { Product } from "./product";

export class OrderDataProductService{
    public Id: number;
    public OrderData: OrderDataProduct;
    public Product: Product;
    public Quantity: number;

}