import { Injectable } from '@angular/core';
import { JSEncrypt } from 'jsencrypt';
import { LocalStorageService } from 'ngx-localstorage';
import { v4 as uuid } from 'uuid';

@Injectable()
export class TokenService {
    private encrypt: any;

    constructor(private localStorage: LocalStorageService) {
        this.encrypt = new JSEncrypt({});
    }

    public saveToken(sessionId: string, key: string) {
        let cookie = { sessionId: sessionId, key: key };
        let cookiesting = btoa(JSON.stringify(cookie));

        localStorage.setItem(window.location.origin, cookiesting);
    }

    public loadToken(): any {
        let cookieString = localStorage.getItem(window.location.origin);

        if (cookieString) {
            let encoded = JSON.parse(atob(cookieString));

            let contextData = { sessionId: encoded.sessionId, key: encoded.key };

            this.encrypt.setPublicKey(decodeURI(contextData.key));
            let token = this.encrypt.encrypt(contextData.sessionId + ';' + uuid());

            return { sessionId: contextData.sessionId, token: token }
        }

        else {
            return '';
        }
    }

    public hasValidToken(): boolean {
        let cookieString = localStorage.getItem(window.location.origin);

        if (cookieString) {
            return true;
        }

        else {
            return false;
        }
    }

    public clearToken() {
        localStorage.removeItem(window.location.origin);
    }
}
