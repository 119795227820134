import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'how-to-care',
  templateUrl: './how-to-care.component.html',
  styleUrls: ['./how-to-care.component.scss']
})
export class HowToCareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
