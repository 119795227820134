<div class="container">
    <span>
        {{ contextStore?.Context?.Name }}
    </span>
    <button mat-button class="menu-btn uppercase" [matMenuTriggerFor]="profileMenu">
        <span class="material-icons icon">
            person
        </span>
    </button>
    
    <mat-menu #profileMenu="matMenu" xPosition="after">
        <button mat-menu-item class="menu-btn" (click)="changePassword()">
            Jelszó változtatás
        </button>
        <button mat-menu-item class="menu-btn" (click)="logout()">
            Kijelentkezés
        </button>
    </mat-menu>
    
</div>