import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Image } from 'src/app/contracts/image';
import { Product } from 'src/app/contracts/product';
import { SlideshowComponent } from '../slideshow/slideshow.component';

@Component({
  selector: 'image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Input() item: Product;
  public mediaContent: Image[];

  constructor(public dialog: MatDialog) {
    this.mediaContent = [];
  }

  ngOnInit() {
    let imageCount = 0;
    let hasMovie = false;

    this.item.MediaContent.forEach(mc => {
      if (mc.Id != this.item.Thumbnail.Id && imageCount <= 2 && !mc.IsMovie) {
        imageCount++;

        this.mediaContent.push(mc);
      }
      else if (!hasMovie && mc.IsMovie) {
        hasMovie = true;
        this.mediaContent.push(mc);
      }

    });

    //if (this.mediaContent != null && this.mediaContent.length > 0) {
    //this.currentMedia = this.mediaContent[0];
    //}


  }

  public showSlideShow(item: any) {
    let slideShowRef = this.dialog.open(SlideshowComponent, {
      data: { mediaContent: this.item.MediaContent, selected: item.Id, thumbnail: this.item.Thumbnail }
    });
  }

  //show(media: Image) {
  //  this.currentMedia = media;
  //}

}
