import { Injectable } from "@angular/core";

@Injectable()
export class DateTime {
    public convertFromUtc(date: Date) {
        let dateString = date.toString();
        if (dateString != "") {
            dateString = dateString.replace("T", " ");
            dateString += " UTC";
            let dateTime = new Date(dateString);
            return dateTime.toLocaleString();
        }
        else {
            return null;
        }
    }
}