import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ShopServices } from 'src/app/services/shop-service';

@Component({
  selector: 'align-picker',
  templateUrl: './align-picker.component.html',
  styleUrls: ['./align-picker.component.scss']
})
export class AlignPickerComponent implements OnInit {
  @Output() onOrderChange: EventEmitter<any> = new EventEmitter<any>();
  
  
  
  constructor(public shopServices: ShopServices) {  
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.Code == object2.Code;
  }

  ngOnInit() {
  }

  changeOrder(order:any){
    this.shopServices.selectedValue = order.value;
    this.onOrderChange.emit(order);
  }

}
