<div class="wrapper" *ngIf="instaPosts && instaPosts.length > 0">
    <div class="title">Kövess minket social medián is</div>
    <div class="items">
        <div *ngFor="let instaPost of instaPosts" class="insta-item">
            <a [href]="instaPost.Url" target="_blank">
                <img [src]="instaPost.Image.FileName" *ngIf="!instaPost.Image.IsMovie" />
                
                <video *ngIf="instaPost.Image.IsMovie" controls>
                    <source [src]="instaPost.Image.FileName" type="video/mp4">
                </video>
            </a>
        </div>
    </div>
</div>