import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Category } from 'src/app/contracts/category';
import { CategoryService } from 'src/app/dataServices/categoryService';
import { ShopServices } from 'src/app/services/shop-service';
import { ShoppingCartService } from 'src/app/services/shopping-cart-service';

var dataLayer;


@Component({
  selector: 'app-masterpage',
  templateUrl: './masterpage.component.html',
  styleUrls: ['./masterpage.component.scss'],
  providers: [CategoryService]
})
export class MasterpageComponent implements OnInit {
  public categories: Category[];
  public isShowMenu = false;
  public isShowCategoryMenu = false;
  public isShowAboutUsMenu = false;
  
  constructor(private categoryService: CategoryService, private router: Router, private shopServices: ShopServices, public cartServices: ShoppingCartService,
    private cookieBotService: NgxCookiebotService) { }

  ngOnInit() {
    this.categoryService.getAll().then((categories: Category[]) => {
      this.categories = categories;
    })

    this.cookieBotService.onServiceReady$.pipe(
     
    ).subscribe(() => {
      // this._cookieBotService.cookiebot is available
    });
  }

  navigate(url) {
    this.router.navigate([url]);
    this.isShowMenu = false;
  }

  navigateWithParam(url, code) {
    this.router.navigate([url], { queryParams: { code: code } });
    this.isShowMenu = false;
  }

  navigateShop(url, category: Category) {
    this.shopServices.activeShopMenu = category.Name;
    this.router.navigate([url], { queryParams: { code: category.Id } });
    this.isShowMenu = false;
  }

  navitgateToCart() {
    this.router.navigate(['/pages/cart']);
    this.isShowMenu = false;
  }

}
