<div class="page-image desktop">
    <img src="../../../assets/pairings.svg" class="image">
    <div class="centered">Kedvenc párosítások</div>
</div>

<div class="page-image mobile">
    <img src="../../../assets/blog_m.png" class="image">
    <div class="centered">Kedvenc párosítások</div>
</div>

<div *ngFor="let product of products" class="item desktop">
    <pairing-left-item [product]="product" *ngIf="product.Mode==1"></pairing-left-item>  
    <pairing-right-item [product]="product" *ngIf="product.Mode==2"></pairing-right-item>  
    <pairing-left2-item [product]="product" *ngIf="product.Mode==3"></pairing-left2-item>
</div>

<div *ngFor="let product of products" class="item mobile spacing">
    <pairing-mobile [product]="product"></pairing-mobile>
</div>

<insta-post-list></insta-post-list>