<action-header (onViewEvent)="onView()" (onNewEvent)="onNew()" (onEditEvent)="onEdit()" (onDeleteEvent)="onDelete()"
    [isSelected]="isSelected"></action-header>

<mat-card class="card admin">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef> {{ 'Id'| translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
        </ng-container>

        <ng-container matColumnDef="Question">
            <th mat-header-cell *matHeaderCellDef>Kérdés </th>
            <td mat-cell *matCellDef="let element"> {{element.Question}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'selected': selectedRowIndex === row.Id}" (click)="selection.select(row)">
        </tr>
    </table>
</mat-card>