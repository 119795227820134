<mat-card class="card">
    <div class="action-button-wrapper">
        <button mat-raised-button color="primary" class="btn-action" (click)="save()" [disabled]="readOnly">{{ 'Save' | translate }}</button>
        <button mat-raised-button color="secondary" class="btn-action" (click)="cancel()" >{{ 'Cancel' | translate }}</button>
    </div>
    
    <mat-form-field class="input-full-width">
        <mat-label>{{ 'Name' | translate }}</mat-label>
        <input matInput placeholder="{{ 'Name' | translate }}" [(ngModel)]="user.Name" [disabled]="readOnly">
    </mat-form-field>

    <mat-form-field class="input-full-width">
        <mat-label>E-mail</mat-label>
        <input matInput placeholder="E-mail" [(ngModel)]="user.Email" [disabled]="readOnly">
    </mat-form-field>

    <mat-checkbox class="checkbox" [(ngModel)]="user.IsLocked" [disabled]="readOnly">Zárolt</mat-checkbox>
</mat-card>