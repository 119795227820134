import { EventEmitter, Injectable, NgModule, Output } from "@angular/core";
import { Product } from "../contracts/product";
import { ProductProperty } from "../contracts/product-property";
import { ProductPropertyValue } from "../contracts/product-property-value";
import { ShopItem } from "../contracts/shop-item";
import { ProductListComponent } from "../pages/admin/product-list/product-list.component";
import { LocalStorageService } from 'ngx-localstorage';
import { ProductService } from "../dataServices/productService";
import { CartComponent } from "../pages/cart/cart.component";
import { Coupon } from "../contracts/coupon";
import { DeliveryMethod } from "../contracts/deliveryMethod";


@Injectable()
export class ShoppingCartService {
    @Output() onCartChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public shoppingCart: ShopItem[];
    public cartSumPrice: number;
    public sumWithDeliveryPrice: number;
    public sumWithCoupon: number;
    public couponCode: string;
    public isCouponActive: boolean;
    public coupon: Coupon;
    private previousProduct: ShopItem;
    public deliveryMethid: DeliveryMethod;

    constructor(private localStorage: LocalStorageService, private productService: ProductService) {
        this.shoppingCart = [];
        this.cartSumPrice = 0;
        this.sumWithDeliveryPrice = 0;
        this.loadCartCookie();
    }

    updateProductPropertiesInCart() {
        let ids = this.shoppingCart.map(function (p) {
            return p.Product.Id
        });

        this.productService.GetByIds(ids).then((r: any) => {
            if (r.ResultType == 0) {
                let itemNumber = this.countProducts();

                if (r.Result.length != this.shoppingCart.length) {
                    this.clearCart();
                }

                r.Result.forEach(item => {
                    let shopItem = this.shoppingCart.find(i => i.Product.Id == item.Id);
                    shopItem.Product.Price = item.Price;
                    this.calculatePrice(shopItem);
                });

                if (this.isCouponActive && (this.coupon.Type == 3 || this.coupon.Type == 4)) {
                    if (itemNumber > this.coupon.Value) {
                        let shopItem = this.shoppingCart.find(i => i.Product.Id == this.previousProduct.Product.Id);
                        shopItem.OriginalPrice = shopItem.Product.Price;
                        shopItem.Product.Price = 0;
                        shopItem.IsActiveCoupon = true;
                    }
                }

                this.cartChanged();
                this.recalculateSum();
                this.updateCartCookie();
            }
            else {
                this.clearCart();
            }
        });
    }

    private countProducts() {
        let itemNumber = 0;
        let items = this.shoppingCart.filter(f => {
            if (f.Product.IsVisible) {
                return f;
            }
        });

        items.forEach(i => {
            itemNumber += i.Quantity;
        });

        return itemNumber;
    }

    public recalculatePrices(deleteCoupon: boolean = false) {
        let itemNumber = this.countProducts();

        this.shoppingCart.forEach(item => {
            this.calculatePrice(item, deleteCoupon);
        });

        if (this.isCouponActive) {
            if (this.coupon.Type == 3 || this.coupon.Type == 4) {
                if (itemNumber > this.coupon.Value) {
                    let shopItem = this.shoppingCart.find(i => i.Product.Id == this.previousProduct.Product.Id);
                    //shopItem.CouponPrice = 0;
                    if (!deleteCoupon) {
                        shopItem.OriginalPrice = shopItem.Product.Price;
                        shopItem.Product.Price = 0;
                        shopItem.IsActiveCoupon = true;
                    }
                    else {
                        if (shopItem.IsActiveCoupon) {
                            shopItem.OriginalPrice = null;
                            shopItem.Product.Price = shopItem.Product.Price
                            shopItem.IsActiveCoupon = false;
                        }
                    }
                }
            }
        }

        this.recalculateSum();

    }

    public addProductToCart(product: Product, quantity: number) {
        // this.shoppingCart.push();
        let identifier = this.generateIdentifier(product);
        let item = this.shoppingCart.find((item: ShopItem) => item.Identifier == identifier);

        if (item != null) {
            item.Quantity += quantity;
        }
        else {
            item = new ShopItem();
            item.SelectedProperties = [];

            item.Product = product;
            item.Quantity = quantity;
            item.Identifier = identifier;

            product.Properties.forEach((property: ProductProperty) => {
                let value = property.Values.filter((v: ProductPropertyValue) => v.Selected)[0];
                item.SelectedProperties.push(value);
            });

            this.shoppingCart.push(item);
        }

        this.cartChanged();
        this.calculatePrice(item);
        this.recalculateSum();
        this.updateCartCookie();
    }

    public removeProductFromCart(shopItem: ShopItem) {
        this.shoppingCart = this.shoppingCart.filter((item: ShopItem) => item.Identifier != shopItem.Identifier);

        shopItem.Product.Services.forEach((p: Product) => {
            //this.shoppingCart = this.shoppingCart.filter((item: ShopItem) => item.Identifier !=   this.generateIdentifier(p));

            let shopItemService = this.shoppingCart.find((si: ShopItem) => si.Identifier == this.generateIdentifier(p));

            if (shopItemService) {
                if (shopItemService.Quantity > shopItem.Quantity) {
                    shopItemService.Quantity = shopItemService.Quantity - shopItem.Quantity;
                }
                else {
                    this.shoppingCart = this.shoppingCart.filter((item: ShopItem) => item.Identifier != this.generateIdentifier(p));
                }
            }

        });

        this.cartChanged();
        this.calculatePrice(shopItem);
        this.recalculateSum();
        this.updateCartCookie();
    }

    public countItemsInCart(): number {
        let items = this.shoppingCart.filter(f=>{
            return f.Product.IsVisible
        });

        return items.length;
    }

    private generateIdentifier(product: Product) {
        let identifier = product.Id.toString();

        product.Properties.forEach((pp: ProductProperty) => {
            pp.Values.forEach((ppv: ProductPropertyValue) => {
                if (ppv.Selected) {
                    identifier += pp.Id.toString();
                    identifier += ppv.Id.toString();
                }
            });
        });

        return identifier;
    }

    public updateCartCookie() {
        let validityDate = new Date();
        validityDate.setDate(validityDate.getDate() + 2);
        let cookie = { validity: validityDate, cart: this.shoppingCart };
        let cookiestring = JSON.stringify(cookie);
        this.localStorage.set(window.location.origin + 'shoppingCart', cookiestring)
    }

    public loadCartCookie() {
        let cookieString = localStorage.getItem(window.location.origin + 'shoppingCart');

        if (cookieString) {
            let encoded = JSON.parse(JSON.parse(cookieString));

            if (new Date(encoded.validity) < new Date()) {
                this.localStorage.remove(window.location.origin + 'shoppingCart');
            }
            else {
                this.shoppingCart = encoded.cart;
            }
        }

        this.cartChanged();
        this.recalculateSum();
    }

    public clearCart() {
        this.shoppingCart = [];
        this.cartChanged();
        this.recalculateSum();
        this.updateCartCookie();
    }

    private calculatePrice(shopItem: ShopItem, deleteCoupon: boolean = false) {
        shopItem.Price = shopItem.Quantity * shopItem.Product.Price;
       // shopItem.OriginalPrice = shopItem.Product.Price;
        shopItem.IsActiveCoupon = false;

        if (this.isCouponActive) {
            if (shopItem.Product.IsVisible) {
                if (this.coupon && this.coupon.Type == 1) {
                    if (!deleteCoupon) {
                        if (this.coupon.IsPercentage) {
                            let percentage = shopItem.Product.Price * (this.coupon.Value / 100);
                            //shopItem.CouponPrice = shopItem.Price - percentage;
                            shopItem.OriginalPrice = shopItem.Product.Price;
                            shopItem.Product.Price = shopItem.Product.Price - percentage;
                            shopItem.Price = shopItem.Quantity * shopItem.Product.Price;

                            shopItem.IsActiveCoupon = true
                        }
                        else {
                            //shopItem.CouponPrice = shopItem.Price - couponProduct[0].Value;
                            shopItem.OriginalPrice = shopItem.Product.Price;
                            shopItem.Product.Price = shopItem.Product.Price - this.coupon.Value;
                            shopItem.Price = shopItem.Quantity * shopItem.Product.Price;

                            shopItem.IsActiveCoupon = true;
                        }
                    }
                    else {
                        //   if (shopItem.IsActiveCoupon) {
                        if (shopItem.OriginalPrice) {
                            shopItem.IsActiveCoupon = false;
                            shopItem.Product.Price = shopItem.OriginalPrice;
                            shopItem.OriginalPrice = null;
                            shopItem.Price = shopItem.Quantity * shopItem.Product.Price;
                        }
                        //  }
                    }
                }
                else if (this.coupon && this.coupon.Type == 2) {
                    if (!deleteCoupon) {
                        let couponProduct = this.coupon.Products.filter((p) => {
                            return p.Product.Id == shopItem.Product.Id;
                        });

                        if (couponProduct && couponProduct.length > 0) {
                            if (this.coupon.IsPercentage) {
                                let percentage = shopItem.Product.Price * (couponProduct[0].Value / 100);
                                //shopItem.CouponPrice = shopItem.Price - percentage;
                                shopItem.OriginalPrice = shopItem.Product.Price;
                                shopItem.Product.Price = shopItem.Product.Price - percentage;
                                shopItem.Price = shopItem.Quantity * shopItem.Product.Price;

                                shopItem.IsActiveCoupon = true
                            }
                            else {
                                //shopItem.CouponPrice = shopItem.Price - couponProduct[0].Value;
                                shopItem.OriginalPrice = shopItem.Product.Price;
                                shopItem.Product.Price = shopItem.Product.Price - couponProduct[0].Value;
                                shopItem.Price = shopItem.Quantity * shopItem.Product.Price;

                                shopItem.IsActiveCoupon = true;
                            }
                        }
                    }
                    else {
                        // if (shopItem.IsActiveCoupon) {
                        if (shopItem.OriginalPrice) {
                            shopItem.IsActiveCoupon = false;
                            shopItem.Product.Price = shopItem.OriginalPrice;
                            shopItem.OriginalPrice = null;
                            shopItem.Price = shopItem.Quantity * shopItem.Product.Price;
                        }
                        // }
                    }
                }
                else if (this.coupon && this.coupon.Type == 3) {
                    if (this.previousProduct == null || this.previousProduct.Product.Price > shopItem.Product.Price) {
                        this.previousProduct = shopItem;

                    }
                }
                else if (this.coupon && this.coupon.Type == 4) {

                    if (this.previousProduct == null || this.previousProduct.Product.Price < shopItem.Product.Price) {
                        this.previousProduct = shopItem;
                    }
                }
            }
        }
    }

    public recalculateSum() {
        this.cartSumPrice = 0;
        this.sumWithCoupon = 0;

        this.shoppingCart.forEach((shopItem: ShopItem) => {
            /*if (shopItem.IsActiveCoupon) {
                this.cartSumPrice += shopItem.CouponPrice;
            }
            else {*/
            this.cartSumPrice += shopItem.Price;
            //}
        });

        /*if (this.isCouponActive) {
            if (this.coupon.Type == 1) {
                if (this.coupon.IsPercentage) {
                    let percentage = this.cartSumPrice * (this.coupon.Value / 100);
                    this.sumWithCoupon = this.cartSumPrice - percentage;
                }
                else {
                    this.sumWithCoupon = this.cartSumPrice - this.coupon.Value;
                }
            }
        }*/
    }

    private cartChanged() {
        if (this.shoppingCart.length == 0) {
            this.onCartChanged.emit(true);
        }
        else {
            this.onCartChanged.emit(false);
        }
    }
}