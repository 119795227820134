<mat-card class="card">
    <div class="action-button-wrapper">
        <button mat-raised-button color="primary" class="btn-action" (click)="save()" [disabled]="readOnly">{{ 'Save' |
            translate }}</button>
        <button mat-raised-button color="secondary" class="btn-action" (click)="cancel()">{{ 'Cancel' | translate
            }}</button>
    </div>

    <mat-form-field class="input-full-width">
        <mat-label>Kupon kód</mat-label>
        <input matInput placeholder="Kupon kód" [(ngModel)]="coupon.Code" [disabled]="readOnly">
    </mat-form-field>

    <div>
        <span>Tól: &nbsp;</span>
        <input color="primary" matInput [(ngModel)]="coupon.From" [matDatepicker]="fromDatePicker" [disabled]="readOnly">
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
    </div>

    <div>
        <span>Ig: &nbsp;</span>
        <input color="primary" matInput [(ngModel)]="coupon.To" [matDatepicker]="toDatePicker" [disabled]="readOnly">
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
    </div>

    <div style="margin-top: 20px;">
        <label id="example-radio-group-label">Válassz kedvezmény típust</label>
        <mat-radio-group class="coupon-wrapper" [(ngModel)]="coupon.Type" [disabled]="readOnly"> 
            <mat-radio-button class="coupon-item" *ngFor="let couponType of CouponTypes" [value]="couponType.Id">
                {{couponType.Name}}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div *ngIf="coupon.Type == 1" class="coupon-content">
        <div class="input-full-width">
            <mat-checkbox class="checkbox" [(ngModel)]="coupon.IsPercentage" [disabled]="readOnly">Százalék</mat-checkbox>
        </div>

        <mat-form-field class="action-value">
            <mat-label>Kedvezmény mértéke</mat-label>
            <input matInput placeholder="Kedvezmény mértéke" [(ngModel)]="coupon.Value" [disabled]="readOnly">
        </mat-form-field> {{ coupon.IsPercentage ? "%" : "Forint" }}
    </div>

    <div *ngIf="coupon.Type == 2" class="coupon-content">
        <mat-checkbox class="checkbox" [(ngModel)]="coupon.IsPercentage" [disabled]="readOnly" >Százalék</mat-checkbox>

        <div class="product">
            <button mat-raised-button color="primary" (click)="addProduct()">
                <span class="material-icons">
                    add
                </span>
            </button>
            <div *ngFor="let product of coupon.Products; let i = index" [attr.data-index]="i">
                <div class="product-item" *ngIf="!product.IsDeleted">
                    <button mat-raised-button color="primary" (click)="deleteProduct(product)">
                        <span class="material-icons">
                            delete
                        </span>
                    </button>

                    <div class="line">
                        <product-picker class="product-picker" [selectedProduct]="product.Product"
                            (onChangeEvent)="handleProduct($event, i)" [disabled]="readOnly || !product.IsNew"></product-picker>

                            <mat-form-field class="value">
                                <mat-label>Kedvezmény</mat-label>
                                <input matInput placeholder="Kedvezmény" [(ngModel)]="product.Value" [disabled]="readOnly">
                            </mat-form-field> {{coupon.IsPercentage ? '%' : 'Forint'}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--The cheapest-->
    <div *ngIf="coupon.Type == 3" class="coupon-content">
        <mat-form-field class="input-full-width">
            <mat-label>Szükséges darabszám a kedvezményhez</mat-label>
            <input matInput placeholder="Szükséges darabszám a kedvezményhez" [(ngModel)]="coupon.Value"
                [disabled]="readOnly">
        </mat-form-field>
    </div>

    <!--Expensive-->
    <div *ngIf="coupon.Type == 4" class="coupon-content">
        <mat-form-field class="input-full-width">
            <mat-label>Szükséges darabszám a kedvezményhez</mat-label>
            <input matInput placeholder="Szükséges darabszám a kedvezményhez" [(ngModel)]="coupon.Value"
                [disabled]="readOnly">
        </mat-form-field>
    </div>


</mat-card>