import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Blog } from 'src/app/contracts/blog';

@Component({
  selector: 'blog-list-item',
  templateUrl: './blog-list-item.component.html',
  styleUrls: ['./blog-list-item.component.scss']
})
export class BlogListItemComponent implements OnInit {
  @Input() blog: Blog;

  private categories = [
    { code: 1, viewValue: 'Vivian tippek' },
    { code: 2, viewValue: 'Minden, ami esküvő' },
    { code: 3, viewValue: 'Esküvői történetek' },
  ];

  constructor(private router: Router) { }

  ngOnInit() {
  }

  getCategoryById(id: number){
    let item  = this.categories.find(f=>{
      return f.code == id;
    })

    return item.viewValue;
  }

  public open(){
    this.router.navigate(['/pages/blog'], { queryParams: { id: this.blog.Id } });
  }

}
