<div class="wrapper">
    <div class="header">
        <div *ngIf="product.IsHandle" [ngClass]="{selected: id===3}" class="title" (click)="show(3)">Hogyan ápoljam</div>
        <div *ngIf="product.IsMeasure" [ngClass]="{selected: id===2}" class="title" (click)="show(2)"> Hogyan mérjem le magam?</div>
        <div *ngIf="product.IsSizeTable" [ngClass]="{selected: id===1}" class="title" (click)="show(1)">Mérettáblázat</div>
        <div *ngIf="product.IsTransport" [ngClass]="{selected: id===4}" class="title" (click)="show(4)">Szállítási információk</div>
    </div>

    <div class="content">
        <size-table *ngIf="id==1"></size-table>
        <div *ngIf="id==2" class="how-to-measure">
            <div class="measure-item">
                <img src="/assets/howtomeasure/csipo.jpg" class="image-size" />
                Csípő
            </div>
            <div class="measure-item">
                <img src="/assets/howtomeasure/derék.jpg" class="image-size" />
                Derék
            </div>
            <div class="measure-item">
                <img src="/assets/howtomeasure/derékföldhossz.jpg" class="image-size" />
                Derékföldhossz
            </div>
            <div class="measure-item">
                <img src="/assets/howtomeasure/kar.jpg" class="image-size" />
                Kar
            </div>
            <div class="measure-item">
                <img src="/assets/howtomeasure/mell.jpg" class="image-size" />
                Mell
            </div>
        </div>
        <how-to-care *ngIf="id==3"></how-to-care>
        <div *ngIf="id==4">
            <ul class="listing">
                <li>
                    <span style="display:block; margin-bottom:5px"><b>MPL Háztól-Házig</b></span>
                    Az MPL a Magyar Posta csomagszállítási szolgáltatása. A világ bármely szegletébe, gyorsan,
                    kényelmesen, megfelelő áron,<br/> az egyedi igényeknek is megfelelve kínál futárszolgáltatást.<br/>
                    Ár: 1980ft
                </li>

                <li>
                    <span style="display:block; margin-bottom:5px"><b>MPL Postapont, MOL pont, Coop pont</b></span>
                    Ezzel szállítási móddal a Magyar Posta fiókjaiba, MOL töltőállomásokra vagy a COOP üzletekbe kérheti
                    a csomag kiszállítását.<br/>
                    Ár:1680ft
                </li>

                <li>
                    <span style="display:block; margin-bottom:5px"><b>GLS</b></span>
                    Csomagját a GLS futárszolgálat munkatársai fogják kiszállítani Önnek, amennyiben ezt a megbízható
                    szállítási módot választja.<br/>
                    Ár: 2890ft
                </li>
            </ul>
        </div>
    </div>
</div>