import { Category } from './category';
import { Image } from './image';
import { ICategory } from './interfaces/iCategory';
import { IProduct } from './interfaces/iProduct'
import { IProductType } from './interfaces/iProductType';
import { ProductProperty } from './product-property';

export class Product implements IProduct {
    public Id: number;
    public Category: Category;
    public ProductType: Product;
    public Name: string;
    public Description: string;
    public Price: number;
    public IsActive: boolean;
    public IsVisible: boolean;
    public IsFavourite: boolean;

    public IsSizeTable: boolean;
    public IsHandle: boolean;
    public IsMeasure: boolean;
    public IsTransport: boolean;

    public Selected: boolean;

    public DisableBankTransfer: boolean;
    public DisableCashOnDelivery: boolean;
    public DisableOnlinePayment: boolean;

    public Thumbnail: Image;
    public MediaContent: Image[];
    public Services: Product[];
    public Pairings: Product[];
    public Properties: ProductProperty[];

    public ShowInPairingsList: boolean;
    public PairingSubTitle: string;
    public PairingShortDescription: string;
    public PairingThumbnail: Image;
    public PairingSubText:string;

    public IsNew:boolean;
    public IsDeleted:boolean;
    public Mode:number;
}