import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterpageComponent as AdminMasterPage } from './pages/admin/masterpage/masterpage.component';
import { LoginComponent } from './pages/admin/login/login.component';

import { MasterpageComponent as PageMasterPage } from './pages/masterpage/masterpage.component';
import { HomeComponent } from './pages/home/home.component';
import { ContentComponent as AdminContentComponent } from './pages/admin/content/content.component';
import { ContentComponent } from './pages/content/content.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { BlogListComponent as AdminBlogListComponent } from './pages/admin/blog-list/blog-list.component';
import { BlogListComponent } from './pages/blog-list/blog-list.component';
import { BlogEditComponent } from './pages/admin/blog-edit/blog-edit.component';
import { CategoryListComponent } from './pages/admin/category-list/category-list.component';
import { CategoryEditComponent } from './pages/admin/category-edit/category-edit.component';
import { ProducttypeListComponent } from './pages/admin/producttype-list/producttype-list.component';
import { ProducttypeEditComponent } from './pages/admin/producttype-edit/producttype-edit.component';
import { ProductListComponent } from './pages/admin/product-list/product-list.component';
import { ProductEditComponent } from './pages/admin/product-edit/product-edit.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ShopComponent } from './pages/shop/shop.component';
import { ProductComponent } from './pages/product/product.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { LoginRestrict } from './common/loginRestrict';
import { CartComponent } from './pages/cart/cart.component';
import { OrderListComponent } from './pages/admin/order-list/order-list.component';
import { OrderCheckComponent } from './pages/order-check/order-check.component';
import { NewslettersendComponent } from './pages/admin/newslettersend/newslettersend.component';
import { ChangepasswordComponent } from './pages/admin/changepassword/changepassword.component';
import { UserListComponent } from './pages/admin/user-list/user-list.component';
import { UserEditComponent } from './pages/admin/user-edit/user-edit.component';
import { FaqListComponent } from './pages/admin/faq-list/faq-list.component';
import { FaqEditComponent } from './pages/admin/faq-edit/faq-edit.component';
import { PairingsComponent } from './pages/pairings/pairings.component';
import { InstapostListComponent } from './pages/admin/instapost-list/instapost-list.component';
import { InstapostEditComponent } from './pages/admin/instapost-edit/instapost-edit.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { CouponListComponent } from './pages/admin/coupon-list/coupon-list.component';
import { CouponEditComponent } from './pages/admin/coupon-edit/coupon-edit.component';
import { BlogComponent } from './pages/blog/blog.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages/home',
    pathMatch: 'full',
    canActivate: [
    ],
    data: {}
  },
  {
    path: 'adminsite',
    redirectTo: 'adminsite/dashboard',
    pathMatch: 'full',
    canActivate: [
    ],
    data: {}
  },
  {
    path: 'admin',
    component: LoginComponent,
    canActivate: [],
    data: {}
  },
  {
    path: 'pages',
    component: PageMasterPage,
    canActivate: [],
    data: {},
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [
        ],
      },
      {
        path: 'content',
        component: ContentComponent,
        canActivate: [
        ]
      },
      {
        path: 'bloglist',
        component: BlogListComponent, 
        canActivate: [
        ]
      },
      {
        path: 'blog',
        component: BlogComponent,
        canActivate: [
        ]
      },
      {
        path: 'faq',
        component: FaqComponent,
        canActivate: [
        ]
      },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [
        ]
      },
      {
        path: 'shop',
        component: ShopComponent,
        canActivate: [
        ]
      },
      {
        path: 'product',
        component: ProductComponent,
        canActivate: [
        ]
      },
      {
        path: 'subscription',
        component: SubscriptionComponent,
        canActivate: [
        ]
      },
      {
        path: 'cart',
        component: CartComponent,
        canActivate: [
        ]
      },
      {
        path: 'cart/:paymentId',
        component: CartComponent,
        canActivate: [
        ]
      },
      {
        path: 'ordercheck/:orderId',
        component: OrderCheckComponent,
        canActivate: [
        ]
      },
      {
        path: 'pairings',
        component: PairingsComponent,
        canActivate: [
        ]
      },
      {
        path: 'aboutus',
        component: AboutUsComponent,
        canActivate: [
        ]
      },
    ]
  },
  {
    path: 'adminsite',
    component: AdminMasterPage,
    canActivate: [],
    data: {},
    children: [
      {
        path: 'content',
        component: AdminContentComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'bloglist',
        component: AdminBlogListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'blogedit',
        component: BlogEditComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'categorylist',
        component: CategoryListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'categoryedit',
        component: CategoryEditComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'producttypelist',
        component: ProducttypeListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'producttypeedit',
        component: ProducttypeEditComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'productlist',
        component: ProductListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'productedit',
        component: ProductEditComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'orderlist',
        component: OrderListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'newslettersend',
        component: NewslettersendComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'changepassword',
        component: ChangepasswordComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'userlist',
        component: UserListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'useredit',
        component: UserEditComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'faqlist',
        component: FaqListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'faqedit',
        component: FaqEditComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'instapostlist',
        component: InstapostListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'instapostedit',
        component: InstapostEditComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'couponlist',
        component: CouponListComponent,
        canActivate: [
          LoginRestrict
        ],
      },
      {
        path: 'couponedit',
        component: CouponEditComponent,
        canActivate: [
          LoginRestrict
        ],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
