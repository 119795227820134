import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'currency',
    pure: false
})
export class Currency implements PipeTransform {

   constructor(private sanitizer: DomSanitizer){}

   transform(input:any) : any {
       if (input){
        return input.toString().replace(",",".")
       }
       else{
           return "";
       }
   }

}