import { Component, OnInit } from '@angular/core';
import { InstaPost } from 'src/app/contracts/instaPost';
import { InstaPostService } from 'src/app/dataServices/instaPostService';

@Component({
  selector: 'insta-post-list',
  templateUrl: './insta-post-list.component.html',
  styleUrls: ['./insta-post-list.component.scss'],
  providers: [InstaPostService]
})
export class InstaPostListComponent implements OnInit {
  public instaPosts: InstaPost[];

  constructor(private instaPostService: InstaPostService) { 
    this.instaPosts = [];
  }

  ngOnInit() {
    this.instaPostService.getAll().then((r:any)=>{
      this.instaPosts = r.Result;
    });
  }

}
