import { OrderData } from "./orderData";
import { OrderDataProductPropertyValue } from "./orderDataProductPropertyValue";
import { OrderDataProductService } from "./orderDataProductService";
import { Product } from "./product";

export class OrderDataProduct{
    public Id: number;
    public OrderData: OrderData;
    public Product: Product;
    public OrderDataProductServices: OrderDataProductService[];
    public OrderDataProductPropertyValues: OrderDataProductPropertyValue[];
    public Quantity: number;
    public CalculatedPrice: number;
    public OriginalProductPrice: number;
    public OriginalCalculatedPrice: number;
    public ProductPrice: number;
    public IsDiscounted: boolean;

    constructor() {
        this.OrderDataProductServices = [];
        this.OrderDataProductPropertyValues = [];
    }
}