import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IUser } from 'src/app/contracts/interfaces/iUser';
import { UserService } from 'src/app/dataServices/userService';


var self;

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [UserService]
})

export class UserListComponent implements OnInit {

  public dataSource: IUser[];
  public displayedColumns = ['Id', 'Name', 'Email', 'IsLocked'];

  private selectedRowIndex: number = null;
  private selectedItem: any = null;
  private selection: SelectionModel<any>;
  private gridChangeSubscription: any;
  public isSelected: boolean = false;

  constructor(private userService: UserService, private router: Router, private translateService: TranslateService) {
    self = this;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(false);

    this.getAll();

    this.gridChangeSubscription = this.selection.changed.subscribe(this.change);
  }

  private getAll() {
    this.userService.getUsers().then((r: any) => {
      this.dataSource = r.Result;
    });
  }

  private change(e: any) {
    self.selectedRowIndex = e.added[0]['Id'];
    self.selectedItem = e.added[0];
    self.isSelected = true;
  }

  public onView() {
    this.router.navigateByUrl("adminsite/useredit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/userlist', mode: 'ReadOnly' } } });
  }

  public onNew() {
    this.router.navigateByUrl("adminsite/useredit", { state: { data: { routeBack: 'adminsite/userlist', mode: 'New' } } });
  }

  public onEdit() {
    this.router.navigateByUrl("adminsite/useredit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/userlist', mode: 'Modify' } } });
  }

  public onDelete() {
    if (confirm(this.translateService.instant("Confirm"))) {
      this.userService.delete(this.selectedItem.Id).then(r => {
        this.getAll();
        this.isSelected = false;
        this.selectedItem = null;
      });
    }
  }

  ngOnDestroy(): void {
    this.gridChangeSubscription.unsubscribe();
  }

  public generatePassword(){
    this.userService.generatePassword(self.selectedItem.Id).then((r:any)=>{
      if (r.ResultType == 0){
        alert("A generált új jelszót e-mailben elküldtük a felhasználónak")
      }
    })
  }
}
