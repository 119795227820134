import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Content } from 'src/app/contracts/content';
import { ContentService } from 'src/app/dataServices/contentService';
import { IgnoreSanitize } from 'src/app/pipes/ignoreSanitize';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  providers: [ContentService],
})
export class ContentComponent implements OnInit {
  private queryParams: any;
  public content: Content;

  constructor(private contentService: ContentService, private route: ActivatedRoute, private router: Router) {
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.contentService.getByCode(queryParams.code).then((c: Content) => {
        this.content = c;
      });
    });
  }
}
