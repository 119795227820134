import { Injectable } from "@angular/core";
import { HttpService } from "../common/httpService";
import { QueryStringParameter } from "../contracts/queryStringParameter";
import { Base } from "./base";

@Injectable()
export class CouponService extends Base {
   
    constructor(httpService: HttpService){
        super( httpService, "CouponController");
    }

    public activate(code: string){
        return this.httpService.post(this.controller, "Activate", code);
    }
}