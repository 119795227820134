import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-picker',
  templateUrl: './cta-picker.component.html',
  styleUrls: ['./cta-picker.component.scss']
})
export class CtaPickerComponent implements OnInit {
  @Input() selectedCta: string;
  @Output() onChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  public ctas: any[];
  
  constructor() { }

  ngOnInit() {

    this.ctas = [
      { code: 'Main', viewValue: 'Fő' },
      { code: 'Collection', viewValue: 'Kollekció' },
      { code: 'Pairing', viewValue: 'Párosítás' },
    ];
  }

}




