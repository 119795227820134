import { Component, Inject, OnInit } from '@angular/core';
import { Image } from 'src/app/contracts/image';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {
  public current: Image;
  public mediaContentCount: number;
  private mediaContentIndex: number;
  public mediaContentDisplayIndex: number;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.mediaContentCount = data.mediaContent.length;
    
    this.current = this.data.mediaContent.filter(f=>{
      return f.Id == data.selected;
    })[0];

    //this.current = this.data.mediaContent[0];
    this.mediaContentIndex = this.data.mediaContent.indexOf(this.current);
    this.mediaContentDisplayIndex = this.mediaContentIndex+1;
  }

  ngOnInit() {
  }

  public next(){
    if (this.mediaContentIndex < this.mediaContentCount){
      this.mediaContentIndex++;
      this.mediaContentDisplayIndex = this.mediaContentIndex+1;

      this.current = this.data.mediaContent[this.mediaContentIndex];
    }
  }

  public prev(){
    if (this.mediaContentIndex > 0){
      this.mediaContentIndex--;
      this.mediaContentDisplayIndex = this.mediaContentIndex+1;
    
      this.current = this.data.mediaContent[this.mediaContentIndex];
    }
  }
}
