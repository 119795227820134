<mat-card class="card">
    <button mat-raised-button color="primary" [disabled]="!isSelected" (click)="onViewEvent.emit()">
        <span class="material-icons">
            visibility
        </span>
    </button>

    <button mat-raised-button color="primary" (click)="onNewEvent.emit()">
        <span class="material-icons">
            add
        </span>
    </button>

    <button mat-raised-button color="primary" [disabled]="!isSelected" (click)="onEditEvent.emit()">
        <span class="material-icons">
            mode_edit
        </span>
    </button>

    <button mat-raised-button color="primary" [disabled]="!isSelected" (click)="onDeleteEvent.emit()"> 
        <span class="material-icons">
            delete
        </span>
    </button>

    <button *ngIf="custom1ButtonVisible" mat-raised-button color="primary" [disabled]="!isSelected" (click)="onCustom1Event.emit()"> 
        <span class="material-icons">
            {{ custom1Icon }}
        </span>
    </button>

    <button *ngIf="custom2ButtonVisible" mat-raised-button color="primary" [disabled]="!isSelected" (click)="onCustom2Event.emit()"> 
        <span class="material-icons">
            {{ custom2Icon }}
        </span>
    </button>

    <button *ngIf="custom3ButtonVisible" mat-raised-button color="primary" [disabled]="!isSelected" (click)="onCustom3Event.emit()"> 
        <span class="material-icons">
            {{ custom3Icon }}
        </span>
    </button>
</mat-card>