import { Injectable } from "@angular/core";
import { HttpService } from "../common/httpService";
import { Newsletter } from "../contracts/newsletter";
import { QueryStringParameter } from "../contracts/queryStringParameter";
import { Subscription } from "../contracts/subscription";
import { Base } from "./base";

@Injectable()
export class NewsLetterService extends Base {
    constructor(public httpService: HttpService) {
        super(httpService, "NewsletterController");
    }

    public subscribe(subscription: Subscription){
        return this.httpService.post(this.controller, "Subscribe", subscription);
    }

    public unSubscribe(id: string) {
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter('id', id));
        
        return this.httpService.post(this.controller, "UnSubscribe", null, parameters);
    }

    public activate(id: string) {
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter('id', id));
        
        return this.httpService.post(this.controller, "Activate", null, parameters);
    }

    public unSubscribeFromNewsLetter(subscription: Subscription){
        return this.httpService.post(this.controller, "UnSubscribeRequest", subscription);
    }

    public send(newsLetter: Newsletter){
        return this.httpService.post(this.controller, "Send", newsLetter);
    }

}