import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';

@Component({
  selector: 'text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit, AfterViewInit {
  @Input() disabled: boolean;
  @Input() data: string;
  @Input() imageList: any[] = [];
  @Output() dataChange = new EventEmitter<string>();

  public config;

  constructor() { }

  ngOnInit() {
    this.config = {
      height: 500,
      menubar: true,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount, table'
      ],
      toolbar:
        'undo redo | formatselect | fontselect  | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | lineheightselect | \
      bullist numlist outdent indent | removeformat | help image | \
      table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
      image_prepend_url: "/",
      image_list: this.imageList,
      font_formats:'Montserrat-SemiBold=Montserrat-SemiBold;Montserrat-Regular=Montserrat-Regular;Montserrat-Medium=Montserrat-Medium;Montserrat-Light=Montserrat-Light; \
        CormorantGaramond-SemiBold=CormorantGaramond-SemiBold;CormorantGaramond-Regular=CormorantGaramond-Regular;CormorantGaramond-Medium=CormorantGaramond-Medium;CormorantGaramond-Bold=CormorantGaramond-Bold',
      content_style: "@import url('/assets/fonts/fonts.css');",
      lineheight_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px",
      fontsize_formats: "8px 10px 12px 14px 18px 24px 36px"
    }
  }

  ngAfterViewInit(){
  
  }

}
