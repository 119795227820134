import { Injectable } from '@angular/core';

@Injectable()
export class ProcessingService {
    //public Processing: boolean;
    private guids: string[];

    constructor(){
        this.guids = [];
    }

    public addGuid(id: string){
        this.guids.push(id);
    }

    public removeGuid(id: string){
        this.guids = this.guids.filter(function(value, index, arr){ 
            return value != id; 
        });
    }

    public hasGuid(id: string){
        return this.guids.indexOf(id) > -1;
    }   
}