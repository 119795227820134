<div class="page-image desktop">
    <img src="../../../assets/home_image.png" class="image">
    <!--<div class="centered">Centered</div>-->
</div>

<div class="page-image mobile">
    <img src="../../../assets/home_image_m.png" class="image">
    <!--<div class="centered">Centered</div>-->
</div>

<cta-main></cta-main>
<card-full code="acc85bc2-910e-4d80-a216-a31accf5975b" url="/pages/content"></card-full>
<card-full-left-image code="5560C533-3A03-4E07-B949-C9FF0248420F" url="/pages/content"></card-full-left-image>
<cta-collection></cta-collection>
<div class="card-container">
    <card-half code="979d73e5-858c-4bdf-b891-925beb710f22" btnText="Tovább" url="/pages/shop" urlParam="5"></card-half>
    <card-half code="f1ba431e-d8c0-40f5-adaa-99dfe138fa23" btnText="Tovább" url="/pages/shop" urlParam="6"></card-half>
</div>
<cta-pairings></cta-pairings>
<favourites></favourites>
<newsletter></newsletter>