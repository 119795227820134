import { Component, OnInit } from '@angular/core';
import { CouponService } from 'src/app/dataServices/couponService';
import { ShoppingCartService } from 'src/app/services/shopping-cart-service';

@Component({
  selector: 'coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  providers: [CouponService]
})
export class CouponComponent implements OnInit {
  private clicked: boolean = false;
  constructor(private couponService: CouponService, public shoppingCartService: ShoppingCartService) { }

  ngOnInit() {
  }

  activate() {

    if (!this.clicked) {
      if (this.shoppingCartService.couponCode) {
        this.clicked = true;
        this.couponService.activate(JSON.stringify(this.shoppingCartService.couponCode)).then((res: any) => {
          if (res.Result) {
            this.shoppingCartService.coupon = res.Result;
            this.shoppingCartService.isCouponActive = true;
            this.shoppingCartService.recalculatePrices();

            if (this.shoppingCartService.deliveryMethid && this.shoppingCartService.deliveryMethid.Price) {
              this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice + this.shoppingCartService.deliveryMethid.Price;
            }
            else {
              this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice;
            }

            alert("A kupon sikeresen érvényesítésre került");
          }
          else {
            alert("A kupont nem érvényes");
          }
          this.clicked = false;
        }).catch(e => {
          alert("A kupon nem érvényes");
          this.clicked = false;
        })
      }
    }
  }

  deActivate() {
    this.shoppingCartService.couponCode = "";
    this.shoppingCartService.recalculatePrices(true);
    this.shoppingCartService.recalculateSum();
    this.shoppingCartService.coupon = null;
    this.shoppingCartService.isCouponActive = false;

    if (this.shoppingCartService.deliveryMethid && this.shoppingCartService.deliveryMethid.Price) {
      this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice + this.shoppingCartService.deliveryMethid.Price;
    }
    else {
      this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice;
    }
  }
}
