import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageService } from 'src/app/dataServices/imageService';
import { Image } from '../../contracts/image';

@Component({
  selector: 'image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  providers: [ImageService]
})
export class ImagePickerComponent implements OnInit {
  @Input() type: string;
  @Input() id: number;
  @Input() placeholder: string;
  @Input() selectedImage: Image;
  @Input() disabled: boolean;
  @Input() filter: string = "";

  @Output() onChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  public images: Image[];

  constructor(private imageService: ImageService) { }

  ngOnInit() {
    this.getImages();
  }

  public getImages() {
    if (this.filter == '') {
      this.imageService.getByEntiTypeAndId(this.type, this.id).then((r: Image[]) => {
        this.images = r;
      });
    }
    else{
      this.imageService.getByFilter(this.filter).then((r: Image[]) => {
        this.images = r;
      });
    }
  }

  public objectComparisonFunction = function (option, value): boolean {
    return option.Id === value.Id;
  }

}
