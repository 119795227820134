import { HttpService } from "../common/httpService";
import { BillModel } from "../contracts/billl-model";
import { Filter } from "../contracts/infrastructure/filter";
import { OrderData } from "../contracts/orderData";
import { QueryStringParameter } from "../contracts/queryStringParameter";
import { Base } from "./base";

export class OrderService extends Base {

    constructor(httpService: HttpService) {
        super(httpService, "OrderController");
    }

    public Order(orderData: OrderData) {
        return this.httpService.post(this.controller, "Order", orderData);
    }

    public filter(filters: Filter[]) {
        return super.filter(filters);
    }

    public getByOrderId(orderId: string) {
        let params: QueryStringParameter[] = [];
        params.push(new QueryStringParameter("orderId", orderId));

        return this.httpService.get(this.controller, "GetByOrderId", params);
    }

    public setState(orderId: string, state:number){
        let params: QueryStringParameter[] = [];
        params.push(new QueryStringParameter("orderId", orderId));
        params.push(new QueryStringParameter("state", state));

        return this.httpService.post(this.controller, "SetOrderState", null, params);
    } 

    public setSeen(orderId: string){
        let params: QueryStringParameter[] = [];
        params.push(new QueryStringParameter("orderId", orderId));

        return this.httpService.post(this.controller, "SetSeen", null, params);
    }

    public deleteByOrderId(orderId: string){
        let params: QueryStringParameter[] = [];
        params.push(new QueryStringParameter("orderId", orderId));

        return this.httpService.post(this.controller, "Delete", null, params);
    }

    public createBill(model: BillModel){
        return this.httpService.post(this.controller, "CreateBill", model);
    }

    public getUrl(id){
        let params: QueryStringParameter[] = [];
        params.push(new QueryStringParameter("id", id));

        return this.httpService.get(this.controller, "GetUrl", params);
    }

    public getPdf(id){
        let params: QueryStringParameter[] = [];
        params.push(new QueryStringParameter("id", id));

        return this.httpService.get(this.controller, "GetPdf", params);
    }

    public getPaymentState(paymentId: string){
        let params: QueryStringParameter[] = [];
        params.push(new QueryStringParameter("paymentId", paymentId));

        return this.httpService.get(this.controller, "GetPaymentState", params);
    }

    public rePay(orderId: string){
        let params: QueryStringParameter[] = [];
        params.push(new QueryStringParameter("orderId", orderId));

        return this.httpService.post(this.controller, "RePay", null, params);
    }
}
