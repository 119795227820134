import { PropertyBindingType } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Category } from 'src/app/contracts/category';
import { Product } from 'src/app/contracts/product';
import { ProductProperty } from 'src/app/contracts/product-property';
import { ProductPropertyValue } from 'src/app/contracts/product-property-value';
import { ProductService } from 'src/app/dataServices/productService';
import { ImagePickerComponent } from 'src/app/directives/image-picker/image-picker.component';
import { MediaUploaderComponent } from 'src/app/directives/media-uploader/media-uploader.component';
import { Image } from '../../../contracts/image';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
  providers: [ProductService]
})
export class ProductEditComponent implements OnInit {
  private pageData: any;
  public readOnly: boolean;
  public product: Product;

  private mediaUploader: MediaUploaderComponent;
  private imagePickerComponent: ImagePickerComponent;
  private pairingImagePickerComponent: ImagePickerComponent;

  @ViewChild('mediaUploader', { static: false }) set file(component: MediaUploaderComponent) {
    if (component) {
      this.mediaUploader = component;
    }
  }

  @ViewChild('imagePicker', { static: false }) set imagePicker(component: ImagePickerComponent) {
    if (component) {
      this.imagePickerComponent = component;
    }
  }

  @ViewChild('pairingImagePicker', { static: false }) set pairingImagePicker(component: ImagePickerComponent) {
    if (component) {
      this.pairingImagePickerComponent = component;
    }
  }

  constructor(private router: Router, private productService: ProductService) {
    this.pageData = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.data : null;
    this.readOnly = this.pageData.mode == "ReadOnly";

    this.product = new Product();
    this.getById();
  }

  ngOnInit() {

  }

  private getById() {
    this.productService.getById(this.pageData.id).then((r: Product) => {
      this.product = r;
      this.mediaUploader.load("Product", r.Id);
    });
  }

  public onImagesChanging() {
    //this.isEditorVisible = false;
  }

  public imagesLoaded(images: Image[]) {
    /* let list = [];
     images.forEach(e => {
       list.push({ title: e.Name, value: e.FileName });
     });
 
     this.imageList = list;
     this.isEditorVisible = true;*/
    this.imagePickerComponent.getImages();
  }

  public handleImage(image: Image) {
    this.product.Thumbnail = image;
  }
  
  public handlePairingImage(image: Image) {
    this.product.PairingThumbnail = image;
  }

  public handleCategory(category: Category) {
    this.product.Category = category;
  }

  public handleService(product: Product, idx: number) {
    let isNew = this.product.Services[idx].IsNew;

    this.product.Services[idx] = product;
    this.product.Services[idx].IsNew = isNew;
  }

  public handlePairing(product: Product, idx: number) {
    let isNew = this.product.Pairings[idx].IsNew;

    this.product.Pairings[idx] = product;
    this.product.Pairings[idx].IsNew = isNew;
  }

  public deleteService(service: Product) {
    if (service.IsNew) {
      this.product.Services = this.product.Services.filter(obj => obj !== service);
    }
    else {
      service.IsDeleted = true;
    }
  }

  public deletePairing(pairing: Product) {
    if (pairing.IsNew) {
      this.product.Pairings = this.product.Pairings.filter(obj => obj !== pairing);
    }
    else {
      pairing.IsDeleted = true;
    }
  }

  public addService() {
    let p = new Product()
    p.IsNew = true;
    p.Name = "";
    this.product.Services.push(p);
  }

  public addPairing() {
    let p = new Product()
    p.IsNew = true;
    p.Name = "";
    this.product.Pairings.push(p);
  }


  public save() {
    this.productService.update(this.product).then(r => {
      this.router.navigateByUrl(this.pageData.routeBack);
    });
  }

  public cancel() {
    this.router.navigateByUrl(this.pageData.routeBack);
  }

  public addProperty(){
    let pp = new ProductProperty()
    pp.IsNew = true;
    pp.Values = [];
    this.product.Properties.push(pp);
  }

  public addPropertyValue(pp: ProductProperty){
    let ppv = new ProductPropertyValue()
    ppv.IsNew = true;
    
    pp.Values.push(ppv);
  }

  public deleteProperty(pp: ProductProperty){
    if (pp.IsNew){
      this.product.Properties = this.product.Properties.filter(obj => obj !== pp);
    }
    else{
      pp.IsDeleted = true;
    }
  }

  public deletePropertyValue(ppv: ProductPropertyValue, pp: ProductProperty){
    if (ppv.IsNew){
      pp.Values = pp.Values.filter(obj => obj !== ppv);
    }
    else{ 
      ppv.IsDeleted = true;
    }
  }
}
