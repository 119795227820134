import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/contracts/user';
import { UserService } from 'src/app/dataServices/userService';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  providers: [UserService]
})
export class UserEditComponent implements OnInit {

  public pageData: any;
  public readOnly: boolean;
  public user: User;

  constructor(private router: Router, private userService: UserService) {
    this.user = new User();
    this.pageData = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.data : null;
    this.readOnly = this.pageData.mode == "ReadOnly";
  }

  ngOnInit() {
    if (this.pageData.mode != "New"){
      this.getById();
    }
  }

  private getById() {
    this.userService.getById(this.pageData.id).then((r: any) => {
      this.user = r.Result;
    });
  }

  public cancel(){
    this.router.navigateByUrl(this.pageData.routeBack);
  }

  public save() {
    if (this.pageData.mode == "New"){
      this.userService.save(this.user).then((r:any)=>{
        if (r.ResultType == 0) {
          this.router.navigateByUrl(this.pageData.routeBack);
        }
        else if (r.ResultType == 3) {
          let errors = "";
          r.ValidationResult.forEach(element => {
            errors += element.Key + ': ' + element.Value + '\r\n';
          });
  
          alert(errors);
        }
      });
    }
    else{
      this.userService.update(this.user).then((r:any) => {
        if (r.ResultType == 0) {
          this.router.navigateByUrl(this.pageData.routeBack);
        }
        else if (r.ResultType == 3) {
          let errors = "";
          r.ValidationResult.forEach(element => {
            errors += element.Key + ': ' + element.Value + '\r\n';
          });
  
          alert(errors);
        }
      });
    }
  }
}
