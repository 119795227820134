import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'src/app/common/dateTime';
import { Faq } from 'src/app/contracts/faq';
import { FaqService } from 'src/app/dataServices/faqService';

@Component({
  selector: 'app-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.scss'],
  providers: [FaqService]
})
export class FaqEditComponent implements OnInit {
  public faq: Faq;
  private pageData: any;
  public readOnly: boolean;
  public isEditorVisible = true;

  constructor(private router: Router, private faqService: FaqService, public dateTimeService: DateTime) {
    this.faq = new Faq();
    this.pageData = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.data : null;
    this.readOnly = this.pageData.mode == "ReadOnly";
  }

  ngOnInit() {
    this.getById();
  }

  private getById() {
    this.faqService.getById(this.pageData.id).then((r: any) => {
      if (r.ResultType == 0) {
        this.faq = r.Result;
      }
    });
  }

  public save() {
    this.faqService.update(this.faq).then(r => {
      this.router.navigateByUrl(this.pageData.routeBack);
    });
  }

  public cancel(){
    this.router.navigateByUrl(this.pageData.routeBack);
  }

}
