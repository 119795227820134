<mat-card class="card">
    <div class="upload">
        <span><input type="file" [disabled]="disabled" #file multiple (change)="onFileChange($event)"
                accept=".gif,.jpg,.jpeg,.png" /></span>
        <div class="btn-action-wrapper">
            <button mat-raised-button color="primary" [disabled]="!uploadableFiles" class="btn-upload"
                (click)="upload()" class="btn-action"> {{ 'Upload' | translate }} </button>
            <button mat-raised-button color="primary" class="btn-action" (click)="showHide()"> {{
                'ShowHideFiles' | translate }} </button>
        </div>
    </div>

    <div class="files">
        <!--<mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'Files' | translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>-->

        <mat-tab-group #fileTab *ngIf="isFilesVisible">
            <mat-tab [disabled]="disabled" label="{{'UploadedFiles' | translate }} ({{ uploadedFiles != null ? uploadedFiles.length : '0' }})">
                <div class="scroll">
                    <div *ngFor="let file of uploadedFiles; let i = index" class="line">
                        <button mat-icon-button color="secondary" (click)="deleteFile(file.Id)" [disabled]="disabled">
                            <span class="material-icons">
                                delete
                            </span>
                        </button>
                        <div class="file-name">
                            <a href="{{file.FileName}}" target="_blank"> {{file.Name}}</a>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [disabled]="!uploadableFiles || disabled" label="{{'UploadableFiles' | translate }} ({{ uploadableFiles != null ? uploadableFiles.length : '0' }}) ">
                <div class="scroll">
                    <div *ngFor="let file of uploadableFiles; let i = index" class="line">
                        <button mat-icon-button color="secondary" (click)="removeFileFromQueue(file.name)" [disabled]="disabled">
                            <span class="material-icons">
                                delete
                            </span>
                        </button>
                        <div class="file-name">
                            {{file.name}}
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

        <!-- </mat-expansion-panel>-->

    </div>


</mat-card>