import { query } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'src/app/contracts/subscription';
import { NewsLetterService } from 'src/app/dataServices/newsletterService';
import { CaptchaComponent } from 'src/app/directives/captcha/captcha.component';

@Component({
  selector: 'subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  providers: [NewsLetterService]
})
export class SubscriptionComponent implements OnInit {
  public successActivation: boolean = false;
  public successUnSubscribe: boolean = false;

  public unsuccessActivation: boolean = false;
  public unsuccessUnSubscribe: boolean = false;

  public unSubscribeRequest: boolean = false;
  public unSubscribeRequestMessage: boolean = false;

  public subscription: Subscription;
  private onResolvedSubscription: any;
  private onErrorSubscription:any;
  private captchaDialog: any;

  constructor(private newsletterService: NewsLetterService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {
    this.subscription = new Subscription();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.unsuccessActivation = false;
      this.unsuccessUnSubscribe = false;
      this.successActivation = false;
      this.successUnSubscribe = false;


      if (queryParams.mode == 1) {
        this.unSubscribe(queryParams.code);
      }
      else if (queryParams.mode == 2) {
        this.activate(queryParams.code);
      }
      else {
        this.unSubscribeRequest = true;
      }
    });
  }

  public unSubscribe(id: string) {
    this.newsletterService.unSubscribe(id).then(r => {
      this.successUnSubscribe = true;
    }).catch(r => {
      this.unsuccessUnSubscribe = true;
    });
  }

  public activate(id: string) {
    this.newsletterService.activate(id).then(r => {
      this.successActivation = true;
    }).catch(r => {
      this.unsuccessActivation = true;
    });
  }

  public unSubscribeFromNewsLetter() {
    this.newsletterService.unSubscribeFromNewsLetter(this.subscription).then((r:any) => {
      if (r.ResultType == 0) {
        this.unSubscribeRequest = false;
        this.unSubscribeRequestMessage = true;
      }
      else if (r.ResultType == 3) {
        let errors = "";
        r.ValidationResult.forEach(element => {
          errors += element.Key + ': ' + element.Value + '\r\n';
        });

        alert(errors);
      }
      else {
        alert('Sikertelen leiratkozás');
      }
    }).catch(r => {
      alert('Sikertelen leiratkozás');
    });
  }

  public openCaptcha(){
        
    this.captchaDialog = this.dialog.open(CaptchaComponent, {
      width: '310px',
      height: '126px',
    });

    this.onResolvedSubscription = this.captchaDialog.componentInstance.onResolved.subscribe(() =>{
      this.unSubscribeFromNewsLetter();
      this.unSubscripbeCapthca();
    });

    this.onErrorSubscription = this.captchaDialog.componentInstance.onError.subscribe(()=>{
      this.unSubscripbeCapthca();
    });
  }

  private unSubscripbeCapthca(){
    this.onResolvedSubscription.unsubscribe();
    this.onErrorSubscription.unsubscribe();

    this.captchaDialog.close();
    this.captchaDialog = null;
  }


}
