import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Category } from 'src/app/contracts/category';
import { ICategory } from 'src/app/contracts/interfaces/iCategory';
import { CategoryService } from 'src/app/dataServices/categoryService';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

var self;

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  providers: [CategoryService]
})
export class CategoryListComponent implements OnInit, OnDestroy {
  public dataSource: ICategory[];
  public displayedColumns = ['Id', 'Name', 'Parent'];
    
  private selectedRowIndex: number = null;
  private selectedItem: any = null;
  private selection: SelectionModel<any>;
  private gridChangeSubscription: any;
  public isSelected: boolean = false;
  

  constructor(private categoryService: CategoryService, private router: Router, private translateService: TranslateService) {
    self = this;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(false);

    this.getAll();

    this.gridChangeSubscription = this.selection.changed.subscribe(this.change);
  }

  private getAll() {
    this.categoryService.getAll().then((r: ICategory[]) => {
      this.dataSource = r;
    });
  }

  private filterByName(name: string){
    this.categoryService.filterByName(name).then((r:ICategory[]) => {
      this.dataSource = r;
    });
  }

  private change(e: any) {
    self.selectedRowIndex = e.added[0]['Id'];
    self.selectedItem = e.added[0];
    self.isSelected = true;
  }

  public onView() {
    this.router.navigateByUrl("adminsite/categoryedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/categorylist', mode: 'ReadOnly' } } });
  }

  public onNew() {
    this.router.navigateByUrl("adminsite/categoryedit", { state: { data: { routeBack: 'adminsite/categorylist', mode: 'New' } } });
  }

  public onEdit() {
    this.router.navigateByUrl("adminsite/categoryedit", { state: { data: { id: this.selectedItem.Id, routeBack: 'adminsite/categorylist', mode: 'Modify' } } });
  }

  public onDelete() {
    if (confirm(this.translateService.instant("Confirm"))) {
      this.categoryService.delete(this.selectedItem.Id).then(r => {
        this.getAll();
        this.isSelected = false;
        this.selectedItem = null;
      });
    }
  }

  ngOnDestroy(): void {
    this.gridChangeSubscription.unsubscribe();
  }
}
