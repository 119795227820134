<div class="page-image desktop">
    <img src="../../../assets/blog.svg" class="image">
    <div class="centered">Blog</div>
</div>
<div class="page-image mobile">
    <img src="../../../assets/blog_m.png" class="image">
    <div class="centered">Blog</div>
</div>

<div *ngFor="let blog of blogs" class="blog-list">
    <blog-list-item [blog]="blog"></blog-list-item>
</div>