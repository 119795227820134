<div>
    <h1>
        {{property.Name}}
    </h1>

    <div class="values">
        <div *ngFor="let value of property.Values" class="value"> 
            <property-picker-item [value]="value" [selected]="selectedValue != null && value != null && selectedValue.Id == value.Id" (onSelected)="onSelected($event)"></property-picker-item>
        </div>
    </div>
</div>