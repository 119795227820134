import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/contracts/address';
import { DeliveryMethod } from 'src/app/contracts/deliveryMethod';
import { OrderData } from 'src/app/contracts/orderData';
import { OrderDataProduct } from 'src/app/contracts/orderDataProduct';
import { OrderDataProductPropertyValue } from 'src/app/contracts/orderDataProductPropertyValue';
import { OrderDataProductService } from 'src/app/contracts/orderDataProductService';
import { PaymentState } from 'src/app/contracts/payment-state';
import { Product } from 'src/app/contracts/product';
import { ProductProperty } from 'src/app/contracts/product-property';
import { ProductPropertyValue } from 'src/app/contracts/product-property-value';
import { ShopItem } from 'src/app/contracts/shop-item';
import { DeliveryMethosService } from 'src/app/dataServices/deliveryMethodService';
import { OrderService } from 'src/app/dataServices/orderService';
import { ProductService } from 'src/app/dataServices/productService';
import { CaptchaComponent } from 'src/app/directives/captcha/captcha.component';
import { ShoppingCartService } from 'src/app/services/shopping-cart-service';

declare var ppapi: any;
var self;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [DeliveryMethosService, OrderService]
})

export class CartComponent implements OnInit, OnDestroy {

  public deliveryMethods: DeliveryMethod[];
  public step: number;
  public orderData: OrderData;
  public isResolved: boolean = false;
  public orderSuccess: boolean = false;

  private paymentType;
  private onResolvedSubscription: any;
  private onErrorSubscription: any;
  private captchaDialog: any;
  private paymentId: string;
  private paymentCheckerTimer: any;
  public paymentState: PaymentState;
  public clicked: boolean = false;

  constructor(public shoppingCartService: ShoppingCartService, private deliveryMethodService: DeliveryMethosService, private orderService: OrderService, private router: Router, private dialog: MatDialog, private route: ActivatedRoute) {
    self = this;

    ppapi.onSelect = (data) => {
      this.orderData.PostPointName = data.name;
      this.orderData.PostPointAddress = data.address;
      this.orderData.PostPointId = data.id;
    };

    if (shoppingCartService.shoppingCart.length == 0) {
      this.step = 0;
    }
    else {
      this.step = 1;
      this.shoppingCartService.updateProductPropertiesInCart();
    }
    this.orderData = new OrderData();
    this.orderData.OtherAddress = false;


    shoppingCartService.onCartChanged.subscribe((evt: boolean) => {
      if (evt) {
        if (!this.orderSuccess) {
          this.step = 0;
        }
      }
    });

    this.route.queryParamMap
      .subscribe((params: any) => {
        if (params && params.params && params.params.paymentId) {
          this.paymentId = params.params.paymentId;
          this.step = 4;
          this.getPaymentState();
        }
      });



    this.shoppingCartService.recalculateSum();
    this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice;
  }

  ngOnInit() {

    /*); s.route.snapshot.params && this.route.snapshot.params.paymentId) {
    this.paymentId = this.route.snapshot.params.paymentId;
    this.step = 4;
    this.getPaymentState();
  }* /*/

    this.deliveryMethodService.getAllActive().then((r: DeliveryMethod[]) => {
      this.deliveryMethods = r;
    })
  }

  public getSize(product: ShopItem) {
    let property = product.Product.Properties.filter(property => property.Name == 'Méret')[0];
    let value: ProductPropertyValue;

    if (property) {
      value = this.getValueByPropertyAndId(property, product.SelectedProperties);
    }

    if (value) {
      return value.Value;
    }
    else {
      return "";
    }
  }

  private getValueByPropertyAndId(property: ProductProperty, selectedValues: ProductPropertyValue[]) {
    let value: ProductPropertyValue;

    property.Values.forEach((ppv: ProductPropertyValue) => {
      /*if (selectedValues.indexOf(ppv.Id) > -1) {
        value = ppv;
      }*/

      let item = selectedValues.filter((productPropertyValue: ProductPropertyValue) => {
        if (productPropertyValue && productPropertyValue.Id == ppv.Id) {
          return productPropertyValue;
        }
      })[0];

      if (item) {
        value = ppv;
      }

    });

    return value;
  }

  public deleteCart() {
    this.shoppingCartService.clearCart();

    if (!this.orderSuccess) {
      if (this.shoppingCartService.shoppingCart.length == 0) {
        this.step = 0;
      }
    }
  }

  public refreshCart() {
    this.shoppingCartService.loadCartCookie();
    this.shoppingCartService.updateProductPropertiesInCart();

    if (this.shoppingCartService.shoppingCart.length == 0) {
      this.step = 0;
    }
  }

  public changeDeliveryMethod(selectedItem: any) {
    let id = parseInt(selectedItem.value);
    this.orderData.DeliveryMethod = this.deliveryMethods.filter((m: DeliveryMethod) => m.Id == id)[0];

    if (this.orderData.DeliveryMethod && this.orderData.DeliveryMethod.Price) {
      this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice + this.orderData.DeliveryMethod.Price;
    }
    else {
      this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice;
    }

    if (this.orderData.DeliveryMethod.Code == 'PostPoint' && this.step == 2) {
      setTimeout(() => {
        ppapi.setResolution(1000, 645)
        ppapi.insertMap('postPointChooser');
      }, 500);
    }

    this.shoppingCartService.deliveryMethid = this.orderData.DeliveryMethod;
  }

  public isPaymentDisabled() {
    return !this.orderData.DeliveryMethod || this.shoppingCartService.shoppingCart.length == 0;
  }

  public goToPayment() {
    scrollTo(0,0);
    this.step = 2;

    if (this.orderData.DeliveryMethod.Code == 'PostPoint') {
      setTimeout(() => {
        ppapi.setResolution(1000, 645)
        ppapi.insertMap('postPointChooser');
      }, 500);
    }
  }

  public otherAddress() {
    this.orderData.OtherAddress = !this.orderData.OtherAddress;

    if (this.orderData.OtherAddress) {
      this.orderData.DeliveryAddress = new Address();
    }
    else {
      this.orderData.DeliveryAddress = null;
    }
  }

  public pay(method: string) {
    let isValid = this.validateRequiredFields();
    let isEmailValid = this.validateEmail();
    let isPhoneValid = this.validatePhone();

    if (isValid) {
      if (isEmailValid) {
        if (isPhoneValid) {

          this.clicked = true;

          setTimeout(() => {
            this.orderData.PaymentMethod = method;
            this.orderData.OrderDataProducts = [];
            this.orderData.Coupon = this.shoppingCartService.coupon;

            this.shoppingCartService.shoppingCart.forEach((shopItem: ShopItem) => {
              let orderDataProduct = new OrderDataProduct();

              orderDataProduct.Product = shopItem.Product
              //   orderDataProduct.OrderDataProductPropertyValues = shopItem.SelectedProperties;
              orderDataProduct.OrderDataProductPropertyValues = [];

              shopItem.SelectedProperties.forEach((ppv: ProductPropertyValue) => {
                if (ppv) {
                  let orderDataProductPropertyValue = new OrderDataProductPropertyValue();
                  orderDataProductPropertyValue.ProductPropertyValue = ppv;
                  orderDataProduct.OrderDataProductPropertyValues.push(orderDataProductPropertyValue);
                }
              });

              orderDataProduct.Quantity = shopItem.Quantity;
              //orderDataProduct.OrderDataProductService

              shopItem.Product.Services.forEach((service: Product) => {
                if (service.Selected) {
                  let orderDataProductService = new OrderDataProductService();

                  orderDataProductService.Product = service;

                  orderDataProduct.OrderDataProductServices.push(orderDataProductService);
                }
              });

              this.orderData.OrderDataProducts.push(orderDataProduct);
            });

            this.orderService.Order(this.orderData).then((r: any) => {
              if (r.ResultType == 0) {
                if (r.Identifier == '') {
                  this.step = 3;
                  this.orderSuccess = true;
                  this.deleteCart();
                }
                else {
                  if (r.IsAdditionalLogic) {

                  }
                  else {
                    this.deleteCart();
                    window.location.href = r.Identifier;
                  }
                }
                this.clicked = false;
              }
              else if (r.ResultType == 3) {
                let messages = "";

                r.ValidationResult.forEach(element => {
                  messages += element.Key + ": " + element.Value + "\r\n"
                });


                if (r.IsAdditionalLogic) {
                  this.shoppingCartService.couponCode = "";
                  this.shoppingCartService.recalculatePrices(true);
                  this.shoppingCartService.coupon = null;
                  this.shoppingCartService.isCouponActive = false;

                  if (this.shoppingCartService.deliveryMethid && this.shoppingCartService.deliveryMethid.Price) {
                    this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice + this.shoppingCartService.deliveryMethid.Price;
                  }
                  else {
                    this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice;
                  }
                }

                alert(messages);
              }
              else {
                alert("A rendelést nem sikerült leadni.")
                this.clicked = false;
              }
            }).catch(r => {
              if (r.Exception.Message.includes("NotValidCartException")) {
                alert('A kosár tartalma nem érvényes');
                this.deleteCart();
                this.step = 0;
                this.clicked = false;
              }
              else if (r.ResultType == 1) {
                let messages = "";

                r.Exception.ValidationResult.forEach(element => {
                  messages += element.Key + ": " + element.Value + "\r\n"
                });


                if (r.IsAdditionalLogic) {
                  this.shoppingCartService.couponCode = "";
                  this.shoppingCartService.recalculatePrices(true);
                  this.shoppingCartService.coupon = null;
                  this.shoppingCartService.isCouponActive = false;

                  if (this.shoppingCartService.deliveryMethid && this.shoppingCartService.deliveryMethid.Price) {
                    this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice + this.shoppingCartService.deliveryMethid.Price;
                  }
                  else {
                    this.shoppingCartService.sumWithDeliveryPrice = this.shoppingCartService.cartSumPrice;
                  }
                }

                alert(messages);

                this.clicked = false;

              }
              else {
                alert('A rendelést nem sikerült leadni');
                this.clicked = false;
              }
            });
          }, 10);
        }
        else {
          alert("Nem megfelelő telefonszám (+3620123456789)")
        }
      }
      else {
        alert('Nem megfelelő e-mail cím');
      }
    }
    else {
      alert("Kérem töltse ki a '*'-al jelölt mezőket!")
    }

  }

  private validateRequiredFields() {
    if (this.orderData.Name == ''
      || this.orderData.Name == undefined
      || this.orderData.BillingAddress.Country == ''
      || this.orderData.BillingAddress.Country == undefined
      || this.orderData.BillingAddress.County == ''
      || this.orderData.BillingAddress.County == undefined
      || this.orderData.BillingAddress.PostalCode == ''
      || this.orderData.BillingAddress.PostalCode == undefined
      || this.orderData.BillingAddress.City == ''
      || this.orderData.BillingAddress.City == undefined
      || this.orderData.BillingAddress.AddressLine1 == ''
      || this.orderData.BillingAddress.AddressLine1 == undefined
      || this.orderData.Phone == ''
      || this.orderData.Phone == undefined
      || this.orderData.Email == ''
      || this.orderData.Email == undefined
      || this.orderData.OtherAddress && (
        this.orderData.DeliveryAddress.Country == ''
        || this.orderData.DeliveryAddress.Country == undefined
        || this.orderData.DeliveryAddress.County == ''
        || this.orderData.DeliveryAddress.County == undefined
        || this.orderData.DeliveryAddress.PostalCode == ''
        || this.orderData.DeliveryAddress.PostalCode == undefined
        || this.orderData.DeliveryAddress.City == ''
        || this.orderData.DeliveryAddress.City == undefined
        || this.orderData.DeliveryAddress.AddressLine1 == ''
        || this.orderData.DeliveryAddress.AddressLine1 == undefined

      )) {
      return false;
    }
    else {
      return true;
    }
  }

  private validateEmail() {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regularExpression.test(String(this.orderData.Email).toLowerCase());
  }

  private validatePhone() {
    const regex = /((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})/gm;
    return regex.test(String(this.orderData.Phone).toLowerCase());
  }

  public openCollection() {
    this.router.navigate(['/pages/shop'], { queryParams: { code: 1 } });
  }

  public resolve(evt) {
    this.isResolved = true;
  }

  public isBankTransferEnabled() {
    let shopItems = this.shoppingCartService.shoppingCart.filter((item: ShopItem) => {
      if (item.Product.DisableBankTransfer == true) {
        return item;
      }
    })

    if (shopItems.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  public isCashOnDeliveryEnabled() {
    let shopItems = this.shoppingCartService.shoppingCart.filter((item: ShopItem) => {
      if (item.Product.DisableCashOnDelivery == true) {
        return item;
      }
    })

    if (shopItems.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  public isOnlinePaymentEnabled() {
    let shopItems = this.shoppingCartService.shoppingCart.filter((item: ShopItem) => {
      if (item.Product.DisableOnlinePayment == true) {
        return item;
      }
    })

    if (shopItems.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  public openCaptcha() {


    this.captchaDialog = this.dialog.open(CaptchaComponent, {
      width: '310px',
      height: '126px',
    });

    this.onErrorSubscription = this.captchaDialog.componentInstance.onError.subscribe(() => {
      this.unSubscripbeCapthca();
    });
  }

  private paySubscriptions() {

    this.onResolvedSubscription = this.captchaDialog.componentInstance.onResolved.subscribe(() => {
      this.pay(this.paymentType);
      this.unSubscripbeCapthca();
    });
  }

  private rePaySubscriptions() {

    this.onResolvedSubscription = this.captchaDialog.componentInstance.onResolved.subscribe(() => {
      this.rePay();
      this.unSubscripbeCapthca();
    });
  }

  public payCart(paymentType: string) {
    this.paymentType = paymentType;

    this.openCaptcha();
    this.paySubscriptions();
  }

  public rePayCart(paymentType: string) {
    this.paymentType = paymentType;

    this.openCaptcha();
    this.rePaySubscriptions();
  }

  public getPaymentState() {
    this.paymentCheckerTimer = setTimeout(() => {

      this.orderService.getPaymentState(this.paymentId).then((r: any) => {
        if (r.ResultType == 0) {
          this.paymentState = r.Result as PaymentState;
          if (this.paymentState.State == "Succeeded" || this.paymentState.State == "Canceled" || this.paymentState.State == "Failed") {

          }
          else {
            this.getPaymentState();
          }
        }
        else {

        }
      });
    }, 5000);
  }

  private rePay() {
    this.orderService.rePay(this.paymentState.OrderId).then((r: any) => {
      if (r.ResultType == 0) {
        if (r.Identifier == '') {
          this.step = 3;
          this.orderSuccess = true;
          this.deleteCart();
        }
        else {
          this.deleteCart();
          window.location.href = r.Identifier;
        }
      }
      else {
        alert("A rendelést nem sikerült leadni.")
      }
    }).catch(r => {
      if (r.Exception.Message.includes("NotValidCartException")) {
        alert('A kosár tartalma nem érvényes');
        this.deleteCart();
        this.step = 0;
      }
      else if (r.ResultType == 1) {
        let messages = "";

        r.Exception.ValidationMessages.forEach(element => {
          messages += element.Key + ": " + element.Value + "\r\n"
        });

        alert(messages);
      }
      else {
        alert('A rendelést nem sikerült leadni');
      }
    });
  }

  private unSubscripbeCapthca() {
    this.onResolvedSubscription.unsubscribe();
    this.onErrorSubscription.unsubscribe();

    this.captchaDialog.close();
    this.captchaDialog = null;
  }

  public navigate(link, code) {
    this.router.navigate([link], { queryParams: { code: code } });
  }

  ngOnDestroy(): void {
    if (this.paymentCheckerTimer) {
      //this.paymentCheckerTimer.();
      clearTimeout(this.paymentCheckerTimer);
    }
  }
}
