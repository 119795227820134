<mat-card class="card">
    <div class="action-button-wrapper">
        <button mat-raised-button color="primary" class="btn-action" [disabled]="readOnly" (click)="save()">{{ 'Save' |
            translate }}</button>
        <button mat-raised-button color="secondary" class="btn-action" (click)="cancel()">{{ 'Cancel' | translate
            }}</button>
    </div>

    <mat-tab-group class="input-full-width">
        <mat-tab label="Alapadatok">
            <mat-form-field class="input-full-width">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input matInput placeholder="{{ 'Name' | translate }}" [(ngModel)]="product.Name" [disabled]="readOnly">
            </mat-form-field>

            <mat-checkbox class="checkbox" [(ngModel)]="product.IsActive">Aktív</mat-checkbox>
            <mat-checkbox class="checkbox" [(ngModel)]="product.IsVisible">Látszik a webshopban</mat-checkbox>
            <mat-checkbox class="checkbox" [(ngModel)]="product.IsFavourite">Megjelenik a kedvencekben</mat-checkbox>


            <mat-checkbox class="checkbox" [(ngModel)]="product.IsSizeTable">Megjelenik a mérettábla</mat-checkbox>
            <mat-checkbox class="checkbox" [(ngModel)]="product.IsMeasure">Megjelenik a "Hogyan mérjem le magam?"
            </mat-checkbox>
            <mat-checkbox class="checkbox" [(ngModel)]="product.IsHandle">Megjelenik a "Hogyan ápoljam""</mat-checkbox>
            <mat-checkbox class="checkbox" [(ngModel)]="product.IsTransport">Megjelenik a "Szállítási információ"
            </mat-checkbox>

            <mat-checkbox class="checkbox" [(ngModel)]="product.DisableBankTransfer">Banki átutalás tiltása"
            </mat-checkbox>
            <mat-checkbox class="checkbox" [(ngModel)]="product.DisableCashOnDelivery">Utánvét tiltása</mat-checkbox>
            <mat-checkbox class="checkbox" [(ngModel)]="product.DisableOnlinePayment">Online fizetés (Barion) tiltása
            </mat-checkbox>
            <mat-checkbox class="checkbox" [(ngModel)]="product.ShowInPairingsList">Kedvenc párosításokban megjelenik
            </mat-checkbox>

            <br />

            <category-picker [selectedCategory]="product?.Category" (onChangeEvent)="handleCategory($event)"
                [disabled]="readOnly"></category-picker>

            <div class="editor">
                <span>Leírás</span>
                <text-editor [disabled]="readOnly" [(data)]="product.Description"></text-editor>
            </div>

            <mat-form-field class="input-full-width">
                <mat-label>Ár</mat-label>
                <input matInput type="number" placeholder="Ár" [(ngModel)]="product.Price" [disabled]="readOnly">
            </mat-form-field>

            <mat-form-field class="input-full-width" *ngIf="product.ShowInPairingsList">
                <mat-label>Párosítás alcím</mat-label>
                <input matInput type="text" placeholder="Párosítás alcím" [(ngModel)]="product.PairingSubTitle" [disabled]="readOnly">
            </mat-form-field>

            <mat-form-field class="input-full-width" *ngIf="product.ShowInPairingsList">
                <mat-label>Párosítás listában megjelenő rövid leírás</mat-label>
                <input matInput type="text" placeholder="Párosítás listában megjelenő rövid leírás" [(ngModel)]="product.PairingShortDescription" [disabled]="readOnly">
            </mat-form-field>

            <mat-form-field class="input-full-width" *ngIf="product.ShowInPairingsList">
                <mat-label>Párosítás alszöveg</mat-label>
                <input matInput type="text" placeholder="Párosítás alszöveg" [(ngModel)]="product.PairingSubText" [disabled]="readOnly">
            </mat-form-field>

            <media-uploader *ngIf="product" #mediaUploader (onImagesChangeEvent)="onImagesChanging()"
                (onImagesLoadedEvent)="imagesLoaded($event)" type="Product" [id]="product?.Id"></media-uploader>

            <!--<image-picker #pairingImagePicker *ngIf="product.Id" type="Product" [id]="product?.Id" placeholder="Párosítás borítókép"
                (onChangeEvent)="handlePairingImage($event)" [selectedImage]="product?.PairingThumbnail">
            </image-picker>-->

            <image-picker #imagePicker *ngIf="product.Id" type="Product" [id]="product?.Id" placeholder="CoverImage"
                (onChangeEvent)="handleImage($event)" [selectedImage]="product?.Thumbnail">
            </image-picker>
        </mat-tab>

        <mat-tab label="Szolgáltatások">
            <div class="service">
                <button mat-raised-button color="primary" (click)="addService()">
                    <span class="material-icons">
                        add
                    </span>
                </button>
                <div *ngFor="let service of product.Services; let i = index" [attr.data-index]="i">
                    <div class="service-item" *ngIf="!service.IsDeleted">
                        <button mat-raised-button color="primary" (click)="deleteService(service)">
                            <span class="material-icons">
                                delete
                            </span>
                        </button>

                        <service-picker class="picker" [selectedService]="service"
                            (onChangeEvent)="handleService($event, i)" [disabled]="readOnly || !service.IsNew"></service-picker>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Párosítások">
            <div class="pairing">
                <button mat-raised-button color="primary" (click)="addPairing()">
                    <span class="material-icons">
                        add
                    </span>
                </button>
                <div *ngFor="let pairing of product.Pairings; let i = index" [attr.data-index]="i">
                    <div class="pairing-item" *ngIf="!pairing.IsDeleted">
                        <button mat-raised-button color="primary" (click)="deletePairing(pairing)">
                            <span class="material-icons">
                                delete
                            </span>
                        </button>

                        <product-picker class="picker" [selectedProduct]="pairing"
                            (onChangeEvent)="handlePairing($event, i)" [disabled]="readOnly || !pairing.IsNew"></product-picker>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Tulajdonságok" class="card">
            <div class="properties">
                <button mat-raised-button color="primary" (click)="addProperty()">
                    <span class="material-icons">
                        add
                    </span>
                </button>

                <div *ngFor="let property of product.Properties; let i = index" [attr.data-index]="i">
                    <mat-card class="card property" *ngIf="!property.IsDeleted">
                        <div class="pp">
                            <div class="pp-item">
                                <button mat-raised-button color="primary" (click)="deleteProperty(property)">
                                    <span class="material-icons">
                                        delete
                                    </span>
                                </button>
                                <input type="text" [(ngModel)]="property.Name" class="text" />
                            </div>

                            <mat-card class="card">
                                <button mat-raised-button color="primary" (click)="addPropertyValue(property)">
                                    <span class="material-icons">
                                        add
                                    </span>
                                </button>

                                <div *ngFor="let propertyValue of property.Values; let ppvi = index"
                                    [attr.data-index]="ppvi">
                                    <div class="ppv-item" *ngIf="!propertyValue.IsDeleted">
                                        <button mat-raised-button color="primary" (click)="deletePropertyValue(propertyValue, property)">
                                            <span class="material-icons">
                                                delete
                                            </span>
                                        </button>
                                        <input type="text" [(ngModel)]="propertyValue.Value" class="text" />
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </mat-card>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>