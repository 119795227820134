import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable()
export class LoaderService {
    private subscribedRequests: string[];

    constructor() {
        this.subscribedRequests = [];
    }

    public addToken(): string {
       // $(window).scrollTop(0);
       // $("body").css("overflow", "hidden");

        let token = uuid();
        this.subscribedRequests.push(token);

        return token;
    }

    public removeToken(token: string) {
        let idx = this.subscribedRequests.indexOf(token);
        this.subscribedRequests.splice(idx, 1);
    }

    public hasItem(): boolean {
        if (this.subscribedRequests == null || this.subscribedRequests.length == 0) {
         //   $("body").css("overflow", "auto");
            return false;
        }
        else
            return true;
    }
}