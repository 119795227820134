import { CouponProduct } from "./couponProduct";
import { ICoupon } from "./interfaces/iCoupon";
import { Product } from "./product";

export class Coupon implements ICoupon {
    public Id: number;
    public Code: string;
    public From: Date;
    public To: Date;
    public Type: number;
    public IsPercentage: boolean;
    public Value: number;
    public IsActive: boolean;
    public Products: CouponProduct[];
    public IsNew: boolean;
    
    constructor(){
    }
}