<div class>
    <mat-card class="card">
        <div class="change-password">
            <mat-form-field class="input-full-width">
                <mat-label>Régi jelszó</mat-label>
                <input type="password" matInput placeholder="Régi jelszó" [(ngModel)]="password.OldPassword">
            </mat-form-field>

            <mat-form-field class="input-full-width">
                <mat-label>Új jelszó</mat-label>
                <input type="password" matInput placeholder="Új jelszó"  [(ngModel)]="password.NewPassword">
            </mat-form-field>

            <mat-form-field class="input-full-width">
                <mat-label>Jelszó ismét</mat-label>
                <input type="password" matInput placeholder="Jelszó ismét" [(ngModel)]="password.NewPasswordAgain">
            </mat-form-field>

            <div class="action">
                <button mat-raised-button color="primary" [disabled]="!password.OldPassword || !password.NewPassword || !password.NewPasswordAgain" class="btn-save" (click)="save()">Megváltoztat</button>
            </div>
        </div>
    </mat-card>
</div>