import { Injectable } from "@angular/core";

@Injectable()
export class ShopServices {
    public orderByKey: string;
    public queryParams: any;
    public activeShopMenu: string;
    public selectedValue: any;
    public orders: any[];

    constructor() {
        this.orders = [
            { DisplayName: 'Rendezés A-tól Z-ig', Code: 'OrderByNameAsc' },
            { DisplayName: 'Rendezés Z-től A-ig', Code: 'OrderByNameDesc' },
            { DisplayName: 'Rendezés ár szerint növekvő sorrendbe', Code: 'OrderByPriceAsc' },
            { DisplayName: 'Rendezés ár szerint csökkenő sorrendbe', Code: 'OrderByPriceDesc' },
        ];

        this.selectedValue = this.orders[0];
        this.orderByKey = "OrderByNameAsc";
    }

}