<media-uploader #mediaUploader (onImagesChangeEvent)="onImagesChanging()" (onImagesLoadedEvent)="imagesLoaded($event)"
    [disabled]="readOnly" filter="InstaPost"></media-uploader>

<mat-card class="card" *ngIf="instaPost">
    <div class="action-button-wrapper">
        <button mat-raised-button color="primary" class="btn-action" (click)="save()" [disabled]="readOnly" >{{ 'Save' | translate }}</button>
        <button mat-raised-button color="secondary" class="btn-action" (click)="cancel()" >{{ 'Cancel' | translate }}</button>
    </div>

    <mat-form-field class="input-full-width">
        <mat-label>Cím</mat-label>
        <input matInput placeholder="Cím" [(ngModel)]="instaPost.Title" [disabled]="readOnly">
    </mat-form-field>

    <mat-form-field  class="input-full-width">
        <mat-label>Url</mat-label>
        <input matInput placeholder="Url" [(ngModel)]="instaPost.Url" [disabled]="readOnly">
    </mat-form-field>

    <image-picker #imagePicker *ngIf="instaPost" placeholder="Kép" [disabled]="readOnly"
        (onChangeEvent)="handleImage($event)" [selectedImage]="instaPost?.Image" filter="InstaPost">
</image-picker>
</mat-card>