import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Password } from 'src/app/contracts/password';
import { UserService } from 'src/app/dataServices/userService';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
  providers: [UserService]
})
export class ChangepasswordComponent implements OnInit {
  public password: Password;

  constructor(private userService: UserService, private router: Router) {
    this.password = new Password();
  }

  ngOnInit() {
  }

  public save() {

    this.password.OldPassword = this.createMd5(this.password.OldPassword);
    this.password.NewPassword = this.createMd5(this.password.NewPassword);
    this.password.NewPasswordAgain = this.createMd5(this.password.NewPasswordAgain);

    this.userService.changePassword(this.password, 0).then(r => {
      this.password = new Password();

      alert("Sikeres jelszóváltoztatás")
      this.router.navigate(['adminsite/dashboard']);

    }).catch(r => {
      this.password = new Password();
      alert("Sikertelen jelszóváltoztatás")
    });
  }

  private createMd5(value: string) {
    let md5 = new Md5();
    md5.appendStr(value);
    return md5.end().toString();
  }
}
