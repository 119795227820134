<div class="wrapper">
    <h1 *ngIf="!showLines">
        Kedvenceink
    </h1>
    <div class="separator" *ngIf="showLines">
        <div class="line"></div>
        <h1>Kedvenceink</h1>
        <div class="line"></div>
    </div>
    <div class="content-wrapper">
        <div class="scroll-content">
            <favourite-item *ngFor="let favourite of favouritesToShow" [id]="favourite.Id" [src]="favourite.FileName"
                [name]="favourite.Name"></favourite-item>

            <favourite-item *ngFor="let favourite of favouritesToShow" [id]="favourite.Id" [src]="favourite.FileName"
                [name]="favourite.Name"></favourite-item>
        </div>
    </div>
</div>

<!-- Wrap the slider in div with overflow hidden to hide scrollbars -->