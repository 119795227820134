import { HttpService } from "../common/httpService";
import { Filter } from "../contracts/infrastructure/filter";
import { QueryStringParameter } from "../contracts/queryStringParameter";

export class Base {
    protected controller: string;

    constructor(public httpService: HttpService, controller: string) {
        this.controller = controller;
    }

    public getById(id: number) {
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter('Id', id));

        return this.httpService.get(this.controller, 'GetById', parameters);
    }

    public getAll() {
        return this.httpService.get(this.controller, 'GetAll');
    }

    public getAllActive() {
        return this.httpService.get(this.controller, 'GetAllActive');
    }

    public getByCode(code: string) {
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter('Code', code));

        return this.httpService.get(this.controller, 'GetByCode', parameters);
    }

    public create() {
        return this.httpService.post(this.controller, "Create", null);
    }

    public update(contract: any) {
        return this.httpService.post(this.controller, 'Update', contract);
    }

    public save(contract: any) {
        return this.httpService.post(this.controller, 'Save', contract);
    }

    public delete(id: number) {
        let parameters: QueryStringParameter[] = [];
        parameters.push(new QueryStringParameter('Id', id));

        return this.httpService.post(this.controller, "Delete", null, parameters);
    }

    public filter(filters: Filter[]) {
        return this.httpService.post(this.controller, "Filter", filters);
    }
}