<div class="wrapper">
    <div class="left">
        <img [src]="blog.CoverImage.FileName" />
    </div>
    <div class="right">
        <h1>{{blog.Title}}</h1>
        <h2>{{ getCategoryById(blog.CategoryId) }} | {{blog.Created | date:'yyyy.MM.dd HH:mm'}}</h2>
        <div class="short-text">
            <p [innerHTML]="blog?.ShortText | keepHtml"></p>
        </div>
        <div class="action-btn">
            <button (click)="open()" mat-flat-button>Tovább</button>
        </div>
    </div>
</div>